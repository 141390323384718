import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './i18n.en';
import chTranslation from './i18n.ch';
import esTranslation from './i18n.es';
import frTranslation from './i18n.fr';
import grTranslation from './i18n.gr';
import norTranslation from './i18n.nor';
import itlyTranslation from './i18n.itly';
import turkishhTranslation from './i18n.turh';
import STORAGE from 'src/constants/localStorage';

const savedLanguage = localStorage.getItem(STORAGE.LANGUAGE) || process.env.REACT_APP_REGION === 'CN' ? 'ch' : 'en';

i18n
  .use(initReactI18next)

  .init({
    lng: savedLanguage,
    debug: false,
    fallbackLng: process.env.REACT_APP_REGION === 'CN' ? 'ch' : 'en',
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: '#',
    resources: {
      en: enTranslation,
      ch: chTranslation,
      es: esTranslation,
      fr: frTranslation,
      gr: grTranslation,
      nor: norTranslation,
      itly: itlyTranslation,
      tur: turkishhTranslation,
    },
  });

export default i18n;
