import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const useRoutesCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return queries.routes.create.query(data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.routes.get.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useRoutesEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) => {
      return queries.routes.edit.query(id, data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.routes.get.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useRouteDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => {
      return queries.routes.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.routes.get.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useRoutesCreate,
  useRoutesEdit,
  useRouteDelete,
};
