import { useMutation, useQueryClient } from 'react-query';

import queries from '../queries';

const useSeen = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => queries.notifications.seen.query(id), {
    ...callbacks,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([queries.notifications.list.key]);
      if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
    },
  });
};

const useSeenAll = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(queries.notifications.seenAll.query, {
    ...callbacks,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([queries.notifications.list.key]);
      if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
    },
  });
};

const useClearAll = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return queries.notifications.clearAll.query();
    },
    {
      ...callbacks,
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([queries.notifications.list.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useSeen,
  useSeenAll,
  useClearAll,
};
