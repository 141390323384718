export default {
  translation: {
    commonUsedWords: {
      login: 'Giriş Yap',
      email: 'E-posta Adresi',
      password: 'Parola',
      emailIsRequired: 'E-posta gerekli',
      mustBeAValidEmail: 'Geçerli bir e-posta olmalı',
      deviceSerialNumber: 'Cihaz Seri Numarası',
      next: 'İleri',
      success: 'Başarılı',
      submit: 'Gönder',
      firstName: 'Ad',
      firstNameIsRequired: 'Ad gerekli',
      lastName: 'Soyad',
      lastNameIsRequired: 'Soyad gerekli',
      save: 'Kaydet',
      undoChanges: 'Değişiklikleri Geri Al',
      cancel: 'İptal',
      loading: 'Yükleniyor...',
      yes: 'Evet',
      no: 'Hayır',
      clear: 'Temizle',
      confirm: 'Onayla',
      noOption: 'Seçenek yok',
      openText: 'Aç',
      edit: 'Düzenle',
      logout: 'Çıkış Yap',
      update: 'Güncelle',
      locked: 'Kilitli',
      unlocked: 'Kilidi Açık',
      latitude: 'Enlem (LAT)',
      longitude: 'Boylam (LONG)',
      ok: 'Tamam',
      okay: 'Tamam',
      done: 'Bitti',
      comingSoon: 'Yolda...',
      reset: 'Sıfırla',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Zorunlu alanlar eksik',
        userNotFound: 'Kullanıcı bulunamadı',
        missingRefreshToken: 'Yenileme jetonu eksik',
        cannotRefreshToken: 'Jeton yenilenemiyor',
      },
      helpers: {
        error: 'Hata',
        invalidHTTPMethod: 'Geçersiz HTTP yöntemi',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'E-posta Adresini Onayla',
        confirmEmailIsRequired: 'Onaylanmış e-posta adresi gereklidir',
        emailNotMatched: 'E-posta adresleri eşleşmiyor',
      },
      confirmPassword: {
        confirmPassword: 'Parolayı Onayla',
        confirmPasswordIsRequired: 'Onaylanmış parola gereklidir',
        newPasswordIsRequired: 'Yeni parola gereklidir',
        showPassword: 'Parolayı Göster',
        passwordLength: 'Parolalar en az 6 karakter olmalıdır',
        passwordContainSpaces: 'Parola boşluk içermemelidir',
        passwordNotMatch: 'Parola girişleri eşleşmiyor',
        newPassword: 'Yeni Parola',
      },
      confirmModal: {
        areYouSure: 'Emin misiniz?',
      },
      appliedFilters: {
        between: '{{firstValue}} ile {{secondValue}} arasında',
        bottom: 'Alt {{primaryValue}}%',
        top: 'Üst {{primaryValue}}%',
        activeFilters: 'Aktif Filtreler',
        active: 'aktif',
        closed: 'kapalı',
      },
      editTable: {
        editTable: 'Tabloyu Düzenle',
        selectDeselect: 'Tablo Değerlerini Seç / Seçimi Kaldır',
        resetToDefault: 'Varsayılana Sıfırla',
      },
      errorBoundary: {
        oops: 'Oops! Beklenmeyen bir şey oldu.',
        tryAgain: 'Lütfen tekrar deneyin! Sorun devam ederse lütfen yöneticinize başvurun.',
        refresh: 'Yenile',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Üzgünüz, sonuç bulunamadı. Lütfen tekrar deneyin.',
      },
      permissionGuard: {
        invalidPermission: 'Geçersiz izin yapısı, dizide 3 öğe bekleniyor',
      },
      preview: {
        findInView: 'Görünümde Bul',
        showDetails: 'Ayrıntıları Göster',
        closeEsc: 'Kapat (Esc)',
        prevLeftArrow: 'Önceki (Sol ok)',
        nextRightArrow: 'Sonraki (Sağ ok)',
        proceedWithoutSaving: 'Kaydetmeden gezin',
        continueEditing: 'Düzenlemeye devam et',
        unsavedChanges: {
          partOne: 'Dikkat:',
          partTwo: 'Kaydedilmemiş',
          partThree: 'DEĞİŞİKLİKLERİNİZ VAR.',
          partFour: 'Kaydetmeden gezinmek tüm değişiklikleri yok sayacaktır. Emin misiniz?',
        },
      },
      printRecord: {
        title: 'Kaçak Olay Kaydı',
        date: 'Tarih:',
        location: 'Konum',
        leakDetails: 'Kaçak Ayrıntıları',
        leakParameters: 'Kaçak Parametreleri',
        energyLoss: 'Enerji Kaybı',
        leakSourceEquipment: 'Kaçak Kaynak Ekipmanı',
        notes: 'Notlar',
        leakEventStatus: 'Kaçak Olay Durumu',
        requiredAction: 'Gerekli Eylem',
        leakSourceImage: 'Kaçak Kaynak Görüntüsü',
        smartLeakTag: 'Akıllı Kaçak Etiketi',
        lossPerYear: 'Kayıp ({{currency}}/yıl)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'Atama silindi',
          deleteAssignmentError: 'Atama silinemiyor. Lütfen daha sonra tekrar deneyin',
          currentlyAssigned: 'Şu Anda Atanan Eylem',
          repairedBy: 'Onaran',
          text: 'Bu atamayı silmek üzeresiniz. Bu işlem geri alınamaz',
        },
        actionSaveDate: 'Son kaydedildi',
      },
      visibleColumnsPopper: {
        tableValues: 'Tablo Değerlerini Seç / Seçimi Kaldır',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Eşittir',
        LESS_THAN: 'Küçüktür',
        GREATER_THAN: 'Büyüktür',
        LESS_THAN_OR_EQUAL_TO: 'Küçük veya eşittir',
        GREATER_THAN_OR_EQUAL_TO: 'Büyük veya eşittir',
        BETWEEN_A_AND_Y: 'Arasında',
        TOP_PERCENT: 'En üst "x"%',
        BOTTOM_PERCENT: 'En alt "x"%',
      },
      recordFields: {
        CREATED_AT: 'Oluşturulma Tarihi',
        RECORD_ID: 'Kayıt Kimlik Numarası',
        DOCUMENT_ID: 'Kayıt Kimlik Numarası',
        DOLLARS_PER_YEAR: 'Kayıp',
        GAS_COMPONENT: 'Gaz/Bileşen',
        LEAK_TYPE: 'Kaçak Tipi Profili',
        DATE_TIME: 'Tarih/Saat',
        DB_PEAK: 'Tepe dB',
        COMPANY: 'Şirket',
        NOTES: 'Notlar',
        USER: 'Kullanıcı',
        EQUIPMENT_TAG: 'Ekipman/Bileşen Etiketi',
        FLOW: 'Akış (m³/h)',
        EQUIPMENT: 'Ünite/Ekipman',
        ROUTE: 'Rota',
        ROUTE_LOCATION: 'Rota Konumu',
        PROJECT_SURVEY: 'Denetim/Anket Kayıt Referansı',
        OUTAGE_STATUS: 'Kesinti/Kapanma Gerekli mi?',
        FACILITY: 'Tesis/Site',
        ACTION_STATUS: 'Eylem Durumu',
        SERVICE_TYPE: 'Hizmet/Tür',
        DB_ACTIVE: 'Aktif dB',
        REFERENCE_ID: 'Proje #/Anket Kimliği',
        AMBIENT_TEMPERATURE: 'Ortam Sıcaklığı (°C)',
        LEAK_PRESSURE: 'Basınç (kPag)',
        LEAK_TEMPERATURE: 'Gaz Sıcaklığı (°C)',
        LEAK_CATEGORY: 'Kaçak Kategorisi',
        DISTANCE: 'Mesafe (m)',
        GAS_COST: 'Gaz Maliyeti ($/m³)',
        COMPRESSOR_FLOW: 'BHP başına m³/h',
        DEVICE_ID: 'Cihaz Kimliği',
        COMPRESSOR_BHP: 'Kompresör BHP',
        COMPRESSOR_TYPE: 'Kompresör Tipi',
        MOTOR_EFFICIENCY: 'Motor Verimliliği (%)',
        ENERGY_COST: 'Enerji Maliyeti (kWh başına)',
        HOURS: 'Çalışma Saati/Yıl',
        LOCATION: 'Kaçak Konumu',
        LAST_MODIFIED: 'Son Kaydedilen Tarih',
        POWER_LOAD: '% Tam Yük Gücü',
        CLIENT: 'Müşteri',
        SERVICE: 'Hizmet',
        CAPACITY: '% Kapasite',
        EQUIPMENT_TYPE: 'Ekipman/Bileşen Türü',
        LEAK_TAG: 'Kaçak Etiketi #/İş Referansı #',
        REPAIRED_BY: 'Onaran',
        ALTITUDE: 'Rakım (m)',
        REQUIRED_ACTION: 'Gerekli Eylem',
        FREQUENCY_LOW_BOUND: 'Frekans (Düşük)',
        FREQUENCY_HIGH_BOUND: 'Frekans (Yüksek)',
        SENSITIVITY: 'Duyarlılık',
        KWH_PER_YEAR: 'kWh/yıl',
        ASSIGNED_BY: 'Atayan',
        ASSIGNED_TO: 'Atanan',
        ASSIGNED_DATE: 'Atama Tarihi',
        LOSS_PER_YEAR: 'Kayıp (yıl başına)',
        ASSIGNED: 'Atanmış',
        CREATOR_USER_ID: 'Kullanıcı Kimliği',
        CURRENCY: 'Para Birimi',
        COMPLETED_ON: 'Tamamlanma Tarihi',
        IMAGES: 'Kaçak Kaynak Görüntüleri',
        ACTION_STATUS_MODIFIED: 'Eylem Durumu Değiştirildi',
        NAMEPLATE_CAPACITY: 'Plaka Kapasitesi (m³/h)',
        FULL_LOAD_INPUT_POWER: 'Tam Yük Giriş Gücü (kW)',
        RATED_PRESSURE: 'Anma Basıncı (kPag)',
        PRESSURE_SETPOINT: 'Basınç Ayar Noktası (kPag)',
        TOTAL_AIR_STORAGE_SIZE: 'Toplam Hava Depolama Boyutu (l)',
        COMPRESSOR_SETPOINT_SEU: 'Kompresör Ayar Noktası (kPag)',
        COMPRESSOR_FLOW_SEU: 'Kompresör Akışı (m³/h)',
        SYSTEM_PRESSURE_SETPOINT: 'Sistem Basınç Ayar Noktası (kPag)',
        SYSTEM_MEASURED_FLOW: 'Sistem Ölçülen Akış (m³/h)',
        VOL_FLOW_FOUND: 'Bulunan Hacim Akışı (m³/h)',
        VOL_FLOW_REPAIRED: 'Onarılan Hacim Akışı (m³/h)',
        ENERGY_SAVING_OPPORTUNITY_FOUND: 'Enerji Tasarrufu Fırsatı (Bulunan) (kWh)',
        ENERGY_SAVING_REALIZED_FIXED: 'Gerçekleşen Enerji Tasarrufu (Onarılan) (kWh)',
        SYSTEM_CONTROL_PRESSURE_REDUCED: 'Sistem Kontrol Basıncı Azaltıldı (kPag)',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Flow (cfm)',
        AMBIENT_TEMPERATURE: 'Ambient Temperature (°F)',
        LEAK_PRESSURE: 'Pressure (psi)',
        LEAK_TEMPERATURE: 'Gas Temperature (°F)',
        DISTANCE: 'Distance (ft)',
        GAS_COST: 'Cost of Gas ($/ft³)',
        COMPRESSOR_FLOW: 'cfm per BHP',
        ALTITUDE: 'Altitude (ft)',
        NAMEPLATE_CAPACITY: 'Nameplate Capacity (cfm)',
        FULL_LOAD_INPUT_POWER: 'Full Load Input Power (BTU/h)',
        RATED_PRESSURE: 'Rated Pressure (psi)',
        PRESSURE_SETPOINT: 'Pressure Setpoint (psi)',
        TOTAL_AIR_STORAGE_SIZE: 'Total Air Storage Size (gal)',
        COMPRESSOR_SETPOINT_SEU: 'Compressor Setpoint (psi)',
        COMPRESSOR_FLOW_SEU: 'Compressor Flow (cfm)',
        SYSTEM_PRESSURE_SETPOINT: 'System Pressure Setpoint (psi)',
        SYSTEM_MEASURED_FLOW: 'System Measured Flow (cfm)',
        VOL_FLOW_FOUND: 'Volume Flow Found (cfm)',
        VOL_FLOW_REPAIRED: 'Volume Flow Repaired (cfm)',
        ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found) (BTU)',
        ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed) (BTU)',
        SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced (psi)',
      },
      userFields: {
        EMAIL: 'E-posta',
        ROLE: 'Rol',
        DEVICE_ID: 'Cihaz Kimliği',
        DATE_TIME: 'Oluşturuldu',
        ACTIVE: 'Durum',
        SOFTWARE_VERSION: 'Yazılım Sürümü',
        FIRMWARE_VERSION: 'Donanım Yazılımı Sürümü',
        FIRST_NAME: 'Ad',
        LAST_NAME: 'Soyad',
        COMPANY_SLUG: 'Şirket',
        COMPANY_NAME: 'Şirket',
      },
      auditFields: {
        documentId: 'Kayıt Kimlik Numarası',
        companyName: 'Şirket Adı',
        auditorName: 'Denetçi Adı',
        auditorEmail: 'Denetçi E-postası',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        comment: 'Yorumlar',
        isLocked: 'Denetim Kilidi',
      },
      deviceFields: {
        DEVICE_ID: 'Cihaz Kimliği',
        ACTIVE: 'Durum',
        REGISTERED_ON: 'Etkinleştirildiği Tarih',
        EVENT_COUNT: 'Kayıtlar',
        UNIQUE_COMPANIES: 'Şirketler',
        LAST_USED_AT: 'Son Kullanım Tarihi',
        LAST_USED_BY_NAME: 'Son Kullanılan İsim',
        LAST_USED_BY_EMAIL: 'Son Kullanılan E-posta',
        LOCATION: 'Son Kullanılan Konum',
        FIRMWARE_VERSION: 'Donanım Yazılımı Sürümü',
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: 'Bulunan Kaçak Sayısı',
          NO_OF_LEAKS_REPAIRED: 'Onarılan Kaçak Sayısı',
          PERCENT_LEAKS_REPAIRED: '% Onarılan Kaçak',
          VOL_FLOW_FOUND: 'Bulunan Hacim Akışı',
          VOL_FLOW_REPAIRED: 'Onarılan Hacim Akışı',
          PERCENT_VOL_FLOW_REPAIRED: '% Onarılan Hacim Akışı',
          VALUE_FOUND: 'Bulunan Değer',
          VALUE_REPAIRED: 'Onarılan Değer',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Enerji tasarrufu fırsatı (Bulunan)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Enerji tasarrufu fırsatı % (Bulunan vs hesaplanan veya ölçülen)',
          ENERGY_SAVING_REALIZED_FIXED: 'Gerçekleşen Enerji Tasarrufu (Onarılan)',
          ENERGY_SAVING_REALIZED_PERCENT: 'Gerçekleşen Enerji Tasarrufu % (Onarılan vs hesaplanan veya ölçülen)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Sistem kontrol basıncı azaltıldı',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Bulunan Hacim Akışı (cfm)',
          VOL_FLOW_REPAIRED: 'Onarılan Hacim Akışı (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Enerji Tasarrufu Fırsatı (Bulunan) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Gerçekleşen Enerji Tasarrufu (Onarılan) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Sistem Kontrol Basıncı Azaltıldı (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Performans Göstergesi',
          ENPI_VALUE: 'EnPI Değeri',
          ENB_VALUE: 'EnB Değeri',
          SUCCESS_THRESHOLD: 'Başarı Eşiği (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Atanan Kişi',
        assignedBy: 'Atayan Kişi',
        assignedDate: 'Atama Tarihi',
        requestReassign: 'Yeniden Atama Talep Et',
        actionStatus: 'Eylem Durumu',
        partsNeeded: 'Gerekli Parça',
        productionOutage: 'Kapatma Talep Edildi',
        timeToRepair: 'Onarım Süresi',
        repairCost: 'Onarım Maliyeti',
        closeOutRef: 'Kapanış Referansı',
        'assignmentRecord.documentId': 'Kayıt Kimliği',
        assignmentId: 'Atama Kimliği',
        owner: 'Sahip',
        companyName: 'Şirket',
        assignmentActive: 'Atama Durumu',
        'assignmentRecord.leakStatus.building': 'Ünite/Ekipman',
        'assignmentRecord.leakStatus.facility': 'Tesis',
        'assignmentRecord.leakStatus.routeName': 'Rota',
        'assignmentRecord.leakStatus.leakTag': 'Kaçak Etiketi #/İş Referansı #',
        'assignmentRecord.currency': 'Para Birimi',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Alınan Eylem',
        CLOSE_OUT_REF: 'Eylem Kapanış Referansı #',
        PARTS_NEEDED: 'Yeni parça/bileşen gerekli',
        PRODUCTION_OUTAGE: 'Üretim kesintiye uğradı',
        REPAIR_COST: 'Maliyet ($)',
        REQUEST_REASSIGN: 'Yeniden atama talep et',
        ACTION_STATUS: 'Eylem durumu',
        TIME_TO_REPAIR: 'Onarım için geçen süre (dakika)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Kaçak kategorisine göre akış',
          LOSS_BY_LEAK: 'Kaçak kategorisine göre kayıp',
          TAGS_BY_LEAK: 'Kaçak kategorisine göre etiketler',
          AVG_FLOW_BY_LEAK: 'Kaçak kategorisine göre ortalama akış',
          SAVING_OPT_BY_REPAIR: 'Onarım süresine göre fırsat ve tasarruf',
          FLOW_REPAIR_TIME: 'Onarım süresine göre akış',
          LOSS_REPAIR_TIME: 'Onarım süresine göre kayıp',
          FLOW_REPAIR_PROG: 'Onarım ilerlemesine göre akış',
          TOTAL_REPAIR_PROG: 'Onarım ilerlemesine göre kayıp',
          FLOW_BY_EQUIP_COMP: 'Ekipman/bileşene göre akış',
          LOSS_BY_EQUIP_COMP: 'Ekipman/bileşene göre kayıp',
          TAGS_BY_EQUIP_COMP: 'Ekipman/bileşene göre etiketler',
        },
        leakCategoryLabels: {
          LOW: 'Düşük',
          MEDIUM: 'Orta',
          HIGH: 'Yüksek',
        },
        actionStatus: {
          OPEN: 'Açık',
          COMPLETE: 'Tamamlandı',
        },
        equipmentTypes: {
          CONNECTOR: 'Bağlantı Elemanı',
          CONTROL_VALVE: 'Kontrol Vanası',
          HOSE: 'Hortum',
          TUBE_FITTING: 'Boru Bağlantı Parçası',
          METER: 'Sayaç',
          OPEN_ENDED_LINE: 'Açık Uçlu Hat',
          PRESSURE_RELIEF_VALVE: 'Basınç Tahliye Vanası',
          PUMP_SEAL: 'Pompa Contası',
          REGULATOR: 'Regülatör',
          VALVE: 'Vana',
          LEVEL_CONTROLLER: 'Seviye Kontrol Cihazı',
          POSITIONER: 'Konumlandırıcı',
          PRESSURE_CONTROLLER: 'Basınç Kontrol Cihazı',
          TRANSDUCER: 'Dönüştürücü',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Genel Pnömatik Enstrüman',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Genel Pistonlu Kompresör',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Genel Santrifüj Kompresör',
        },
        mainGasValues: {
          AIR: 'Hava',
          METHANE: 'Metan (CH4)',
          CARBON_DIOXIDE: 'Karbondioksit (CO2)',
          HYDROGEN: 'Hidrojen (H2)',
          HELIUM: 'Helyum (He)',
          NITROGEN: 'Azot (N2)',
          REFRIGERANT: 'Soğutucu (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oksijen (O)',
          WATER: 'Su (Buha',
        },
        leakTypeValues: {
          DEFAULT: 'Hiçbiri seçilmedi',
          HOLE: 'Delik',
          CRACK: 'Çatlak',
          FITTING: 'Bağlantı Parçası',
          FLANGE: 'Flanş',
          'GLAND/SEAL': 'Conta/Mühür',
          THREAD: 'Vida Dişi',
          'TUBE/HOSE': 'Boru/Hortum',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Basınçlı Hava',
          VACUUM: 'Vakum',
          STEAM: 'Buhar',
          PROCESS_GAS: 'Proses Gazı',
          LIQUID: 'Sıvı',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Rotary Vidalı',
          RECIPROCATING: 'Pistonlu',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Santrif',
        },
        labels: {
          LOW: 'Düşük',
          MEDIUM: 'Orta',
          HIGH: 'Yüksek',
          EQUIPMENT: 'Ekipman',
          SAVINGS: 'Tasarruf',
          OPPORTUNITY: 'Fırsat',
          LOSS: 'Kayıp',
          REPAIRED: 'Onarıldı',
          UNREPAIRED: 'Onarılmadı',
          LESS_15_DAYS: '15 günden az',
          DAYS_16_TO_30: '16 ila 30 gün',
          DAYS_31_TO_60: '31 ila 60 gün',
          DAYS_61_TO_90: '60 günden fazla',
          LESS_THAN_15_DAYS: 'Onarıldı < 15 Gün',
          '16_TO_30_DAYS': 'Onarıldı 16-30 Gün',
          '31_TO_60_DAYS': 'Onarıldı 31-60 Gün',
          MORE_THAN_60_DAYS: 'Onarıldı 61-90 Gün',
          AIR: 'Hava',
          METHANE: 'Metan (CH4)',
          CARBON_DIOXIDE: 'Karbondioksit (CO2)',
          HYDROGEN: 'Hidrojen (H2)',
          HELIUM: 'Helyum (He)',
          NITROGEN: 'Azot (N2)',
          REFRIGERANT: 'Soğutucu (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oksijen (O)',
          WATER: 'Su (Buha',
          DEFAULT: 'Hiçbiri seçilmedi',
          HOLE: 'Delik',
          CRACK: 'Çatlak',
          FITTING: 'Bağlantı Parçası',
          FLANGE: 'Flanş',
          'GLAND/SEAL': 'Conta/Mühür',
          THREAD: 'Vida Dişi',
          'TUBE/HOSE': 'Boru/Hortum',
          COMPRESSED_AIR: 'Basınçlı Hava',
          VACUUM: 'Vakum',
          STEAM: 'Buhar',
          PROCESS_GAS: 'Proses Gazı',
          LIQUID: 'Sıvı',
          ROTARY_SCREW: 'Rotary Vidalı',
          RECIPROCATING: 'Pistonlu',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Santrif',
          CONNECTOR: 'Bağlantı Elemanı',
          CONTROL_VALVE: 'Kontrol Vanası',
          HOSE: 'Hortum',
          TUBE_FITTING: 'Boru Bağlantı Parçası',
          METER: 'Sayaç',
          OPEN_ENDED_LINE: 'Açık Uçlu Hat',
          PRESSURE_RELIEF_VALVE: 'Basınç Tahliye Vanası',
          PUMP_SEAL: 'Pompa Contası',
          REGULATOR: 'Regülatör',
          VALVE: 'Vana',
          LEVEL_CONTROLLER: 'Seviye Kontrol Cihazı',
          POSITIONER: 'Konumlandırıcı',
          PRESSURE_CONTROLLER: 'Basınç Kontrol Cihazı',
          TRANSDUCER: 'Dönüştürücü',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Genel Pnömatik Enstrüman',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Genel Pistonlu Kompresör',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Genel Santrifüj Kompresör',
        },
        leakRecordPDF: 'Kaçak Olay Kaydı • Dışa Aktarıldı {{date}}',
        leakTag: 'Akıllı Kaçak Etiketleri • Dışa Aktarıldı {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Kompresör Etiketi',
          COMPRESSOR_MAKE: 'Kompresör Markası',
          COMPRESSOR_MODEL: 'Kompresör Modeli',
          COMPRESSOR_TYPE: 'Kompresör Türü',
          NAMEPLATE_HP: 'Plaka Beygir Gücü (HP)',
          NAMEPLATE_CAPACITY: 'Plaka Kapasitesi',
          MOTOR_EFFICIENCY: 'Motor Verimliliği (%)',
          MOTOR_VOLTAGE: 'Motor Voltajı',
          FULL_LOAD_CURRENT: 'Tam Yük Akımı (Amper)',
          FULL_LOAD_INPUT_POWER: 'Tam Yük Giriş Gücü (kW)',
          RATED_PRESSURE: 'Anma Basıncı',
          PRESSURE_SETPOINT: 'Basınç Ayar Noktası',
          CONTROL: 'Kontrol',
          TOTAL_AIR_STORAGE_SIZE: 'Toplam Hava Depolama Boyutu',
          IS_ACTIVE: 'Kompresör Aktif / Kapalı',
          OPERATION_TYPE: 'Trim / Temel Yük',
          PERCENT_LOAD: '% Yük',
          COMPRESSOR_SETPOINT_SEU: 'Kompresör Ayar Noktası',
          COMPRESSOR_FLOW_SEU: 'Kompresör Akışı',
          SYSTEM_PRESSURE_SETPOINT: 'Sistem Basınç Ayar Noktası',
          SYSTEM_MEASURED_FLOW: 'Sistem Ölçülen Akış',
          DATE_TIME_RUNNING_STATE: 'Çalışma Durumu Tarih ve Saati',
          NOTES: 'Notlar',
          NAMEPLATE: 'Kompresör İsim Plakası',
          DATASHEET: 'Kompresör Veri Sayfası',
          BHP: 'BHP',
          OPERATING_HOURS: 'Çalışma Saatleri',
          LOADED_PERCENT_TIME_RUNNING: '% Çalışma Süresi',
          LOADED_PERCENT_FULL_LOAD_BHP: '% Tam Yük BHP (Kapasite)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Çalışma Süresi',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% Tam Yük BHP (Kapasite)',
          ENERGY_COST: 'Toplam Elektrik Ücreti ($)',
          ANNUAL_ENERGY_COST: 'Yıllık Enerji Ücreti ($)',
          ANNUAL_ENERGY_KWH: 'Yıllık Enerji kWh',
          COST_FULLY_LOADED: 'Tam Yüklü Maliyet ($)',
          COST_UNLOADED: 'Boş Maliyet ($)',
          KWH_PER_YEAR_LOADED: 'Yüklü Yılda kWh',
          KWH_PER_YEAR_UNLOADED: 'Boş Yılda kWh',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Plaka Kapasitesi (cfm)',
          FULL_LOAD_INPUT_POWER: 'Tam Yük Giriş Gücü (BTU/h)',
          RATED_PRESSURE: 'Anma Basıncı (psi)',
          PRESSURE_SETPOINT: 'Basınç Ayar Noktası (psi)',
          TOTAL_AIR_STORAGE_SIZE: 'Toplam Hava Depolama Boyutu (galon)',
          COMPRESSOR_SETPOINT_SEU: 'Kompresör Ayar Noktası (psi)',
          COMPRESSOR_FLOW_SEU: 'Kompresör Akışı (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'Sistem Basınç Ayar Noktası (psi)',
          SYSTEM_MEASURED_FLOW: 'Sistem Ölçülen Akış (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: 'Kamu Hizmetleri Sağlayıcısı',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Birim',
          ENERGY_SOURCES: 'Enerji Kaynakları',
          FE_HOURS_OPERATION: 'Çalışma Saatleri',
          FE_ENERGY_COST: 'Enerji Maliyeti ($/kWh)',
          FE_EFFICIENCY: 'Verimlilik (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Verimlilik (kW/100cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'İstek doğrulama hatası. Lütfen form alanlarını kontrol edin.',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Cihaz Kaydet',
          help: 'Yardım',
          metric: 'Metrik',
          imperial: 'İmparatorluk',
          downloadApk: 'APK İndir',
          downloadOta: 'Dosya İndir',
        },
        otaDialog: {
          title: 'Dosya İndir',
          downloadButton: 'İndir',
          serialNumberLabel: 'Cihaz Seri Numarası',
          serialNumberRequired: 'Seri numarası gerekli',
          fileDownloaded: '{{fileName}} dosyası başarıyla indirildi',
          errorFetchingFile: 'Dosya alınırken hata oluştu. Lütfen daha sonra tekrar deneyin',
          selectSerialNumber: 'Bir cihaz seri numarası seçin',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Essential planındasınız.',
            dialogTwo: 'Buraya',
            dialogThree: 'tıklayın',
            dialogFour: 'yeni bir Yönetici lisansı satın almak için veya',
            dialogFive: 'support@prosaris.ca ile iletişime geçin.',
          },
          serviceUnavailable: 'Teknik zorluklar yaşıyoruz. Hizmetimiz etkilenebilir. Verdiğimiz rahatsızlıktan dolayı özür dileriz.',
          downForMaintenance: 'Bakım nedeniyle kapalıyız. Lütfen daha sonra tekrar deneyin.',
          tagsManagement: {
            dialogOne: 'satın alın Daha fazla',
            dialogTwo: 'PROSARIS Akıllı Kaçak Etiketi',
            dialogThree: 'buradan!',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menü',
            editProfile: 'Profili Düzenle',
            logout: 'Çıkış Yap',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Tümünü Temizle',
            readAll: 'Tümünü Oku',
            noNotifications: 'Bildirim Yok',
          },
        },
        index: {
          dashboard: 'Kontrol Paneli',
          records: 'Kayıtlar',
          actions: 'Eylemler',
          company: 'Şirket',
          tags: 'Etiketl',
        },
        loggedInAs: 'Giriş Yapıldı:',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'Alan {{field}} ile etiketi belirleyemiyor',
        cannontDeterminByLabel: 'Etiket {{label}} ile alanı belirleyemiyor',
        active: 'Aktif',
        closed: 'Kapalı',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Kontrol Paneli',
        records: 'Kayıtlar',
        actions: 'Eylemler',
        company: 'Şirket',
        tags: 'Etiketler',
        redirecting: 'Yönlendiriliyorsunuz...',
      },
      account: {
        index: {
          updatePassword: 'Şifre başarıyla güncellendi.',
          accountDeletedSuccess: 'Hesap {{user}} başarıyla silindi',
          accountDeletedError: 'Hesap şu anda silinemiyor. Lütfen daha sonra tekrar deneyin.',
          authenticationError: 'Kullanıcı doğrulama başarısız oldu',
          personalDetails: 'Kişisel Bilgiler',
          currentPasswordIsRequired: 'Mevcut parola gerekli',
          username: 'Kullanıcı Adı',
          changePassword: 'Şifre Değiştir',
          currentPassword: 'Mevcut Şifre',
          profilePicture: 'Profil Resmi',
          dangerZone: 'Tehlike Bölgesi',
          deleteAccountTitle: 'Hesabı Sil',
          deleteAccountMessage: 'Hesap artık kullanılamayacak ve tüm kişisel bilgiler kalıcı olarak silinecek.',
          alertTitle: 'Lütfen hesap silme işlemini onaylayın',
          deleteDialog: {
            dialogOne: 'Dikkat:',
            dialogTwo: 'Hesabınızı silmek, tüm kişisel bilgilerinizin veritabanımızdan kalıcı olarak kaldırılmasına neden olacaktır.',
            dialogThree: 'BU İŞLEM GERİ ALINAMAZ.',
            dialogFour: 'Devam etmek için lütfen parolanızı girin.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Önbellek başarıyla temizlendi!',
          editProfile: 'Profili Düzenle',
          clearCache: 'Önbelleği Temizle',
          alertTitle: 'Uygulama önbelleğini temizlemeyi onaylayın',
          deleteAll: 'Hepsini sil',
          accordionDialog: {
            dialogOne: 'Bu işlem, aşağıdaki sayfalar için kaydedilen tüm ayarları silecektir:',
            dialogTwo: 'Grafikler, Grafik Filtreleri, Zaman Aralığı',
            dialogThree: 'Filtreler, Sıralama, Sütunlar, Önizleme',
            dialogFour: 'Filtreler, Sütunlar',
            dialogFive: 'Seçilen şirket',
            dialogSix: 'Filtreler, Sütunlar',
          },
          deleteSelective: 'Seçerek Sil',
          selectiveDialog: 'Lütfen silmek istediğiniz kaydedilen ayarları seçin:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Zaman Aralığı',
            CHART_FILTERS: 'Grafik Filtreleri',
            CHARTS: 'Grafikler',
          },
          Records: {
            HIGHLIGHTED: 'Önizleme',
            COLUMNS: 'Sütunlar',
            FILTERS: 'Filtreler',
            SORT_COLUMN: 'Sıralama',
          },
          Actions: {
            FILTERS: 'Filtreler',
            COLUMNS: 'Sütunlar',
          },
          Company: {
            SELECTED: 'Seçilen şirket',
          },
          Tags: {
            FILTERS: 'Filtreler',
            COLUMNS: 'Sütunlar',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Şirket',
          'leakStatus.building': 'Ünite/Ekipman',
          'leakStatus.facility': 'Tesis',
          'leakStatus.leakTag': 'Kaçak Etiketi #/İş Referansı #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'Görüntülenecek atama yok',
        },
        filtersCommonWords: {
          recordsFilter: 'Kayıt Filtresi',
          column: 'Sütun',
          selectAColumn: 'Bir sütun seçin...',
          addSearchTerm: 'Bir arama terimi ekleyin...',
          filtersArentAvailable: 'Filtreler mevcut değil',
          value: 'Değer',
          exportCSV: "CSV'yi Dışa Aktar",
          show: 'Göster',
          loadMoreRecords: 'Daha Fazla Kayıt Yükle',
          selectedAllRecords: 'Tüm kayıtlar seçildi ({{totalNum}})',
          selectedFromRecords: '{{totalNum}} kayıttan {{selectedNum}} kayıt seçildi',
        },
        invalidColumn: 'Geçersiz sütun {{column}}',
        assignedActionRecords: 'Atanmış Eylem Kayıtları',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Şifrenizi mi unuttunuz?',
          successMessage: 'Onay e-postası gönderdik:',
        },
        authSuccess: {
          thanks: 'Teşekkürler!',
          backToLogin: 'Prosaris Giriş Sayfasına Geri Dön',
        },
        loginView: {
          forgotPass: 'Şifrenizi mi Unuttunuz?',
          createAccount: 'Hesap Oluştur',
          passwordIsRequired: 'Parola gerekli',
          resetPassword: 'Şifreyi sıfırla',
          contactSupport: 'Lütfen support@prosaris.ca ile iletişime geçin',
          incorrectEmailOrPassword: 'Yanlış e-posta veya parola',
          resetPassDialog: {
            dialogOne: 'Devam etmeden önce şifrenizi sıfırlamanız gerekiyor.',
            dialogTwo: 'Şifre sıfırlama talimatlarını içeren bir e-posta size gönderildi.',
          },
          ok: 'Tamam',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'Şifreniz başarıyla sıfırlandı',
          enterNewPassword: 'Aşağıya yeni şifrenizi girin.',
          passwordChanged: 'Şifreniz değiştirildi.',
        },
        createCompanyView: {
          createCompany: 'Bir Şirket Oluştur',
          companyName: 'Şirket Adı',
          successCreatingCompany: 'Şirketiniz oluşturuldu.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Kaçak verileri yüklenirken beklenmeyen bir hata oluştu',
          calculatingError: 'Hesaplama sırasında beklenmeyen bir hata oluştu',
          dbMeasured: 'Ölçülen Db',
          calculate: 'Hesapla',
          calculating: 'Hesaplanıyor...',
          outflowCoefficient: 'Dış Akış Katsayısı',
          kwhPerYear: 'kWh/yıl',
        },
      },
      companies: {
        commonUsedWords: {
          assigendAdminLicenses: 'Atanan Yönetici Lisansları',
          company: 'Şirket',
          totalAccounts: 'Toplam Hesaplar',
          email: 'E-posta',
          name: 'Ad',
          loadCompanyUsersError: 'Şirket kullanıcıları yüklenemedi. Lütfen daha sonra tekrar deneyin',
          activated: 'Aktif',
          deactivated: 'Deaktif',
          activating: 'Aktifleştiriliyor',
          deactivating: 'Deaktifleştiriliyor',
          deactivate: 'Deaktifleştir',
          activate: 'Aktifleştir',
          set: 'Ayarla',
          youAreGoingTo: 'Bunu yapıyorsunuz',
          areYouSure: 'Emin misiniz?',
          companyNameIsRequired: 'Şirket adı gerekli',
          editCompany: 'Şirketi Düzenle',
          createCompany: 'Şirket Oluştur',
          viewHistory: 'Geçmişi Görüntüle',
          exportCSV: 'CSV Dışa Aktar',
          onEssentialPlan: 'Essential planındasınız. Lütfen support@prosaris.ca ile iletişime geçin',
        },
        accounts: {
          accountDialog: {
            companyIsRequired: 'Şirket gerekli',
            roleIsRequired: 'Rol gerekli',
            createAccountSuccessMessage: 'Hesap başarıyla oluşturuldu',
            emailTakenErrorMessage: 'Bu e-posta ile kayıtlı bir hesap sistemde zaten mevcut',
            errorWhileCreatingAccountMessage: 'Hesap oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            changeAccountSuccessMessage: 'Hesap başarıyla güncellendi',
            errorWhileChangingAccountMessage: 'Hesap güncellenirken hata oluştu. Lütfen tekrar deneyin.',
            editAccount: 'Hesabı Düzenle',
            createAccount: 'Hesap Oluştur',
            role: 'Rol',
          },
          changeOwner: {
            changeOwnerMessage: 'Bu hesaba ait kaçak kayıtlarının sahibini de değiştirmek istiyor musunuz?',
            no: 'Hayır, mevcut hesabı koruyun',
          },
          contentCell: {
            loadAccountRolesError: 'Hesap rolleri bilgileri yüklenemedi. Lütfen daha sonra tekrar deneyin.',
            changeRoleSuccessMessage: 'Hesap {{email}} için yeni bir rol atandı',
            changeRoleErrorMessage: 'Hesap {{email}} için rol güncellenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            editUser: 'Kullanıcıyı Düzenle',
            adminSetPassword: 'Yönetici Şifreyi Ayarla',
            resetPassword: 'Şifreyi Sıfırla',
            user: 'Kullanıcı',
            deleteUser: 'Kullanıcıyı Sil',
          },
          index: {
            noUsersToDisplay: 'Görüntülenecek kullanıcı yok',
            changeStatusSuccessMessage: 'Kullanıcı başarıyla {{isActive}}',
            changeStatusErrorMessage: 'Kullanıcı {{isActive}} yapılırken hata oluştu. Lütfen tekrar deneyin',
            changeOwnerSuccessMessage: 'Kaçak kayıtları yeni sahibine aktarıldı',
            changeOwnerErrorMessage: 'Kayıtlar yeni sahibine aktarılamıyor. Lütfen daha sonra tekrar deneyin',
            deleteAccountMutationSuccessMessage: 'Hesap {{email}} başarıyla silindi',
            deleteAccountMutationErrorMessage: 'Hesap {{email}} şu anda silinemiyor. Lütfen daha sonra tekrar deneyin',
            resetAccountPassWordSuccessMessage: 'Şifre sıfırlama e-postası başarıyla gönderildi.',
            users: 'kullanıcılar',
            noneAvailable: 'Mevcut değil',
            of: 'nın',
            contactSupport: 'Şirket yönetici lisansları hakkında ek bilgi için lütfen support@prosaris.ca ile iletişime geçin',
            findAccount: 'Hesap Bul',
            placeholder: 'Ad veya e-posta girin...',
            tooltip: 'Kullanıcıları davet edebilmeniz için en az bir şirket yönetici lisansına ihtiyacınız var',
            invite: 'Davet Et',
            alertDialog: {
              messageOne: 'Kullanıcının şifre sıfırlama talebini onaylayın',
              messageTwo: 'Hesap durumundaki değişikliği onaylayın',
              messageThree: 'Hesap silme işlemini onaylayın',
            },
            sendAPasswordResetEmail: 'Aşağıdaki hesap için bir şifre sıfırlama e-postası göndermeye gidiyorsunuz:',
            confirmMessage: 'Devam etmek istiyor musunuz?',
            followingAccount: 'aşağıdaki hesap:',
            delete: 'SİL',
            userCreatedDialogTitle: 'Kullanıcı Şifre Sıfırlama Bağlantısı Başarıyla Gönderildi',
            userCreatedDialogDescription:
              'Kullanıcıya e-posta adresine bir şifre sıfırlama bağlantısı gönderildi. Ayrıca aşağıdaki bağlantıyı da kopyalayabilirsiniz.',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Hesap şifresi başarıyla güncellendi',
            changedUserPasswordErrorMessage: 'Hesap güncellenirken hata oluştu. Lütfen tekrar deneyin.',
            setNewPassword: 'Yeni Şifre Ayarla',
          },
          setPassword: {
            automatic: 'Güçlü bir şifre otomatik olarak oluştur',
            manual: 'Şifreyi manuel olarak oluştur',
            copyLinkInstruction: 'Bir sonraki adımda şifreyi görüntüleyip kopyalayabileceksiniz.',
          },
          userCreatedDialog: {
            title: 'Kullanıcı Başarıyla Oluşturuldu',
            description:
              'Yeni kullanıcı artık Prosaris kontrol panelini kullanmaya başlayabilir. Kullanıcı ayrıca şifresini sıfırlamak için bir bağlantı içeren bir e-posta alacaktır.',
            passwordCopied: 'Şifre panoya kopyalandı',
            passwordResetLinkCopied: 'Şifre sıfırlama bağlantısı panoya kopyalandı',
            copyPassword: 'Şifreyi Kopyala',
            copyPasswordResetLink: 'Şifre Sıfırlama Bağlantısını Kopyala',
          },
          useRoleOptions: {
            notAvailable: '(kullanılmıyor)',
            adminSeatTaken: '(yönetici koltuğu alınmış)',
            allSeatsAreTaken: '(tüm {{numSeats}} koltuklar dolu)',
            adminRolesTitle: 'Yönetici Rolleri',
            roles: {
              superAdmin: 'Prosaris Yönetici',
              companyAdmin: 'Şirket (Yönetici)',
              superCompanyAdmin: 'Süper Şirket (Yönetici)',
              companyRead: 'Şirket (Okuma)',
              superAdminRead: 'Prosaris Yönetici (Okuma)',
              integrator: 'Entegre Edici',
              auditor: 'Denetçi',
              user: 'Kullanıcı',
              essential: 'Prosaris Essential',
            },
            rolesDescriptions: {
              superAdmin: 'Birden fazla şirket üzerinde tam kontrole sahip olma yetkisi.',
              companyAdmin: 'Şirket kayıtlarını görüntüleme, düzenleme ve atama yetkisi.',
              superCompanyAdmin: 'Şirket kayıtları ve kullanıcılar üzerinde tam kontrole sahip olma yetkisi.',
              companyRead: 'Şirket kayıtlarını yalnızca okuma modunda görüntüleme yetkisi.',
              superAdminRead: 'Tüm şirketler için yalnızca okuma erişimine sahip olma yetkisi.',
              integrator: 'Şirketleri ve hesapları yönetmek için üçüncü taraf entegrasyonlarına izin verir.',
              auditor: 'Kaçak kayıtlarının denetimini sağlamak için.',
              user: 'Kendi kayıtlarını oluşturma/güncelleme/silme yetkisi.',
              essential: 'Sınırlı işlevselliğe sahip bir rol.',
            },
            nonAdminRolesTitle: 'Yönetici Olmayan Roller',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Denetimleri yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            audits: 'denetimler',
            noAuditsToDisplay: 'Görüntülenecek denetim yok',
            findByAuditorEmail: 'Denetçi E-postasına Göre Bul',
            placeholder: 'Denetçi e-posta adresini yazın...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Tesis Adı gereklidir',
              hoursYearlyIsRequired: 'Yıllık Toplam Saat gereklidir',
              totalHoursYearly: "Yıllık Toplam Saat 8760'ı geçemez",
              invalidPhoneNumber: 'Geçersiz telefon numarası',
              createErrorMessage: 'Saat x Vardiyalar 24 saati geçemez',
              loadFacilityErrorMessage: 'Tesisi yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin',
              createFacilitySuccessMessage: 'Tesis başarıyla oluşturuldu',
              createFacilityErrorMessage: 'Tesis oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin',
              changeFacilitySuccessMessage: 'Tesis başarıyla güncellendi',
              changeFacilityErrorMessage: 'Tesis güncellenirken hata oluştu. Lütfen tekrar deneyin',
              facilityName: 'Tesis Adı',
              abbreviation: 'Kısaltma',
              address: 'Adres',
              enterALocation: 'Bir konum girin',
              tooltip: 'Enerji Yönetimi faaliyetleri için, ayrıca Rapor Onaylayıcı',
              energyMgtOwner: 'Enerji Yönetimi Sahibi',
              title: 'Unvan',
              phone: 'Telefon',
              tooltipTwo: 'Çözüm yenilemeleri ve genel hesap sorunları için',
              accountOwner: 'Hesap Sahibi',
              manufacturingArea: 'Üretim Alanı',
              size: 'Boyut',
              unit: 'Birim',
              hoursOfOperation: 'Çalışma Saatleri',
              auditFrequency: 'Denetim Sıklığı',
            },
            operationHours: {
              daysPerWeek: 'Gün/Sıra',
              shiftsPerDay: 'Vardiya/Gün',
              hoursPerShift: 'Saat/Vardiya',
              weeksPerYear: 'Yıl başına 52 hafta',
              totalHoursYearly: 'Toplam = Saat/Yıl',
            },
            constants: {
              constant: 'Sabit',
              monthly: 'Aylık',
              threeMonth: '3 Aylık',
              fourMonth: '4 Aylık',
              sixMonth: '6 Aylık',
              nineMonth: '9 Aylık',
              annual: 'Yıllık',
              sqft: 'Kare Fit [SQFT]',
              sqm: 'Kare Metre [SQM]',
            },
          },
          index: {
            users: 'Kullanıcılar',
            devices: 'Cihazlar',
            facility: 'Tesis',
            energy: 'Enerji',
            seu: "SEU'lar - Bileşenler",
            enpi: 'EnPIs/EnBs',
            routes: 'Rotalar',
            audit: 'Denetimler',
            comingSoon: 'Yolda...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'EnPIs yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            createEnpisSuccessMessage: 'EnPIs başarıyla oluşturuldu',
            createEnpisErrorMessage: 'EnPIs oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            changeEnpisSuccessMessage: 'EnPIs başarıyla güncellendi',
            changeEnpisErrorMessage: 'EnPIs güncellenirken hata oluştu. Lütfen tekrar deneyin.',
            showHistory: 'Geçmişi Göster',
          },
          changeHistory: {
            bulkOperation: 'toplu işlem olarak',
            details: 'ayrıntılar',
            created: 'oluşturuldu',
            updated: 'güncellendi',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Geçmiş',
            noHistory: 'Geçmiş Yok',
          },
        },
        energy: {
          index: {
            utilProvider: 'Kamu Hizmetleri Sağlayıcısı',
            energyUse: 'Enerji Kullanımı ve Tüketim Türü',
            error: {
              loadFacility: 'Tesis yüklenemedi. Lütfen daha sonra tekrar deneyin',
              loadEnergy: 'Enerji verileri yüklenemedi. Lütfen daha sonra tekrar deneyin',
              loadUtilProviderLogo: 'Kamu hizmetleri sağlayıcısının logosu yüklenemedi. Lütfen daha sonra tekrar deneyin',
              createEnergy: 'Enerji verileri oluşturulamadı. Lütfen daha sonra tekrar deneyin',
              createUtilProviderLogo: 'Kamu hizmetleri sağlayıcısının logosu yüklenemedi. Lütfen daha sonra tekrar deneyin',
              updateEnergy: 'Enerji verileri güncellenemedi. Lütfen daha sonra tekrar deneyin',
              deleteUtilProviderLogo: 'Kamu hizmetleri sağlayıcısının logosu silinemedi. Lütfen daha sonra tekrar deneyin',
            },
            success: {
              createEnergy: 'Enerji verileri başarıyla oluşturuldu',
              updateEnergy: 'Enerji verileri başarıyla güncellendi',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: 'Enerji Maliyeti',
              energySources: 'Enerji Kaynakları',
              per: 'Başına',
              validations: {
                utilProviderIsRequired: 'Kamu hizmetleri sağlayıcısı gerekli',
                costOfEnergyIsRequired: 'Enerji maliyeti gerekli',
                costOfEnergyMustBeNumber: 'Enerji maliyeti bir sayı olmalıdır',
                costOfEnergyMustBeGreaterThanZero: 'Enerji maliyeti 0 veya daha büyük olmalıdır',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: "Dosya boyutu 1 MB'dan az olmalıdır",
                preview: 'Resim önizlemesi başarısız oldu',
              },
              logo: 'Logo',
              addLogo: 'Logo Ekle',
              removeLogo: 'Logoyu Kaldır',
            },
            EnergySources: {
              energySource: 'Enerji Kaynağı',
              percentage: 'Yüzde',
              selectEnergySource: 'Enerji kaynağını seçin',
              addEnergySource: 'Enerji kaynağı ekle',
              totalPercentage: 'Toplam yüzde',
              mustBeEqualTo100: '(100% olmalıdır)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Doğalgaz',
                  FUEL_OIL: 'Akaryakıt',
                  DIESEL: 'Dizel',
                  GASOLINE: 'Benzin',
                  PROPANE: 'Propan',
                  COAL: 'Kömür',
                  WIND: 'Rüzgar',
                  GEOTHERMAL: 'Jeotermal',
                  BIOMASS: 'Biyokütle',
                  STEAM: 'Buhar',
                  COMPRESSED_AIR: 'Basınçlı Hava',
                  CHILLED_WATER: 'Soğutulmuş Su',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Mevcut Hesaplama Yaklaşımı',
              currentCalcApproachMessage:
                "Bu hesaplama yaklaşımı, kullanıcı bunu değiştirmediyse varsayılan olarak tüm 'Enerji Maliyeti' alanlarına uygulanmaktadır.",
              alertTitle: 'Hesaplama Yaklaşımını Sıfırlama',
              alertMessageOne: "Bu işlem, tüm 'Enerji Maliyeti' alanları için mevcut hesaplama yaklaşımının kullanımını durduracaktır.",
              alertMessageTwo: 'Lütfen bu değişikliğin mevcut kayıtları etkilemeyeceğini unutmayın.',
              alertMessageThree: 'Sıfırlamayı devam ettirmek istiyor musunuz?',
            },
            ReportType: {
              calcApproach: 'Hesaplama Yaklaşımı',
              constants: {
                calculations: {
                  FIXED: 'Sabit Verimlilik',
                  USDOE: 'ABD DoE',
                  NREL: 'NREL Bölüm 22',
                },
              },
              setAsCurrent: 'Mevcut olarak ayarla',
              setAsCurrentMessage: "Bu hesaplama yaklaşımı, gelecekte tüm 'Enerji Maliyeti' alanları için kullanılacaktır.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: 'Verimlilik 0 veya daha büyük olmalıdır',
                  efficiencyMustBeNumber: 'Verimlilik bir sayı olmalıdır',
                  operationHoursMustBeGreaterThanZero: 'Çalışma saatleri 0 veya daha büyük olmalıdır',
                  operationHoursMustBeLessThan8760: "Çalışma saatleri 8760'dan az olmalıdır",
                  operationHoursMustBeNumber: 'Çalışma saatleri bir sayı olmalıdır',
                },
              },
              UsDoe: {
                loaded: 'Yüklü',
                unloaded: 'Boşaltılmış',
                calcSummary: 'Hesaplama Özeti',
                currentOperatingSystems: 'Mevcut İşletim Sistemleri',
                noCompsFoundAddCompOne: 'Hiç kompresör bulunamadı. Bir kompresör ekleyin',
                noCompsFoundAddCompTwo: 'yukarıdaki sekmeden.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Plaka yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            dataSheetError: 'Veri sayfası yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            createSuccess: 'Kompresör {{tag}} oluşturuldu',
            createError: 'Kompresör oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            createCompressor: 'Kompresör Oluştur',
          },
          compressorFile: {
            tip: 'Dosya Ekle',
            removeFile: 'Dosyayı Kaldır',
            removeImage: 'Resmi Kaldır',
            title: 'Kompresör {{prop}} silme işlemini onaylayın',
            proceed: 'Devam etmek istiyor musunuz?',
          },
          compressorForm: {
            compTagIsReq: 'Kompresör Etiketi gerekli',
            hpMustBeNum: 'Güç plakası HP bir sayı olmalıdır',
            capMustBeNum: 'Güç plakası kapasitesi bir sayı olmalıdır',
            motorEffMustBeNum: 'Motor verimliliği bir sayı olmalıdır',
            motorVoltMustBeNum: 'Motor voltajı bir sayı olmalıdır',
            loadMustBeNum: 'Tam yük akımı bir sayı olmalıdır',
            loadInputMustBeNum: 'Tam yük giriş gücü bir sayı olmalıdır',
            pressureMustBeNum: 'Nominal basınç bir sayı olmalıdır',
            setpointMustBeNum: 'Basınç ayar noktası bir sayı olmalıdır',
            airMustBeNum: 'Toplam hava depolama boyutu bir sayı olmalıdır',
            addImage: 'Resim ekle',
            addFile: 'Dosya ekle',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Rotary Vidalı',
              RECIPROCATING: 'Pistonlu',
              CENTRIFUGAL: 'Santrifüj',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Yükleme/Boşaltma – Yağsız',
              loadUnload1Gal: 'Yükleme/Boşaltma (1 gal/CFM)',
              loadUnload3Gal: 'Yükleme/Boşaltma (3 gal/CFM)',
              loadUnload5Gal: 'Yükleme/Boşaltma (5 gal/CFM)',
              loadUnload10Gal: 'Yükleme/Boşaltma (10 gal/CFM)',
              ivmWithBlowdown: 'Giriş Vanası Modülasyonu (Boşaltma Olmadan)',
              ivmWithoutBlowdown: 'Giriş Vanası Modülasyonu (Boşaltma ile)',
              variableDisplacement: 'Değişken Deplasman',
              vsdWithUnloading: 'VSD ile Boşaltma',
              vsdWithStopping: 'VSD ile Durdurma',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'Aç/Kapa Kontrolü',
              reciprocatingLoadUnload: 'Yükleme/Boşaltma',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV ile Boşaltma',
              centrifugalInletIbv: 'IGV ile Boşaltma',
            },
          },
          contentCell: {
            min: '% Yük en az 0 olmalıdır',
            max: "% Yük 100'den fazla olamaz",
            typeError: 'Yük geçerli bir yüzde olmalıdır',
            setpointMustBeNum: 'Kompresör Ayar Noktası bir sayı olmalıdır',
            flowMustBeNum: 'Kompresör Akışı bir sayı olmalıdır',
            pressureSetpointMustBeNum: 'Sistem Basınç Ayar Noktası bir sayı olmalıdır',
            measuredFlowMustBeNum: 'Sistem Ölçülen Akış bir sayı olmalıdır',
            dateTime: 'Tarih ve Saat Seçin',
            active: 'AKTİF',
            off: 'KAPALI',
            trim: 'trim',
            baseLoad: 'temel Yük',
          },
          index: {
            error: 'Kompresörleri yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            nameplateError: 'Plakayı yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            dataSheetError: 'Veri sayfasını yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            changeCompSuccess: 'Kompresör başarıyla güncellendi.',
            changeCompError: 'Kompresörü güncellerken hata oluştu. Lütfen tekrar deneyin.',
            deleteCompSuccess: 'Kompresör başarıyla silindi.',
            deleteCompError: 'Kompresörü silerken hata oluştu. Lütfen tekrar deneyin.',
            createCompNameplateSuccess: 'Kompresör plakasını başarıyla yüklendi.',
            createCompNameplateError: 'Plakayı yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            deleteCompNameplateSuccess: 'Kompresör plakasını başarıyla silindi.',
            deleteCompNameplateError: 'Kompresör plakasını silerken hata oluştu. Lütfen tekrar deneyin.',
            createCompDataSheetSuccess: 'Kompresör veri sayfasını başarıyla yüklendi.',
            createCompDataSheetError: 'Veri sayfasını yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            deleteCompDataSheetSuccess: 'Kompresör veri sayfasını başarıyla silindi.',
            deleteCompDataSheetError: 'Kompresör veri sayfasını silerken hata oluştu. Lütfen tekrar deneyin.',
            changeCompanyCompSuccess: 'Sistem verileri başarıyla güncellendi.',
            changeCompanyCompError: 'Sistem verilerini güncellerken hata oluştu. Lütfen tekrar deneyin.',
            compDesignData: 'Kompresör Tasarım Verileri',
            comp: 'Kompresörler:',
            addComp: 'Kompresör Ekle',
            editCompPermission: 'Kompresörü düzenlemek için bir yönetici lisansına ihtiyacınız var. Lütfen iletişime geçin.',
            selectOption: 'Bir seçenek seçin',
            undoChanges: 'Değişiklikleri Geri Al',
            selectComp: 'Yukarıdan bir kompresör seçin.',
            noCompsFound: 'Kompresör bulunamadı.',
            compRunningState: 'Kompresör Çalışma Durumu',
            noCompsFoundAddComp: 'Kompresör bulunamadı. Yukarıdaki bölümden bir kompresör ekleyin.',
            title: 'Kompresör silme işlemini onaylayın.',
            areYouSure: 'Gerçekten silmek istiyor musunuz?',
            compressor: 'kompresör',
            delete: 'sil',
          },
        },
        devices: {
          contentCell: {
            device: 'Cihaz',
            usedByMultipleCompanies: 'birden fazla şirket tarafından kullanılıyor.',
          },
          index: {
            noDevicesToDisplay: 'Görüntülenecek cihaz yok',
            loadingDevicesErrorMessage: 'Şirket cihazları yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            changeStatusSuccessMessage: 'Cihaz başarıyla {{isActive}}',
            changeStatusErrorMessage: 'Cihaz {{isActive}} yapılırken hata oluştu. Lütfen tekrar deneyin.',
            devices: 'cihazlar',
            findDevice: 'Cihaz Bul',
            placeholder: 'Cihaz kimliğini yazın...',
            activatedOn: 'Etkinleştirildiği Tarih',
            title: 'Cihaz durumundaki değişikliği onaylayın',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} başarıyla silindi',
          deleteCompanyErrorMessage: '{{companyName}} silinirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          needAdminLicense:
            'Bu özelliğe erişmek için bir yönetici lisansına ihtiyacınız var. Lütfen support@prosaris.ca ile iletişime geçin.',
          deleteChildCompanyFirst:
            'Alt şirketler veya hesaplar olan bir şirketi silemezsiniz. Lütfen önce tüm alt şirketleri ve hesapları silin.',
          editSettings: 'Ayarları Düzenle',
          createASubCompany: '{{companyName}} için bir alt şirket oluştur',
          deleteCompany: 'Şirketi Sil',
          cannotCreateChildCompanyDialog: {
            messageOne:
              'Süper Şirket Yönetici hesabınızla oluşturulabilecek maksimum şirket sayısına ulaştınız. Lütfen ek bilgiler için iletişime geçin ',
            messageTwo: 'ek detaylar için.',
          },
          deleteCompanyDialog: {
            title: 'Lütfen şirket silme işlemini onaylayın',
            delete: 'Sil',
            attention: 'Dikkat:',
            deleting: 'Silmek,',
            permanentlyDeleted: 'veritabanımızdan kalıcı olarak kaldırılacaktır.',
            cannotBeUndone: 'BU İŞLEM GERİ ALINAMAZ.',
          },
        },
        companyDialog: {
          industryIsRequired: 'Sektör gereklidir',
          createLogoErroerMessage: 'Resim yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          deleteLogoErrorMessage: 'Resmi silerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          createCompanySuccessMessage: 'Şirket {{name}} oluşturuldu',
          createCompanyErrorMessage: 'Bir şirket oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          changeCompanySuccessMessage: 'Şirket {{name}} güncellendi',
          changeCompanyErrorMessage: 'Şirket parametresini değiştirme işlemi başarısız oldu. Lütfen daha sonra tekrar deneyin.',
          language: 'Dil',
          industry: 'Sektör',
          country: 'Ülke',
          currency: 'Para Birimi',
        },
        companyLogo: {
          setImageErrorMessage: "Dosya boyutu 1 MB'dan az olmalıdır",
          logo: 'Logo',
          add: 'Ekle',
          removeLogo: 'Logoyu Kaldır',
          addLogo: 'Logo Ekle',
        },
        index: {
          loadCompaniesErrorMessage: 'Şirketler listesini yüklerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          changeCompaniesSectionWidth: 'Şirketler Bölümü Genişliğini Değiştir',
        },
        sidebar: {
          filterCompanies: 'Şirketleri Filtrele',
          placeholder: 'Şirket adını girin',
          myCompany: 'Benim Şirketim',
          childCompanies: 'Alt Şirketler',
        },
        routes: {
          index: {
            noFacility: 'Tanımlı bir tesisiniz yok',
            createAFacility: 'Rota planlamaya başlamak için bir tesis oluşturun',
            goToCreateAFacility: 'Tesis oluşturmaya git',
            createAFacilityTooltip: 'Rota planlamaya başlamadan önce bir tesis oluşturmanız gerekiyor.',
            save: 'Kaydet',
            reset: 'Sıfırla',
            routePlans: 'Rota Planları',
            loading: 'Yükleniyor...',
            noRoutesAdded: 'Hiçbir rota eklenmedi.',
            buildARouteTooltip: 'Rota Oluşturucu kullanarak bir rota oluşturun.',
            createFloorplanSuccess: 'Başarılı! Kat planı yüklendi!',
            createRouteSuccess: 'Başarılı! Rota oluşturuldu!',
          },
          routeBuilder: {
            title: 'Rota Oluşturucu',
            missingFields: 'Excel dosyasında eksik alanlar var',
            manuallyBuildARoute: 'Manuel Olarak Yeni Bir Rota Planı Oluştur',
            manualInstructions: 'Rotaları manuel olarak veya bir Excel dosyası yükleyerek oluşturabilirsiniz.',
            facility: 'Tesis',
            route: 'Rota',
            enterRoute: 'Rotayı Girin',
            addFloorplan: 'Kat Planı Ekle',
            floorplanInstructionsTooltip:
              'Rota oluşturmanıza yardımcı olmak için bir kat planı resmi ekleyin. Rota kaydedildiğinde kat planının üzerine yerleştirilecektir.',
            change: 'Değiştir',
            location: 'Konum',
            enterLocation: 'Konumu Girin',
            equipment: 'Ekipman',
            enterEquipment: 'Ekipmanı Girin',
            criticality: 'Kritiklik',
            enterCriticality: 'Kritikliği Girin',
            description: 'Açıklama',
            enterDescription: 'Açıklamayı Girin',
            addEquipment: 'Ekipman Ekle',
            addRoute: 'Rota Ekle',
            downloadTemplate: 'Şablonu İndir',
          },
          excelReader: {
            loadFromFile: 'Dosyadan Yükle',
            instructionsTooltip:
              'Her sayfanın bir tesisteki farklı bir konumu temsil ettiği bir Excel dosyasını yükleyerek rotalar oluşturabilirsiniz. Her konumda birden fazla rota olabilir.',
          },
          graph: {
            controls: {
              addFloorplan: 'Kat Planı Ekle',
              toggleLabels: 'Etiketleri Değiştir',
              addWaypoint: 'Yol Noktası Ekle',
              addEquipment: 'Ekipman Ekle',
              swapEquipment: 'Ekipman Değiştir',
              showLeakStats: 'Kaçak İstatistiklerini Göster',
              save: 'Kaydet',
              delete: 'Sil',
            },
            routeStats: {
              title: 'Rota İstatistikleri',
              totalDollarLossPerYear: 'Yıllık Toplam $ Kayıp:',
              totalKwhLossPerYear: 'Yıllık Toplam Kwh Kayıp:',
              totalLeakCount: 'Toplam Kaçak Sayısı:',
              totalActiveOpen: 'Toplam Aktif Açık:',
              totalActiveClosed: 'Toplam Aktif Kapalı:',
              noStatsAvailable: 'Kullanılabilir istatistik yok:',
              close: 'Kapat',
            },
            nodeDetails: {
              edit: 'Düzenle',
              name: 'Adı',
              description: 'Açıklama',
              criticality: 'Kritiklik',
              equipmentInformation: 'Ekipman Bilgisi',
              thisIsAWaypoint: 'Bu bir yol noktasıdır',
              activeOpenLeakCount: 'Aktif Açık Kaçak Sayısı:',
              noActiveLeaksDetected: 'Aktif kaçak tespit edilmedi',
              close: 'Kapat',
              nothingToSeeHere: 'Burada görülecek bir şey yok',
              seeLeakRecords: 'Kaçak Kayıtlarını Görüntüle',
            },
            swapEquipment: {
              title: 'Ekipman Değiştir',
              selectEquipment: 'Değiştirmek için ekipman seçin:',
              selectFirst: 'İlk ekipmanı seçin',
              selectSecond: 'İkinci ekipmanı seçin',
              swap: 'Değiştir',
              cancel: 'İptal',
            },
            addNode: {
              title: 'Ekle',
              waypoint: 'Yol Noktası',
              equipment: 'Ekipman',
              afterWhich: 'Hangi ekipmandan sonra?',
              selectPreceding: 'Önceki ekipmanı seçin:',
              selectEquipment: 'Ekipman seçin',
              name: 'Adı',
              description: 'Açıklama',
              criticality: 'Kritiklik',
              add: 'Ekle',
              cancel: 'İptal',
            },
            save: 'Kaydet',
            delete: 'Sil',
            successRouteUpdated: 'Rota başarıyla güncellendi',
            errorRouteUpdated: 'Rota güncellenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            successRouteDeleted: 'Rota başarıyla silindi',
            errorRouteDeleted: 'Rota silinirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
            successFloorplanUpdated: 'Kat planı başarıyla güncellendi',
            errorFloorplanUpdated: 'Kat planı güncellenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Atanan Yönetici Lisansları',
            of: 'nın',
            edit: 'Düzenle',
          },
          essentialMode: {
            disable: 'devre dışı bırak',
            enable: 'etkinleştir',
            essential: 'Temel Mod:',
            enabled: 'ETKİN',
            disabled: 'DEVRE DIŞI',
            moveCompany: "Şirket {{company}}'yi Temel Moda taşı",
            affectedCompanies: 'Etkilenen şirket sayısı',
            affectedAccounts: 'Etkilenen hesap sayısı',
            archivedLeak: 'Arşivlenmiş kaçak olay kayıtlarının sayısı',
            applyingChanges:
              'Bu değişiklikleri uygulayarak hesaplar "Temel" rolünü alacaktır. Kullanıcılar, bir kontrol panelinde ve mobil uygulamada {{quota}} en son kaçak olay kaydını görebilecektir. Diğer kayıtlar arşivlenecektir.',
            essentialLabel: 'Alt şirketler için Temel Modu Değiştir',
            turnOffEssential: 'Şirket {{company}} için Temel Modu kapat',
            byApplyingChanges:
              'Bu değişiklikleri uygulayarak tüm hesaplar "Kullanıcı" rolünü alacaktır. Kullanıcılar, tüm kayıtlarını görebilecektir. Tüm kayıtlar arşivden çıkarılacaktır.',
          },
          index: 'Ayarlar',
        },
        index: {
          loadCompanyError: 'Şirket bilgileri yüklenemiyor. Lütfen daha sonra tekrar deneyin.',
          companySlug: 'Şirket Slugu',
          notSelected: 'Seçilmedi',
          country: 'Ülke',
          accounts: 'Hesaplar',
          currency: 'Para Birimi',
          adminLicenses: 'Yönetici Lisansları',
          totalCustomer: 'Ağaçta Toplam Müşteri Hesapları',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Cihazınızı Kaydedin',
          toolTip: 'Lütfen kayıt kartınızda veya kutuda seri numarasını bulun.',
          serialNumberIsRequired: 'Seri numarası gereklidir',
          oops: 'Hups!',
          deviceNotFound: 'Cihaz seri numarası bulunamadı',
          deviceFoundNdAssociatedCompany: 'Cihazınız bulundu ve bir şirketle ilişkilidir.',
          deviceFound: 'Cihazınız bulundu.',
          goToLogin: 'Giriş Yap’a Git',
          contactSupport: 'Lütfen destek ile iletişime geçin',
        },
        deviceErrorCodes: {
          'B13:001': 'Cihaz seri numarası bulunamadı',
          'B13:002': 'Cihaz zaten etkinleştirilmiş',
          'B13:003': 'Yönetici e-postası gönderilmedi',
          'B13:004': 'Şirket bulunamadı',
          'B13:005': 'Geçersiz şirket',
          'B13:006': 'Geçersiz tür',
        },
        registerAccountView: {
          registerAccount: 'Bir Prosaris Hesabı Oluştur',
          deviceType: 'Cihaz Türü',
          termsMustBeAccepted: 'Şartlar ve koşullar kabul edilmelidir.',
          saleType012Success: 'Cihazınızı kullanmaya başlamak için giriş yapabilirsiniz.',
          saleType3Success: 'Temel kullanıcı olarak eklenmişsinizdir. Şirket yöneticileriniz bilgilendirilmiştir.',
          saleType4Success: {
            companyPlatformFound: 'Şirket Platform lisansı bulundu.',
            dialogOne: 'Bu cihaz yeni bir şirket platform lisansı ile ilişkilidir.',
            dialogTwo: 'Şirketiniz sistemimizde zaten mevcut olabilir.',
            dialogThree: 'Eğer öyleyse, lütfen yöneticinizle iletişime geçin.',
          },
          termsAndConditions: {
            dialogOne: 'Prosaris’i kabul ediyorum.',
            dialogTwo: 'Hizmet Şartları',
            dialogThree:
              "ve yeterince zaman tanındığını onaylıyorum, ayrıca bunun Prosaris yazılımını kullanmamla ilgili önemli şartlar içerdiğini, bu şartların arasında Prosaris'in sorumluluğunun sınırlandırılması ve verilerimin nasıl işleneceği konularının da bulunduğunu anlıyorum.",
          },
          acceptTerms: 'Şartları Kabul Et',
          registerAccountErrorCodes: {
            'B02:001': 'Geçersiz kayıt kodu',
            'B02:002': 'Geçersiz kullanıcı şirketi',
            'B02:003': 'Geçersiz kullanıcı rolü',
            'B02:004': 'E-posta zaten alınmış',
            'B02:005': 'E-posta zaten alınmış',
            'B02:006': 'Hesabınız için şifre sıfırlanamıyor',
            'B02:007': 'Geçersiz kullanıcı şirketi',
            'B02:008': 'Profil fotoğrafı eksik',
            'B02:009': 'Hesap sıfırlaması gereklidir. İşlem başlatıldı',
            'B02:010': 'Hesap devre dışı bırakıldı',
            'B02:011': 'Profil fotoğrafı geçersiz format',
            'B02:012': 'Cihaz zaten bir şirkete kayıtlı',
            'B02:013': 'Seri numarası tanımsız',
            'B02:014': 'Geçersiz tür',
            'B02:015': 'Şifre sıfırlama bağlantısı süresi dolmuş',
            'B02:016': 'Bu şirket için bir hesap oluşturulamadı',
            'B02:017': 'Geçersiz rol',
            'B02:018': 'Geçersiz rol',
            'B02:019': 'Geçersiz kullanıcı şirketi',
            'B02:020': 'Geçersiz kullanıcı rolü',
            'B02:021': 'Kullanıcı bu şirkete ait değil',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Seri numaraları gereklidir',
          failedToCopy: 'Panoya kopyalanan seri numaraları başarısız oldu',
          invalidFormat: 'Bir veya daha fazla cihazın geçersiz formatı var. Lütfen kontrol edin ve tekrar deneyin.',
          devices: 'Cihazlarınızı kaydedin',
          deviceSerialNumber: 'Cihaz Seri Numarası(ları)',
          note: 'Not: Her seri numarasını yeni bir satıra veya virgülle ayırarak girin.',
          title: 'Lütfen kayıt kartınızda veya kutuda seri numarasını bulun.',
          register: 'Kaydet',
          deviceRegistrationStatus: 'Cihaz Kayıt Durumu',
          success: 'Başarılı',
          noDeviceFound: 'Cihaz bulunamadı.',
          failed: 'Başarısız',
          copyFailed: 'Başarısız seri numaralarını panoya kopyala',
        },
      },
      errors: {
        notFoundView: {
          title: 'Aradığınız sayfa burada değil',
          description: 'Ya şüpheli bir yol denediniz ya da buraya yanlışlıkla geldiniz. Her neyse, lütfen navigasyonu kullanmayı deneyin.',
        },
        accountNotFound: {
          title: 'Hesap Bulunamadı',
          description:
            'Lütfen tekrar deneyin! Eğer sorun devam ederse, hesabınız şu anda devre dışı olabilir veya yönetici tarafından henüz başlatılmamış olabilir. Lütfen yöneticinizle iletişime geçin.',
          logout: 'Çıkış Yap',
        },
      },
      integration: {
        index: {
          loggingInError: "Prosaris'e giriş yaparken hata oluştu.",
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'Görüntülenecek resim yok.',
          activeActionRecord: {
            updateAssignmentSuccess: 'Atanan eylem güncellendi.',
            updateAssignmentError: 'Atanan eylemi güncellerken hata oluştu.',
            deleteImageSuccess: 'Atanan resim silindi.',
            deleteImageError: 'Atanan eylem resmini silerken hata oluştu.',
            confirmMarkAction: 'Bu eylemi tamamlandı olarak işaretlemek istiyor musunuz?',
            confirmDeleteImage: 'Bu resmi silmek istiyor musunuz?',
          },
          index: 'Bir kayıt için atanan eylemleri yüklerken hata oluştu.',
        },
        audio: {
          noAudioAvailable: 'Ses verisi mevcut değil',
          playPause: 'Oynat / Duraklat',
          generateRegion: 'Bölge Oluştur',
          removeLastRegion: 'Son Bölgeyi Kaldır',
          generateMarker: 'İşaretçi Oluştur',
          removeLastMarker: 'Son İşaretçiyi Kaldır',
          toggleTimeline: 'Zaman Çizelgesini Değiştir',
          download: 'İndir',
        },
        audit: {
          updateAuditSuccess: 'Denetim başarıyla güncellendi',
          updateAuditError: 'Yorum kaydedilirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          createAuditSuccess: 'Denetim başarıyla oluşturuldu',
          createAuditError: 'Denetim oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          deleteAuditSuccess: 'Denetim başarıyla silindi',
          deleteAuditError: 'Denetimi silerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          noComment: 'Yorum yok',
          reviewComments: 'Yorumları İncele',
          reviewedBy: 'İnceleyen:',
          auditDate: 'Denetim Tarihi:',
          deleteAudit: 'Denetimi Sil',
          reviewedNoComments: 'Yorum Yok Olarak İncelendi',
          auditComments: 'Denetim Yorumları',
          saveComment: 'Yorumu Kaydet',
          discardChanges: 'Değişiklikleri İptal Et',
        },
        changeHistory: {
          created: 'oluşturuldu',
          updated: 'güncellendi',
          deleted: 'silindi',
          transferred: 'aktarıldı',
          leak: 'Kaçak',
          images: 'Görüntü',
          audit: 'Denetim',
          audio: 'Ses',
          history: 'Geçmiş',
          noHistory: 'Geçmiş Yok',
          bulk: 'toplu işlem olarak',
          details: 'ayrıntılar',
          unset: 'belirlenmemiş',
        },
        fft: {
          fft: 'FFT',
          fftAvg: 'FFT Ortalaması',
          notApplicable: 'Uygulanamaz',
          chartLayers: 'grafik-katmanları',
          avg: 'Ortalama',
          all: 'Tümü',
          playing: 'Çalınıyor...',
          showTimeSeries: 'Zaman Serisini Göster',
        },
        form: {
          updateLeakSuccess: 'Başarıyla güncellendi.',
          updateLeakError: 'Kaçak güncellenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          alertTitle: {
            partOne: 'Kaçak kaydı şu durumda:',
            partTwo: 'sadece okunur',
            partThree: 'mod.',
          },
          alertDialog: {
            dialogOne: 'Ancak, aşağıdaki özellikler değiştirilebilir:',
            dialogTwo: 'Eylem Durumu',
          },
          GPSLocation: 'GPS Konumu',
          completedOn: 'Tamamlanma Tarihi:',
          lastSaved: 'Son Kaydedilen:',
        },
        images: {
          removeSuccess: 'Resim başarıyla silindi.',
          removeError: 'Kaçak kaydı resmini silinemiyor. Lütfen daha sonra tekrar deneyin.',
          text: 'Bu resmi silmek istediğinizden emin misiniz?',
        },
        index: {
          title: 'Kaçak Olay Kaydı',
          loadingError: 'Kaçak kaydını yüklerken hata oluştu. Lütfen tekrar deneyin.',
          unsavedMessage: "'Tamam'a basarak, kaydedilmemiş tüm değişikliklerin silineceğini onaylıyorsunuz.",
          notAnAuditor: 'Bir denetçi değilsiniz.',
          anEssential: 'Temel planındasınız.',
          showHistory: 'Geçmişi Göster',
        },
        massFlow: {
          recalculating: 'Kaçak parametre ayarlarına göre Akış/Kayıp yeniden hesaplanıyor...',
          warning: 'Giriş değerleri değişti veya hesaplama güncel değil, Akış ve Kayıp değerlerini yenilemek için `Yenile` butonuna basın.',
          calculatingError: 'Kütle akışını hesaplarken hata oluştu. Lütfen tekrar deneyin.',
          noValue: 'Değer Yok',
          calculatingSuccess: 'Akış/Kayıp Yeniden Hesaplandı',
          recalculate: 'Yeniden Hesapla',
          calculatorVersion: 'Hesaplama sürümü:',
          noteDialog: {
            note: 'Not:',
            dialogOne: 'Kaçak parametre ayarlarını değiştirmek, Akış/Kayıp hesaplamasını güncelleyecektir. Kontrol edin',
            dialogTwo: 'hesaplama içgörüleri',
            dialogThree: 'sayfası için ek bilgi',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Kaçak kaydı değiştirildi',
          close: 'kapat',
          unsavedChanges: 'Bu kaydın kaydedilmemiş değişiklikleri var. Lütfen bu değişiklikleri kaydetmeyi veya tamamen silmeyi düşünün.',
          discardChanges: 'Değişiklikleri İptal Et',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'Cihaz Kimliği gereklidir',
          dbPeakIsRequired: 'Ölçülen Tepe dB gerekli',
          dbMin: "Ölçülen Tepe dB 0'dan büyük veya eşit olmalıdır",
          dbMax: "Ölçülen Tepe dB 95'ten küçük veya eşit olmalıdır",
          dbError: 'Ölçülen Tepe dB bir sayı olmalıdır',
          imageUploadError: 'Resim yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          createLeakSuccess: 'Kaçak kaydı başarıyla eklendi',
          createLeakError: 'Hesap oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          basicInfo: 'Temel Bilgiler',
          flowInput: 'Akış Girişi',
          mainGas: 'İlgili Ana Gaz/Bileşen',
          selectComponent: 'Bileşeni Seç',
          selectLeakType: 'Kaçak türünü seç',
          mightBeSomethingHere: 'henüz emin değilim',
          energyLoss: 'Enerji Kaybı',
          selectCompressorType: 'Kompresör Türünü Seç',
          noRoutes: 'Rotalar mevcut değil',
          noEquipment: 'Ekipman mevcut değil',
          noLocations: 'Konumlar mevcut değil',
          energyCost: 'Enerji Maliyeti',
          audioRecording: 'Ses Kaydı',
          leakSourceEquipment: 'Kaçak Kaynak Ekipmanı',
          selectComponentType: 'Bileşen türünü seç',
          selectService: 'Hizmeti seç',
          locationAndUser: 'Konum ve Kullanıcı',
          leakEventStatus: 'Kaçak Olay Durumu',
          low: 'Düşük',
          medium: 'Orta',
          high: 'Yüksek',
          open: 'Açık',
          complete: 'Tamamlandı',
          reset: 'Sıfırla',
        },
        images: {
          leakSourceImageCapture: 'Kaçak Kaynağı Görüntü Yakalama',
          add: 'Ekle',
        },
        index: {
          title: 'Kaçak Kaydı Oluştur',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'Deneyim',
          to: 'İleri',
          qualifier: 'Nitelik',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Kaçak Kaydını Ata',
          selectAUserToAssign: 'Seçilen kayıt(lar) için atanacak kullanıcıyı seçin',
          filterByName: 'İsme göre filtrele',
          filterByEmployeeName: 'Çalışan ismine göre filtrele',
          unexpectedError: 'Beklenmeyen bir hata oluştu',
          noUserFound: 'Kullanıcı bulunamadı',
          assigningActionForSelectedRecords: 'Seçilen kayıt(lar) için eylem atanıyor...',
        },
        tableHead: {
          youAreSelecting: 'Seçtiğiniz:',
          visibleRecords: 'görünür kayıtlar',
          andAllUnloadedRecords: 've tüm yüklenmemiş kayıtlar.',
          allRecordsWillBeLoaded: 'Tüm kayıtlar yüklenecek ve seçilecektir.',
          thisCanTakeAWhile: 'Eğer çok sayıda kaydınız varsa bu biraz zaman alabilir.',
        },
        tableBody: {
          noRecordsToDisplay: 'Görüntülenecek kayıt yok.',
        },
        toolBar: {
          assign: 'Ata',
          onEssentialPlan: 'Temel planındasınız. Lütfen support@prosaris.ca ile iletişime geçin.',
          exportPDF: 'PDF Dışa Aktar',
          needAdminLicense:
            'Bu özelliğe erişmek için bir yönetici lisansına ihtiyacınız var. Lütfen support@prosaris.ca ile iletişime geçin.',
          create: 'Oluştur',
          deleteRecordMessage: 'Seçilen kaydı silmek istediğinizden emin misiniz?',
          deleteRecordMessagePlural: 'Seçilen kayıtları silmek istediğinizden emin misiniz?',
          permissionDenied: 'İzin reddedildi!',
          notAllowedToDelete: 'Silinmesine izin verilmeyen birinin kaydını seçtiniz. Lütfen seçimden çıkarın.',
          generatingPDF: 'PDF oluşturuluyor...',
          cannotGeneratePDF:
            'Maalesef, şu anda {{numberSelected}} kaydı tek bir PDF belgesine dışa aktaramıyoruz. Lütfen en fazla {{limitRecords}} kaydı seçin.',
          cannotExportMessage: '{{numberSelected}} kaydı dışa aktarılamıyor.',
        },
        index: {
          cannotLoadLeakRecord: 'Üzgünüz, şu anda kaçak kaydını yükleyemiyoruz.',
          successfullyAssignedAllRecords: 'Tüm kayıtlar başarıyla atandı.',
          actionRecordAssigned: 'Eylem kaydı atandı!',
          actionRecordAssignedPlural: 'Eylem kayıtları atandı!',
          errorWhileAssigningLeak: 'Kaçak atarken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          successfullyDeletedAllRecords: 'Tüm kayıtlar başarıyla silindi.',
          successfullyDeletedRecord: '{{length}} kaçak kaydı başarıyla silindi.',
          successfullyDeletedRecordPlural: '{{length}} kaçak kayıtları başarıyla silindi.',
          errorOnRecordsDelete: 'Kayıtları silerken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          leakPreview: 'Kaçak Önizleme',
          leakRecord: 'Kaçak Kaydı:',
        },
      },
      tagList: {
        resetTag: 'Etiketi Sıfırla',
        dataExport: {
          smartLeakTagsFormat: 'Akıllı Kaçak Etiketleri formatı',
          smartLeakTagsDescription: 'Akıllı Kaçak Etiketleri sayfasında mevcut olan varsayılan alanları içerir.',
          standardLeakRecordFormat: 'Standart Kaçak Kaydı formatı',
          standardLeakRecordDescription: 'Kaçak kayıtlarıyla ilgili tüm alanları içerir.',
        },
        filters: { smartLeakTagFilters: 'Akıllı Kaçak Etiketi Filtreleri' },
        index: {
          cannotLoadTags: 'Üzgünüz, şu anda Akıllı Kaçak Etiketini yükleyemiyoruz.',
          updateLeakSuccess: 'Akıllı Kaçak Etiketi sıfırlama işlemi başarılı.',
          updateLeakError: 'Akıllı Kaçak Etiketi sıfırlarken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          bulkEditSuccess: '{{len}} Akıllı Kaçak Etiketi başarıyla sıfırlandı.',
          bulkEditSuccessPlural: '{{len}} Akıllı Kaçak Etiketi başarıyla sıfırlandı.',
          bulkEditError: 'Akıllı Kaçak Etiketlerini sıfırlarken hata oluştu. Lütfen daha sonra tekrar deneyin.',
          tagsFileName: 'Akıllı Kaçak Etiketleri',
          leakFileName: 'Kaçak Kayıtları',
        },
        tableBody: {
          title: 'Lütfen etiketi sıfırlama işlemini onaylayın.',
          alertDialog: {
            dialogOne: 'Şu anda şunu deniyorsunuz',
            dialogTwo: 'etiketi sıfırlamak.',
            dialogThree: 'bir kaçak kaydından',
            dialogFour: 'Eylem Durumu: AÇIK',
            dialogFive: 'Devam etmek istiyor musunuz?',
          },
        },
        toolBar: {
          resetTags: 'Etiketleri Sıfırla',
          title: 'Lütfen etiketlerin toplu olarak sıfırlanmasını onaylayın.',
          alertDialog: {
            dialogOne: 'Seçiminiz şunları içeriyor',
            dialogTwo: 'kayıt ile',
            dialogTwoPlural: 'kayıt ile',
            dialogThree: 'Eylem Durumu: AÇIK',
            dialogFour: 'Devam etmek istiyor musunuz?',
          },
          exportFormatCSV: 'CSV için bir dışa aktarma formatı seçin',
          exportFormatPDF: 'PDF için bir dışa aktarma formatı seçin',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Atanan eylemleri yüklerken hata oluştu. Lütfen tekrar deneyin.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Mevcut grafikler',
            selectAChart: 'Bir grafik seçin...',
            chartFilters: 'Grafik Filtreleri',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Kaçak kayıtlarını yüklerken hata oluştu. Lütfen tekrar deneyin.',
              errorMessageTwo: 'Lütfen bitiş tarihinden daha önce bir tarih seçin.',
              errorMessageThree: 'Lütfen 6 aydan daha kısa bir tarih aralığı seçin.',
              errorMessageFour: 'Lütfen başlangıç tarihinden daha sonra bir tarih seçin.',
            },
            charts: 'Grafikler',
            chartAlreadyExists: 'Bu grafik zaten mevcut!',
          },
          filters: {
            select: 'Seçin...',
            startDate: 'Başlangıç Tarihi',
            endDate: 'Bitiş Tarihi',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Kaçak kategorisine göre akış (${unit})`,
              LOSS_BY_LEAK: (currency) => `Kaçak kategorisine göre kayıp (${currency})`,
              TAGS_BY_LEAK: 'Kaçak kategorisine göre etiketler',
              AVG_FLOW_BY_LEAK: (unit) => `Kaçak kategorisine göre ortalama akış (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Onarım süresine göre kayıp ve tasarruf fırsatı (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Onarım süresine göre akış (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Onarım süresine göre kayıp (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Akış ilerlemesi – Onarılan vs. Onarılmayan (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Kayıp ilerlemesi – Onarılan vs. Onarılmayan (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Ekipman/bileşene göre akış (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Ekipman/bileşene göre kayıp (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Ekipman/bileşenlere göre etiketler',
            },
            datasetLabel: {
              totalLeakFlow: 'Bulunan Toplam Kaçak Akışı',
              totalLoss: 'Toplam Kayıp',
              totalRecords: 'Bulunan Toplam Kayıt',
              avgLeakFlow: 'Bulunan Ortalama Kaçak Akışı',
              totalLossIn: 'Toplam Kayıp',
              flowByRepairTime: 'Onarım Süresine Göre Akış',
              lossByRepairTime: 'Onarım Süresine Göre Kayıp',
              flowByRepairProgress: 'Onarım İlerlemesine Göre Bulunan Akış',
              recordsByCompletion: 'Tamamlanmaya Göre Kayıtlar',
              flowByEquipment: 'Ekipmana Göre Bulunan Akış',
              totalLossByEquipment: 'Ekipmana Göre Toplam Kayıp',
              totalRecordsByEquipment: 'Ekipmana Göre Toplam Kayıt',
            },
            labels: {
              low: 'Düşük',
              medium: 'Orta',
              high: 'Yüksek',
              equipment: 'Ekipman',
              savings: 'Tasarruf',
              opportunity: 'Fırsat',
              loss: 'Kayıp',
              repaired: 'Onarıldı',
              unrepaired: 'Onarılmadı',
              LESS_15_DAYS: '15 günden az',
              DAYS_16_TO_30: '16 ila 30 gün',
              DAYS_31_TO_60: '31 ila 60 gün',
              DAYS_61_TO_90: '60 günden fazla',
            },
          },
        },
        index: {
          assignedActions: 'Size Atanan Eylemler',
        },
      },
      export: {
        imageUrlOne: 'Kaçak Resim URL 1',
        imageUrlTwo: 'Kaçak Resim URL 2',
        leakTag: 'Akıllı Kaçak Etiketi/İş Referansı',
        per: 'başına ',
      },
    },
    languages: {
      English: 'İngilizce',
      Chinese: 'Çince',
      Spanish: 'İspanyolca',
      French: 'Fransızca',
      German: 'Almanca',
      Norwegian: 'Norveççe',
      Italian: 'İtalyanca',
      Turkish: 'Türkçe',
    },
  },
};
