export default {
  DASHBOARD: {
    TIMEFRAME: 'dashboard__timeframe',
    DATASETS: 'dashboard__datasets',
    CHART_FILTERS: 'dashboard__filters',
    CHARTS: 'dashboard__charts',
  },
  AUTH_USER: 'auth-user',
  AUTH_FEATURES: 'auth-features',
  TOKEN: {
    ACCESS: 'access-token',
    ACCESS_EXPIRES_AT: 'access-token-expiration',
    REFRESH: 'refresh-token',
    REFRESH_EXPIRES_AT: 'refresh-token-expiration',
  },
  REGISTER: {
    SALES_TYPE: 'sales-type',
    SERIAL_NUMBER: 'serial-number',
    FIRSTNAME: 'first-name',
    LASTNAME: 'last-name',
    EMAIL: 'email',
    PASSWORD: 'password',
  },
  COMPANIES: {
    SELECTED: 'companies__selected',
  },
  LEAK_RECORDS: {
    FILTERS: 'leak-records__filters',
    HIGHLIGHTED: 'leak-records__highlighted',
    TABLE_SIZE: 'leak-records__table-size',
    COLUMNS: 'leak-records__columns',
    SORT_COLUMN: 'leak-records__sort-column',
  },
  ASSIGNMENTS: {
    FILTERS: 'assignments__filters',
    TABLE_SIZE: 'assignments__table-size',
    COLUMNS: 'assignments__columns',
  },
  LANGUAGE: 'language',
  TAGS: {
    FILTERS: 'tags__filters',
    TABLE_SIZE: 'tags__table-size',
    COLUMNS: 'tags__columns',
  },
};
