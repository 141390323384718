import { useEffect, useMemo, useRef } from 'react';

export default ({ accExpiresAt, refExpiresAt, onExpire, onRefresh }) => {
  const refTimeout = useRef(null);
  const accTimeout = useRef(null);

  const accToken = useMemo(() => {
    if (accExpiresAt === null) return null;

    return [Date.now() >= accExpiresAt, accExpiresAt - Date.now()];
  }, [accExpiresAt]);

  const refToken = useMemo(() => {
    if (refExpiresAt === null) return null;

    return [Date.now() >= refExpiresAt, refExpiresAt - Date.now()];
  }, [refExpiresAt]);

  // setup a timer for refresh callback
  useEffect(() => {
    if (!accToken) return;

    // clean up old timer
    if (accTimeout.current) clearTimeout(accTimeout.current);

    const [expired, timeLeft] = accToken;
    if (expired) {
      // asap refresh acc token
      // onRefresh()
      return;
    }

    // Int32 limit
    const maxDelay = Math.pow(2, 31) - 1;

    // security check to prevent setTimeout() execute immediately
    // (https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value)
    accTimeout.current = setTimeout(onRefresh, timeLeft > maxDelay ? maxDelay : timeLeft);
  }, [accToken, onRefresh]);

  // setup a timer for expiration callback
  useEffect(() => {
    if (!refToken) return;

    // clean up old timer
    if (refTimeout.current) clearTimeout(refTimeout.current);

    const [expired, timeLeft] = refToken;
    if (expired) {
      // trigger callback on ref token expiration
      onExpire();
      return;
    }

    refTimeout.current = setTimeout(onExpire, timeLeft);
  }, [refToken, onExpire]);

  // clean up all timeouts on component unmount
  useEffect(() => {
    return () => {
      if (accTimeout.current) clearTimeout(accTimeout.current);

      if (refTimeout.current) clearTimeout(refTimeout.current);
    };
  }, []);
};
