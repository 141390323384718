import api from '../helpers';

const mapper = ({ data }) => {
  return data.reduce((acc, { id, documentId, webPath }) => {
    if (acc[documentId]) {
      return {
        ...acc,
        [documentId]: [...acc[documentId], { id, webPath }],
      };
    }

    return { ...acc, [documentId]: [{ id, webPath }] };
  }, {});
};

export default {
  listByDocumentId: {
    key: 'fetchAudioFilesByDocumentId',
    query: (documentId) => {
      return api.get(`/api/audio-files/${documentId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          by: 'documentId',
        },
      });
    },
    mapper,
  },
  listByAssignmentId: {
    key: 'fetchAudioFilesByAssignmentId',
    query: (assignmentId) => {
      return api.get(`/api/audio-files/${assignmentId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          by: 'assignmentId',
        },
      });
    },
    mapper,
  },
};
