import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { t } from 'i18next';

import useAuth from 'src/hooks/useAuth';

export const has = (access, permission, user = null) => {
  if (!access || !access.permissions) return false;

  let [toCheck, addition] = [permission, true];
  if (user && Array.isArray(permission)) {
    if (permission.length !== 3) {
      throw new Error(t('components.permissionGuard.invalidPermission'));
    }
    const [permissionToCheck, fieldPath, fieldValue] = permission;
    addition = _.get(user, fieldPath, null) === fieldValue;
    toCheck = permissionToCheck;
  }

  return access.permissions.includes(toCheck) && addition;
};

export const miss = (access, permission, user = null) => {
  if (!access || !access.permissions) return false;
  return !has(access, permission, user);
};

export const hasAny = (access, permissions, user = null) => {
  if (!access || !access.permissions) return false;
  return permissions.some((permission) => has(access, permission, user));
};

export const missAny = (access, permissions, user = null) => {
  if (!access || !access.permissions) return false;
  return permissions.some((permission) => miss(access, permission, user));
};

export const hasAll = (access, permissions, user = null) => {
  if (!access || !access.permissions) return false;
  return permissions.every((permission) => has(access, permission, user));
};

export const missAll = (access, permissions, user = null) => {
  if (!access || !access.permissions) return false;
  return permissions.every((permission) => miss(access, permission, user));
};

function PermissionGuard({ children, redirect, redirectTo, ...condition }) {
  const { user, access } = useAuth();

  const exit = useMemo(() => {
    if (!redirect) return null;

    return <Redirect to={{ pathname: redirectTo }} />;
  }, [redirect]);

  if (condition.has && !has(access, condition.has, user)) {
    // user doesn't have required permission
    return exit;
  }

  if (condition.miss && !miss(access, condition.miss, user)) {
    // user doesn't have required permission
    return exit;
  }

  if (condition.hasAny && !hasAny(access, condition.hasAny, user)) {
    // user doesn't have any of required permission
    return exit;
  }

  if (condition.hasAll && !hasAll(access, condition.hasAll, user)) {
    // user doesn't have all of required permission
    return exit;
  }

  // all conditions passed, render children freely
  return children;
}

PermissionGuard.defaultProps = {
  redirect: true,
  redirectTo: '/404',
};

PermissionGuard.propTypes = {
  redirect: PropTypes.bool,
  redirectTo: PropTypes.string,
  has: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  hasAny: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
  hasAll: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
  miss: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  missAny: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
  missAll: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
};

export default PermissionGuard;
