import { useMutation } from 'react-query';
import queries from '../queries';

const useNameplateCreate = (callbacks = {}) => {
  return useMutation(
    ({ compressorId, filename, file }) => {
      return queries.nameplates.create.query({ compressorId, filename, file });
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useNameplateDelete = (callbacks = {}) => {
  return useMutation(
    ({ id }) => {
      return queries.nameplates.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useNameplateCreate,
  useNameplateDelete,
};
