import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';

import useAuth from 'src/hooks/useAuth';

function QueryProvider({ children }) {
  const { handleLogout } = useAuth();

  const queryClient = new QueryClient();

  queryClient.setDefaultOptions({
    queries: {
      retry: false,
      staleTime: 2 * 60 * 1000, // query cache time (2 minutes)
      refetchOnWindowFocus: false, // refetch queries on window focus change
      refetchOnMount: 'always', // refetch query on component mount
      onError: (err) => {
        // test for AuthError codes (A01, A02, etc.) or 401 status code or any kind of unauthorized message
        if (/^A/.test(err.code) || /[U|u]nauthorized/.test(err.message) || err.code === 401 || err.response?.status === 401) {
          // logout user and redirect to login
          handleLogout();
        }
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

QueryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default QueryProvider;
