import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FEATURES } from 'src/constants/features';

import useAuth from '../hooks/useAuth';

export function NotAuthGuard({ authRedirectPath = '/app/my-dashboard', children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: authRedirectPath,
        }}
      />
    );
  }

  return children;
}

NotAuthGuard.propTypes = {
  authRedirectPath: PropTypes.string,
  children: PropTypes.node,
};

export function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export function FeatureGuard({ feature, children }) {
  const { isFeatureActive } = useAuth();
  const [status, setStatus] = useState({ active: false, loaded: false });

  useEffect(async () => {
    const featureInfo = await isFeatureActive(feature);
    setStatus({ status: featureInfo.isViewable, loaded: true });
  }, []);

  if (status.loaded && !status.status) {
    return (
      <Redirect
        to={{
          pathname: '/404',
        }}
      />
    );
  }

  return children;
}

FeatureGuard.propTypes = {
  feature: PropTypes.oneOf(FEATURES),
  children: PropTypes.node,
};
