import { useMutation, useQueryClient } from 'react-query';

import queries from '../queries';

const useAssignmentCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ leakIds, filters, userId }) => {
      if (leakIds) {
        return queries.assignments.createById.query(leakIds, userId);
      }
      return queries.assignments.createByFilter.query(filters, userId);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.leaks.list.key);
        queryClient.invalidateQueries(queries.assignments.list.key);
        queryClient.invalidateQueries(queries.assignments.myAssignments.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useAssignmentUpdate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id: assignmentId, ...values }) => {
      return queries.assignments.update.query(assignmentId, values);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (variables.assignmentRecord?.id) {
          // invalidate assignments selection
          const id = variables.assignmentRecord?.id;
          queryClient.invalidateQueries([queries.assignments.listByRecordId.key, id]);
        }
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useBulkDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ assignmentIds }) => {
      return queries.assignments.bulkDelete.query(assignmentIds, 'assignmentId');
    },
    {
      ...callbacks,
      onSuccess: async ({ data }, variables) => {
        await queryClient.invalidateQueries([queries.assignments.list.key]);
        if (variables.leakId) {
          // (optional) invalid assignments list query on a leak record details page
          await queryClient.invalidateQueries([queries.assignments.listByRecordId.key, variables.leakId]);
        }
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useAssignmentCreate,
  useAssignmentUpdate,
  useBulkDelete,
};
