import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const registerDevice = async (payload) => {
  return await queries.devices.register.query(payload);
};

const useRegisterDevices = (callbacks = {}) => {
  return useMutation(
    ({ deviceIds }) => {
      return queries.devices.registerDevices.query(deviceIds);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useDeviceEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) => {
      return queries.devices.edit.query(id, data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.devices.getDevices.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  registerDevice,
  useRegisterDevices,
  useDeviceEdit,
};
