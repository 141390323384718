import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function Page({ children, title = '' }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {children}
    </>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
