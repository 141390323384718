import api from '../helpers';

export default {
  create: {
    key: 'createFloorplan',
    query: ({ routeId, filename, file }) => {
      return api.post('/api/floorplans', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          routeId,
          filename,
          file,
        },
      });
    },
  },
  get: {
    key: 'fetchFloorplan',
    query: (routeId) => {
      return api.get(`/api/floorplans/${routeId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  delete: {
    key: 'deleteFloorplan',
    query: (id) => {
      return api.delete(`/api/floorplans/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
