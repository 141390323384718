import { useMutation, useQueryClient } from 'react-query';

import queries from '../queries';

const useImageDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => {
      return queries.images.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (variables.leakDocumentId) {
          // invalidates images loaded by leak record id
          queryClient.invalidateQueries([queries.images.listByDocumentId.key, variables.leakDocumentId]);
        }

        if (variables.assignmentId) {
          // invalidates images loaded by assignment id
          queryClient.invalidateQueries([queries.images.listByAssignmentId.key, variables.assignmentId]);
        }

        queryClient.invalidateQueries([queries.leaks.history.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useImageCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ documentId, filename, image }) => {
      return queries.images.create.query(documentId, filename, image);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (variables.leakDocumentId) {
          // invalidates images loaded by leak record id
          queryClient.invalidateQueries([queries.images.listByDocumentId.key, variables.leakDocumentId]);
        }

        if (variables.assignmentId) {
          // invalidates images loaded by assignment id
          queryClient.invalidateQueries([queries.images.listByAssignmentId.key, variables.assignmentId]);
        }

        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useLogoCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyId, filename, image }) => {
      return queries.images.createLogo.query(companyId, filename, image);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
        queryClient.invalidateQueries([queries.images.getLogo.key]);
      },
    }
  );
};

const useLogoDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyId }) => {
      return queries.images.deleteLogo.query(companyId);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
        queryClient.invalidateQueries([queries.images.getLogo.key]);
      },
    }
  );
};

const useCompressorFileCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ compressorId, filename, file, fileComponent }) => {
      return queries.images.createCompressorFile.query({ compressorId, filename, file, fileComponent });
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
        queryClient.invalidateQueries([queries.images.createCompressorFile.key]);
      },
    }
  );
};

const useCompressorFileDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ fileId }) => {
      return queries.images.deleteCompressorFile.query(fileId);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
        queryClient.invalidateQueries([queries.images.deleteCompressorFile.key]);
      },
    }
  );
};

export default {
  useImageDelete,
  useImageCreate,
  useLogoCreate,
  useLogoDelete,
  useCompressorFileCreate,
  useCompressorFileDelete,
};
