import api from '../helpers';

export default {
  get: {
    key: 'fetchEnpis',
    query: (companyId) => {
      return api.get(`/api/enpis/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  create: {
    key: 'addEnpis',
    query: (data) => {
      return api.post('/api/enpis', {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  edit: {
    key: 'editEnpis',
    query: (id, data) => {
      return api.put(`/api/enpis/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
};
