import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import releaseNotes from 'src/assets/release-notes.json';

const ReleaseNotesContext = createContext({
  releases: [],
  latest: null,
});

export function ReleaseNotesProvider({ children }) {
  const source = releaseNotes || {};
  const releases = Object.entries(source).reduce((acc, [version, meta]) => {
    return [...acc, { version, ...meta }];
  }, []);

  const contextValue = useMemo(() => ({ releases, latest: releases[0] }));

  return <ReleaseNotesContext.Provider value={contextValue}>{children}</ReleaseNotesContext.Provider>;
}

ReleaseNotesProvider.propTypes = {
  children: PropTypes.node,
};

export default ReleaseNotesContext;
