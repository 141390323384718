import { useMutation } from 'react-query';
import queries from '../queries';

const useUtilProviderCreate = (callbacks = {}) => {
  return useMutation(
    ({ energyId, filename, file }) => {
      return queries.utilproviders.create.query({ energyId, filename, file });
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useUtilProviderDelete = (callbacks = {}) => {
  return useMutation(
    ({ id }) => {
      return queries.utilproviders.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useUtilProviderCreate,
  useUtilProviderDelete,
};
