export default {
  h1: {
    fontWeight: 400,
    fontSize: 22,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 700,
  },
  button: {
    textTransform: 'none',
  },
  body2: {
    fontSize: 12,
  },
  subtitle1: {
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
  subtitle2: {
    lineHeight: '16px',
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 'normal',
  },
  fontFamily: [
    'Nunito',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};
