export const PERFORMANCE_INDICATORS = {
  NO_OF_LEAKS_FOUND: 'NO_OF_LEAKS_FOUND',
  NO_OF_LEAKS_REPAIRED: 'NO_OF_LEAKS_REPAIRED',
  PERCENT_LEAKS_REPAIRED: 'PERCENT_LEAKS_REPAIRED',
  VOL_FLOW_FOUND: 'VOL_FLOW_FOUND',
  VOL_FLOW_REPAIRED: 'VOL_FLOW_REPAIRED',
  PERCENT_VOL_FLOW_REPAIRED: 'PERCENT_VOL_FLOW_REPAIRED',
  VALUE_FOUND: 'VALUE_FOUND',
  VALUE_REPAIRED: 'VALUE_REPAIRED',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'ENERGY_SAVING_OPPORTUNITY_FOUND',
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 'ENERGY_SAVING_OPPORTUNITY_PERCENT',
  ENERGY_SAVING_REALIZED_FIXED: 'ENERGY_SAVING_REALIZED_FIXED',
  ENERGY_SAVING_REALIZED_PERCENT: 'ENERGY_SAVING_REALIZED_PERCENT',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'SYSTEM_CONTROL_PRESSURE_REDUCED',
};

export const PERFORMANCE_INDICATOR_FIELD_PRECISION = {
  NO_OF_LEAKS_FOUND: 0,
  NO_OF_LEAKS_REPAIRED: 0,
  PERCENT_LEAKS_REPAIRED: 2,
  VOL_FLOW_FOUND: 2,
  VOL_FLOW_REPAIRED: 2,
  PERCENT_VOL_FLOW_REPAIRED: 2,
  VALUE_FOUND: 2,
  VALUE_REPAIRED: 2,
  ENERGY_SAVING_OPPORTUNITY_FOUND: 2,
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 2,
  ENERGY_SAVING_REALIZED_FIXED: 2,
  ENERGY_SAVING_REALIZED_PERCENT: 2,
  SYSTEM_CONTROL_PRESSURE_REDUCED: 2,
};

export const PERFORMANCE_INDICATOR_LABELS = {
  NO_OF_LEAKS_FOUND: '# Leaks Found',
  NO_OF_LEAKS_REPAIRED: '# Leaks Repaired',
  PERCENT_LEAKS_REPAIRED: '% Leaks Repaired',
  VOL_FLOW_FOUND: 'Vol. Flow Found',
  VOL_FLOW_REPAIRED: 'Vol. Flow Repaired',
  PERCENT_VOL_FLOW_REPAIRED: '% Vol. Flow Repaired',
  VALUE_FOUND: 'Value Found',
  VALUE_REPAIRED: 'Value Repaired',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energy saving opportunity (Found)',
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Energy saving opportunity % (Found v calculated or measured)',
  ENERGY_SAVING_REALIZED_FIXED: 'Energy saving realized (Fixed)',
  ENERGY_SAVING_REALIZED_PERCENT: 'Energy saving realized % (Fixed v calculated or measured)',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'System control pressure reduced',
};

export const ENPIS_FIELD_PATHS = {
  NO_OF_LEAKS_FOUND: 'enpiValues.noOfLeaksFound',
  NO_OF_LEAKS_REPAIRED: 'enpiValues.noOfLeaksRepaired',
  PERCENT_LEAKS_REPAIRED: 'enpiValues.percentLeaksRepaired',
  VOL_FLOW_FOUND: 'enpiValues.volFlowFound',
  VOL_FLOW_REPAIRED: 'enpiValues.volFlowRepaired',
  PERCENT_VOL_FLOW_REPAIRED: 'enpiValues.percentVolFlowRepaired',
  VALUE_FOUND: 'enpiValues.valueFound',
  VALUE_REPAIRED: 'enpiValues.valueRepaired',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'enpiValues.energySavingOpportunityFound',
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 'enpiValues.energySavingOpportunityPercent',
  ENERGY_SAVING_REALIZED_FIXED: 'enpiValues.energySavingRealizedFixed',
  ENERGY_SAVING_REALIZED_PERCENT: 'enpiValues.energySavingRealizedPercent',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'enpiValues.systemControlPressureReduced',
};

export const ENBS_FIELD_PATHS = {
  NO_OF_LEAKS_FOUND: 'enbValues.noOfLeaksFound',
  NO_OF_LEAKS_REPAIRED: 'enbValues.noOfLeaksRepaired',
  PERCENT_LEAKS_REPAIRED: 'enbValues.percentLeaksRepaired',
  VOL_FLOW_FOUND: 'enbValues.volFlowFound',
  VOL_FLOW_REPAIRED: 'enbValues.volFlowRepaired',
  PERCENT_VOL_FLOW_REPAIRED: 'enbValues.percentVolFlowRepaired',
  VALUE_FOUND: 'enbValues.valueFound',
  VALUE_REPAIRED: 'enbValues.valueRepaired',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'enbValues.energySavingOpportunityFound',
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 'enbValues.energySavingOpportunityPercent',
  ENERGY_SAVING_REALIZED_FIXED: 'enbValues.energySavingRealizedFixed',
  ENERGY_SAVING_REALIZED_PERCENT: 'enbValues.energySavingRealizedPercent',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'enbValues.systemControlPressureReduced',
};

export const SUCCESS_THRESHOLDS_FIELD_PATHS = {
  NO_OF_LEAKS_FOUND: 'successThresholds.noOfLeaksFound',
  NO_OF_LEAKS_REPAIRED: 'successThresholds.noOfLeaksRepaired',
  PERCENT_LEAKS_REPAIRED: 'successThresholds.percentLeaksRepaired',
  VOL_FLOW_FOUND: 'successThresholds.volFlowFound',
  VOL_FLOW_REPAIRED: 'successThresholds.volFlowRepaired',
  PERCENT_VOL_FLOW_REPAIRED: 'successThresholds.percentVolFlowRepaired',
  VALUE_FOUND: 'successThresholds.valueFound',
  VALUE_REPAIRED: 'successThresholds.valueRepaired',
  ENERGY_SAVING_OPPORTUNITY_FOUND: 'successThresholds.energySavingOpportunityFound',
  ENERGY_SAVING_OPPORTUNITY_PERCENT: 'successThresholds.energySavingOpportunityPercent',
  ENERGY_SAVING_REALIZED_FIXED: 'successThresholds.energySavingRealizedFixed',
  ENERGY_SAVING_REALIZED_PERCENT: 'successThresholds.energySavingRealizedPercent',
  SYSTEM_CONTROL_PRESSURE_REDUCED: 'successThresholds.systemControlPressureReduced',
};

export const COLUMNS = {
  PERFORMANCE_INDICATOR: 'Performance Indicator',
  ENPI_VALUE: 'EnPI Value',
  ENB_VALUE: 'EnB Value',
  SUCCESS_THRESHOLD: 'Success Threshold (%)',
};
