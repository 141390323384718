import api from '../helpers';

export default {
  companiesHistory: {
    key: 'companyHistory',
    query: (companyId) => {
      return api.get(`/api/history/companies/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
