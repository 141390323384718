import api from '../helpers';

export default {
  get: {
    key: 'fetchCompressors',
    query: (companyId) => {
      return api.get(`/api/compressors/${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => model);
    },
  },
  create: {
    key: 'addCompressor',
    query: (data) => {
      return api.post('/api/compressor', {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  edit: {
    key: 'editCompressor',
    query: (id, data) => {
      return api.put(`/api/compressor/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  delete: {
    key: 'deleteCompressor',
    query: (id) => {
      return api.delete(`/api/compressor/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
