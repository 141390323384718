import { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ValidationContext = createContext({
  errorFields: [],
  isErrField: () => {},
  setErrorFields: () => {},
  clearError: () => {},
});

export function ValidationProvider({ children }) {
  const [errorFields, setErrorFields] = useState([]);

  const isErrField = useCallback(
    (field) => {
      const fieldPath = field.indexOf('.') === 0 ? field : `.${field}`;
      return errorFields.includes(fieldPath);
    },
    [errorFields]
  );

  const clearError = (fields = null) => {
    if (fields === null) {
      setErrorFields([]);
      return;
    }

    const fieldsPath = fields.map((field) => (field.indexOf('.') === 0 ? field : `.${field}`));
    setErrorFields(errorFields.filter((errorField) => !fieldsPath.includes(errorField)));
  };

  const contextValue = useMemo(() => ({
    errorFields,
    isErrField,
    setErrorFields,
    clearError,
  }));

  return <ValidationContext.Provider value={contextValue}>{children}</ValidationContext.Provider>;
}

ValidationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ValidationContext;
