import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const useUpdateAudit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, comment }) => {
      return queries.audits.updateComment.query(id, comment);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.audits.get.key);
        queryClient.invalidateQueries(queries.audits.list.key);
        queryClient.invalidateQueries(queries.leaks.history.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useCreateAudit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, comment }) => {
      return queries.audits.create.query(id, comment);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.audits.get.key);
        queryClient.invalidateQueries(queries.audits.list.key);
        queryClient.invalidateQueries(queries.leaks.history.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useDeleteAudit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => {
      return queries.audits.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.audits.get.key);
        queryClient.invalidateQueries(queries.audits.list.key);
        queryClient.invalidateQueries(queries.leaks.history.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useUpdateAudit,
  useCreateAudit,
  useDeleteAudit,
};
