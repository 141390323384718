import { createTheme, colors } from '@material-ui/core';

import shadows from './shadows';
import typography from './typography';

export const PRIMARY_COLOR = '#5e7ad2';
export const SECONDARY_COLOR = '#53565a';
export const PRIMARY_TEXT = '#53565A';
export const DARK_BACKGROUND = '#333740';
export const SUCCESS = '#26b562';

const theme = createTheme({
  palette: {
    background: {
      default: '#F3F6F8',
      paper: colors.common.white,
      dark: DARK_BACKGROUND,
    },
    primary: {
      highlight: '#EFF3FF',
      main: PRIMARY_COLOR,
      dark: '#4B68C1',
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: PRIMARY_TEXT,
      secondary: '#7E7E7E',
    },
    success: {
      main: SUCCESS,
    },
    common: {
      white: colors.common.white,
      black: colors.common.black,
    },
    disabled: {
      warning: '#ffbdad',
    }
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 20,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        minWidth: '170px',
        height: '40px',
      },
      sizeSmall: {
        minWidth: '100px',
        height: '28px',
      },
      contained: {
        backgroundColor: DARK_BACKGROUND,
        color: colors.common.white,
        '&:hover': {
          backgroundColor: colors.grey[600],
        },
      },
      outlined: {
        backgroundColor: colors.common.white,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2px',
        backgroundColor: colors.common.white,
      },
    },
    MuiTypography: {
      h2: {
        marginBottom: '1.5rem',
      },
    },
    MuiTableContainer: {
      root: {
        backgroundColor: colors.common.white,
        // copied from Paper
        boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: PRIMARY_COLOR,
      },
    },
    MuiTableCell: {
      root: {
        flexShrink: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      head: {
        backgroundColor: PRIMARY_COLOR,
        color: colors.common.white,
        fontSize: 12,
        lineHeight: '1rem',
      },
      stickyHeader: {
        backgroundColor: PRIMARY_COLOR,
        color: colors.common.white,
        fontSize: 12,
        '&:hover': {
          color: colors.common.white,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: colors.common.white,
        },
        '&:focus': {
          color: colors.common.white,
        },
        '&$active': {
          color: colors.common.white,
        },
      },
      icon: {
        color: `${colors.common.white} !important`,
      },
      active: {},
    },
    MuiInputLabel: {
      shrink: {
        backgroundColor: colors.common.white,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: DARK_BACKGROUND,
        color: colors.common.white,
        '&:active': {
          backgroundColor: DARK_BACKGROUND,
        },
      },
      deletable: {
        '&:focus': {
          backgroundColor: DARK_BACKGROUND,
        },
      },
      deleteIcon: {
        color: colors.common.white,
        '&:hover': {
          color: colors.grey[500],
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 15,
      },
    },
  },
  shadows,
  typography,
});

export default theme;
