import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'beautiful-react-hooks';
import api from 'src/api';
import STORAGE from 'src/constants/localStorage';

const LanguageContext = createContext({
  languageKey: i18n.language,
  setLanguageKey: () => null,
  featureActive: false,
});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children }) => {
  const [languageKey, setLanguageKey] = useLocalStorage(STORAGE.LANGUAGE, i18n.language);
  const [featureActive, setFeatureActive] = useState(false);

  useEffect(() => {
    const changeLanguage = () => {
      setLanguageKey(i18n.language);
    };
    i18n.on('languageChanged', changeLanguage);

    return () => {
      i18n.off('languageChanged', changeLanguage);
    };
  }, [setLanguageKey, i18n]);

  useEffect(() => {
    i18n.changeLanguage(languageKey);
  }, [languageKey]);

  useEffect(async () => {
    const checkFeature = async () => {
      try {
        const translationFeature = await api.features.isTranslationFeatureActive();
        setFeatureActive(translationFeature.isViewable);
      } catch (err) {
        console.error('Faild to check feature status', err);
      }
    };
    checkFeature();
  }, []);

  const contextValue = useMemo(
    () => ({
      languageKey,
      setLanguageKey,
      featureActive,
      setFeatureActive,
    }),
    [languageKey, setLanguageKey, featureActive, setFeatureActive]
  );

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
