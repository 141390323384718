import { dollarsToCents, centsToDollars } from 'src/utils/convertUnits';

import api from '../helpers';
import { DEFAULT_CURRENCY } from 'src/constants/countryCurrency';

const DEFAULT_ASSIGNED_ACTION_MODEL = {
  notes: '',
  closeOutRef: '',
  repairCost: 0,
  partsNeeded: false,
  productionOutage: false,
  requestReassign: false,
  actionStatus: false,
  timeToRepair: 0,
};

export default {
  listByRecordId: {
    key: 'fetchLeakRecordAssignments',
    query: (id) => {
      return api.get('/api/assignments', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          leakId: id,
          format: 'withRelations',
          offset: 0,
          limit: 999,
          sort: ['createdAt', 'asc'],
        },
      });
    },
    mapper: ({ data }) => {
      return data.map((action) => {
        const complete = { ...DEFAULT_ASSIGNED_ACTION_MODEL, ...action };
        return {
          ...complete,
          id: action.id,
          owner: action.assignedTo.email,
          repairCost: centsToDollars(complete.repairCost),
        };
      });
    },
  },
  list: {
    key: 'fetchAssignments',
    query: (offset, limit, sortBy, sortDir, filters = {}) => {
      return api.get('/api/assignments', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          format: 'withRelations',
          offset,
          limit,
          sort: [sortBy, sortDir],
          ...filters,
        },
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => {
        return {
          ...model,
          owner: model.assignedTo.email,
          assignedBy: model.assignedBy.email,
          assignedTo: model.assignedTo.email,
          currency: model.assignmentRecord.currency || DEFAULT_CURRENCY,
        };
      });
    },
  },
  assignmentIds: {
    key: 'fetchAssignmentIds',
    query: (filters = {}) => {
      return api.get('/api/assignments', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          format: 'onlyId',
          offset: 0,
          limit: -1,
          ...filters,
        },
      });
    },
    mapper: ({ data }) => data,
  },
  myAssignments: {
    key: 'fetchMyAssignments',
    query: (offset, limit, sortBy, sortDir) => {
      const filter = { assignmentActive: true };

      return api.get('/api/assignments', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          ...filter,
          format: 'withRelations',
          offset,
          limit,
          sort: [sortBy, sortDir],
        },
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => {
        const record = model.assignmentRecord;
        return {
          ...record,
          assignedAt: model.assignedDate,
          assignedBy: model.assignedBy,
        };
      });
    },
  },
  createById: {
    key: 'createAssignment',
    query: (leakIds, userId) => {
      if (!Array.isArray(leakIds) || leakIds.length === 0) {
        // input parameters are empty
        return null;
      }

      return api.post('/api/assignments/bulk-create', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          userId,
          leakIds,
          leakUrl: leakIds.map((id) => `/app/leak-record/${id}/details`),
        },
      });
    },
  },
  createByFilter: {
    key: 'createAssignment',
    query: (filters, userId) => {
      if (!filters || typeof filters !== 'object' || Object.keys(filters).length === 0) {
        // input parameters are empty
        return null;
      }

      return api.post('/api/assignments/bulk-create', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { userId, filters },
      });
    },
  },
  update: {
    key: 'updateAssignment',
    query: (id, values) => {
      return api.put(`/api/assignments/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          notes: String(values.notes),
          closeOutRef: String(values.closeOutRef),
          repairCost: dollarsToCents(values.repairCost), // Repair cost should be saved as integer
          partsNeeded: Boolean(values.partsNeeded),
          productionOutage: Boolean(values.productionOutage),
          requestReassign: Boolean(values.requestReassign),
          actionStatus: Boolean(values.actionStatus),
          timeToRepair: parseInt(values.timeToRepair, 10),
        },
      });
    },
  },
  bulkDelete: {
    key: 'bulkDelete',
    query: (ids, field) => {
      return api.post('/api/assignments/bulk-delete', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { ids },
        params: { by: field },
      });
    },
  },
};
