import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FEATURES } from 'src/constants/features';
import useAuth from 'src/hooks/useAuth';

function FeatureFlag({ feature, children }) {
  const { isFeatureActive } = useAuth();
  const [status, setStatus] = useState(false);

  useEffect(async () => {
    const featureInfo = await isFeatureActive(feature);
    setStatus(featureInfo?.isViewable);
  }, []);

  return status ? children : null;
}

FeatureFlag.propTypes = {
  feature: PropTypes.oneOf(FEATURES),
  children: PropTypes.node,
};

export default FeatureFlag;
