import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import useReleaseNotes from 'src/hooks/useReleaseNotes';
import useAuth from 'src/hooks/useAuth';
import { FEATURES_LIST } from 'src/constants/features';
import FeatureFlag from 'src/components/FeatureFlag';

const useStyles = makeStyles((theme) => {
  const wrapper = {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: 0,
    right: 0,
    textAlign: 'center',
  };
  return {
    wrapper,
    wrapperWithLink: {
      textAlign: 'left',
      marginLeft: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    },
    message: {
      fontSize: '0.7rem',
    },
    link: {
      textDecoration: 'underline',
    },
  };
});

function Version() {
  const { isAuthenticated } = useAuth();
  const { latest } = useReleaseNotes();

  const classes = useStyles();

  if (latest === null) {
    return null;
  }

  const wrapperClass = isAuthenticated ? 'wrapperWithLink' : 'wrapper';
  return (
    <Box className={classes[wrapperClass]}>
      {isAuthenticated && (
        <FeatureFlag feature={FEATURES_LIST.SITE_VERSION}>
          <Typography className={classes.message}>
            Powered by Prosaris&nbsp;|&nbsp;
            <Link to='/app/release-notes' className={classes.link}>
              {latest.version}
            </Link>
          </Typography>
        </FeatureFlag>
      )}
      {!isAuthenticated && (
        <Typography className={classes.message}>
          Powered by Prosaris&nbsp;|&nbsp;
          {latest.version}
        </Typography>
      )}
    </Box>
  );
}

Version.defaultProps = {};

Version.propTypes = {};

export default React.memo(Version);
