import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const useUtilProviderEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) => {
      return queries.energy.editUtilProvider.query(id, data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.energy.get.key);
        queryClient.invalidateQueries(queries.companies.list.key);
        queryClient.invalidateQueries(queries.compressors.get.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useFixedEfficiencyEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) => {
      return queries.energy.editFixedEfficiency.query(id, data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.energy.get.key);
        queryClient.invalidateQueries(queries.companies.list.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useEnergyCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return queries.energy.create.query(data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.energy.get.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useUtilProviderEdit,
  useFixedEfficiencyEdit,
  useEnergyCreate,
};
