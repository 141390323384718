import { useMutation, useQueryClient } from 'react-query';

import queries from '../queries';

const useChangeOwner = (callbacks = {}) => {
  return useMutation(({ oldUserId, newUserId }) => {
    return queries.leaks.transfer.query(oldUserId, newUserId);
  }, callbacks);
};

const useUpdateLeak = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id: leakId, ...model }) => {
      return queries.leaks.setLeak.query(leakId, 'id', model);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.leaks.getLeak.key, variables.id]);
        queryClient.invalidateQueries([queries.leaks.list.key]);
        queryClient.invalidateQueries([queries.leaks.listNonEmptyTags.key]);
        queryClient.invalidateQueries([queries.filters.leaks.key]);
        queryClient.invalidateQueries([queries.filters.leaksNonEmptyTags.key]);
        queryClient.invalidateQueries([queries.leaks.history.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useBulkDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ids, filters }) => {
      if (ids) {
        return queries.leaks.bulkDeleteById.query(ids);
      }
      return queries.leaks.bulkDeleteByFilters.query(filters);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.leaks.getLeak.key, variables.id]);
        queryClient.invalidateQueries([queries.leaks.list.key]);
        queryClient.invalidateQueries([queries.leaks.listNonEmptyTags.key]);
        queryClient.invalidateQueries([queries.filters.leaks.key]);
        queryClient.invalidateQueries([queries.filters.leaksNonEmptyTags.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useBulkEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ids, documentId, model }) => {
      if (ids) {
        return queries.leaks.bulkEditById.query(ids, model);
      }
      return queries.leaks.bulkEditByDocumentId.query(documentId, model);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.leaks.getLeak.key, variables.id]);
        queryClient.invalidateQueries([queries.leaks.list.key]);
        queryClient.invalidateQueries([queries.leaks.listNonEmptyTags.key]);
        queryClient.invalidateQueries([queries.filters.leaks.key]);
        queryClient.invalidateQueries([queries.filters.leaksNonEmptyTags.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useCreateLeak = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ currency, deviceId, deviceCreatedAt, documentId, creatorUserId, leakParameters, liveDataReading, leakSource, leakStatus }) => {
      return queries.leaks.create.query(
        currency,
        deviceId,
        deviceCreatedAt,
        documentId,
        creatorUserId,
        leakParameters,
        liveDataReading,
        leakSource,
        leakStatus
      );
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.leaks.getLeak.key, variables.id]);
        queryClient.invalidateQueries([queries.leaks.list.key]);
        queryClient.invalidateQueries([queries.leaks.listNonEmptyTags.key]);
        queryClient.invalidateQueries([queries.filters.leaks.key]);
        queryClient.invalidateQueries([queries.filters.leaksNonEmptyTags.key]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useChangeOwner,
  useUpdateLeak,
  useBulkDelete,
  useBulkEdit,
  useCreateLeak,
};
