import api from '../helpers';

export default {
  get: {
    key: 'fetchDatasheet',
    query: (compressorId) => {
      return api.get(`/api/datasheets/${compressorId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  create: {
    key: 'createDatasheet',
    query: ({ compressorId, filename, file }) => {
      return api.post('/api/datasheets', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          compressorId,
          filename,
          file,
        },
      });
    },
  },
  delete: {
    key: 'deleteDatasheet',
    query: (id) => {
      return api.delete(`/api/datasheets/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
