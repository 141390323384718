export default function generateSlug(name) {
  const slugify = (str) => {
    const slug = str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

    return slug;
  };

  const randomChar = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return chars[Math.floor(Math.random() * 62)];
  };

  const randomString = (length) => {
    let result = '';
    for (let i = 0; i < length; i += 1) {
      result += randomChar();
    }
    return result;
  };

  return `${slugify(name)}-${randomString(5)}`;
}
