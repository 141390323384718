import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography, makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#5e7ad2',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}));

function ErrorModal() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Page className={classes.root} title='404'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='md'>
          <Typography align='center' color='textPrimary' variant='h3'>
            {t('components.errorBoundary.oops')}
          </Typography>
          <Typography align='center' color='textPrimary' variant='subtitle2'>
            {t('components.errorBoundary.tryAgain')}
          </Typography>
          <Container maxWidth='sm'>
            <Box pt={2} display='flex' justifyContent='center'>
              <Button color='primary' variant='contained' onClick={() => window.location.reload()}>
                {t('components.errorBoundary.refresh')}
              </Button>
            </Box>
          </Container>
        </Container>
      </Box>
    </Page>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorModal />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
