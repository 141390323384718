import { createContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'beautiful-react-hooks';
import { jwtDecode } from 'jwt-decode';

import STORAGE from 'src/constants/localStorage';

import useTokenRefresh from 'src/hooks/useTokenRefresh';

import api from 'src/api';
import { DEFAULT_CURRENCY } from 'src/constants/countryCurrency';

const AuthContext = createContext({
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  handleLogin: () => Promise.resolve(),
  handleLogout: () => {},
  handlePasswordResetRequest: () => Promise.resolve(),
  handlePasswordReset: () => Promise.resolve(),
});

export function AuthProvider({ children }) {
  const [user, setUser] = useLocalStorage(STORAGE.AUTH_USER, null);
  const [accExpiresAt, setAccExpiresAt] = useLocalStorage(STORAGE.TOKEN.ACCESS_EXPIRES_AT, null);
  const [refExpiresAt, setRefExpiresAt] = useLocalStorage(STORAGE.TOKEN.REFRESH_EXPIRES_AT, null);
  const [, setHighlightedRow] = useLocalStorage(STORAGE.LEAK_RECORDS.HIGHLIGHTED, null);
  const [, setSortColumn] = useLocalStorage(STORAGE.LEAK_RECORDS.SORT_COLUMN, null);
  const history = useHistory();

  const access = useMemo(() => {
    const accessToken = localStorage.getItem(STORAGE.TOKEN.ACCESS);
    return typeof accessToken === 'string' && accessToken !== 'null' ? jwtDecode(accessToken) : null;
  }, [user]);

  const isAuthenticated = useMemo(() => {
    return typeof user !== 'undefined' && user !== null;
  }, [user]);

  function handleLogin(email, password, serial) {
    return api.auth
      .login(email, password, serial)
      .then(([authUser, { accessTokenExpiresAt, refreshTokenExpiresAt }]) => {
        // save token expiration data in local storage
        setAccExpiresAt(accessTokenExpiresAt);
        setRefExpiresAt(refreshTokenExpiresAt);

        const userInfo = {
          displayName: `${authUser.name.first} ${authUser.name.last}`,
          email: authUser.email,
          uid: authUser.id,
          companyId: authUser.company.id,
          photoURL: authUser.photoUrl || null,
          role: authUser.roles[0],
          companySlug: (authUser.company && authUser.company.slug) || null,
          companyCurrency: authUser.company?.currency || DEFAULT_CURRENCY,
        };

        setUser(userInfo);
        return userInfo;
      })
      .catch((err) => {
        setUser(undefined);
        throw err;
      });
  }

  function handleLogout() {
    api.auth.logout();
    // clean up token expiration
    setAccExpiresAt(null);
    setRefExpiresAt(null);
    setUser(null);
    setHighlightedRow(null);
    setSortColumn(null);
    localStorage.removeItem(STORAGE.COMPANIES.SELECTED);

    if (!history.location.pathname.endsWith('login')) {
      history.push('/login');
    }
  }

  function handleProfileUpdate(userData) {
    setUser({
      ...user,
      ...userData,
    });
  }

  function handlePasswordResetRequest(email) {
    return api.users.passwordResetRequest(email);
  }

  function handlePasswordReset(actionCode, newPassword) {
    return api.users.passwordResetCode(actionCode, newPassword);
  }

  function handleIntegration(key) {
    return api.auth.integration(key).then(([user, { accessTokenExpiresAt, refreshTokenExpiresAt }]) => {
      setUser({
        displayName: `${user.name.first} ${user.name.last}`,
        email: user.email,
        uid: user.id,
        companyId: user.company.id,
        photoURL: user.photoUrl || null,
        role: user.roles[0],
        companySlug: (user.company && user.company.slug) || null,
        companyCurrency: user.company?.currency || DEFAULT_CURRENCY,
      });
      setAccExpiresAt(accessTokenExpiresAt);
      setRefExpiresAt(refreshTokenExpiresAt);
    });
  }

  const handleRefresh = useCallback(() => {
    api.auth
      .loginWithRefreshToken()
      .then(({ accessTokenExpiresAt, refreshTokenExpiresAt }) => {
        // save token expiration data in local storage
        setAccExpiresAt(accessTokenExpiresAt);
        setRefExpiresAt(refreshTokenExpiresAt);
      })
      .catch(() => {
        // in case of any error during token refresh clean up everything and redirect user
        handleLogout();
      });
  }, [handleLogout, setAccExpiresAt, setRefExpiresAt]);

  useTokenRefresh({
    accExpiresAt,
    refExpiresAt,
    onRefresh: handleRefresh,
    onExpire: handleLogout,
  });

  const contextValue = useMemo(() => ({
    isAuthenticated,
    user,
    access,
    isFeatureActive: async (feature) => {
      return await api.features.getFeatureViewable(feature).then((data) => data);
    },
    handleLogin,
    handleLogout,
    handleProfileUpdate,
    handlePasswordResetRequest,
    handlePasswordReset,
    handleIntegration,
  }));

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthContext;
