import { useMutation, useQueryClient } from 'react-query';

import queries from '../queries';

const passwordResetRequest = async (email) => {
  const res = await queries.users.passwordResetRequest.query(email);
  if (res.error) {
    throw new Error(`${res.error.code} - ${res.error.message}`);
  }
  return res.data;
};

const passwordResetCode = async (code, password) => {
  const res = await queries.users.passwordResetCode.query(code, password);
  if (res.error) {
    throw new Error(`${res.error.code} - ${res.error.message}`);
  }
};

const useCreateUser = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ firstName, lastName, email, companyId, role, password }) => {
      return queries.users.create.query(firstName?.trim(), lastName?.trim(), email.trim().toLowerCase(), companyId, role, password);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.companies.users.key, variables.companyId]);
        queryClient.invalidateQueries([queries.companies.stats.key, variables.companyId]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useChangeUserPassword = (callbacks = {}) => {
  return useMutation(
    ({ id, password }) => {
      return queries.users.editUserPassword.query(id, password);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useChangeUser = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id: userId, firstName, lastName, companyId, role, isActive }) => {
      return queries.users.change.query(userId, {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        companyId,
        role,
        isActive,
      });
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        const accounts = queryClient.getQueryData([queries.companies.users.key, variables.currentCompanyId]);
        if (Array.isArray(accounts?.data)) {
          const affectedAccount = accounts?.data?.find((account) => account.id === variables.id);

          const isRoleChanged = affectedAccount?.roles[0]?.id !== variables.role;
          const isCompanyChanged = affectedAccount?.companyId !== variables.currentCompanyId;

          if (isRoleChanged) {
            // account role has been changed
            // it could affect seats counter -> need to invalidate companies query
            queryClient.invalidateQueries(queries.companies.list.key);
          }

          if (isCompanyChanged) {
            // account has been transferred to another company
            // we need to invalid old company cache for reload new list accounts
            queryClient.invalidateQueries([queries.companies.users.key, variables.currentCompanyId]);
            // also it could affect seats counter
            queryClient.invalidateQueries(queries.companies.list.key);
          }
        }

        queryClient.invalidateQueries([queries.companies.users.key, data.company?.id]);

        if (variables.context?.company) {
          // invalidate company stats on account change
          queryClient.invalidateQueries([queries.companies.stats.key, variables.context?.company?.id]);
          queryClient.invalidateQueries([queries.users.getAccountQuota.key, variables.context?.company?.managedBy]);
        }

        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useDeleteUser = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id: userId }) => {
      return queries.users.delete.query(userId);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.companies.list.key);
        queryClient.invalidateQueries([queries.companies.users.key, variables.companyId]);
        queryClient.invalidateQueries([queries.companies.stats.key, variables.companyId]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useAddUserProfilePhoto = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ photo }) => {
      return queries.users.addProfilePhoto.query(photo);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.users.addProfilePhoto.key, variables.photo]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useUpdatePassword = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ currentPassword, newPassword }) => {
      return queries.users.updatePassword.query(currentPassword, newPassword);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.notifications.list.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useSetAccountQuota = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ accountId, quota, used, threshold }) => {
      return queries.users.setAccountQuota.query(accountId, quota, used, threshold);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.companies.stats.key, variables.companyId]);
        queryClient.invalidateQueries([queries.users.getAccountQuota.key, variables.accountId]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  passwordResetRequest,
  passwordResetCode,
  useChangeUserPassword,
  useCreateUser,
  useChangeUser,
  useDeleteUser,
  useAddUserProfilePhoto,
  useUpdatePassword,
  useSetAccountQuota,
};
