export default {
  translation: {
    commonUsedWords: {
      login: 'Accedi',
      email: 'Indirizzo email',
      password: 'Password',
      emailIsRequired: "È richiesta l'email",
      mustBeAValidEmail: "Deve essere un'email valida",
      deviceSerialNumber: 'Numero di serie del dispositivo',
      next: 'Avanti',
      success: 'Successo',
      submit: 'Invia',
      firstName: 'Nome',
      firstNameIsRequired: 'Il nome è richiesto',
      lastName: 'Cognome',
      lastNameIsRequired: 'Il cognome è richiesto',
      save: 'Salva',
      undoChanges: 'Annulla modifiche',
      cancel: 'Annulla',
      loading: 'Caricamento...',
      yes: 'Sì',
      no: 'No',
      clear: 'Cancella',
      confirm: 'Confermare',
      noOption: 'Nessuna opzione',
      openText: 'Apri',
      edit: 'Modifica',
      logout: 'Esci',
      latitude: 'Latitudine',
      longitude: 'Longitudine',
      ok: 'Ok',
      okay: 'Va bene',
      done: 'Fatto',
      comingSoon: 'Prossimamente...',
      reset: 'Reimposta',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Mancano campi obbligatori',
        userNotFound: 'Utente non trovato',
        missingRefreshToken: 'Manca il token di aggiornamento',
        cannotRefreshToken: 'Impossibile aggiornare il token',
      },
      helpers: {
        error: 'Errore',
        invalidHTTPMethod: 'Metodo HTTP non valido',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'Conferma indirizzo email',
        confirmEmailIsRequired: "È richiesta la conferma dell'email",
        emailNotMatched: 'Le voci di posta elettronica non corrispondono',
      },
      confirmPassword: {
        confirmPassword: 'Conferma password',
        confirmPasswordIsRequired: 'È richiesta la conferma della password',
        newPasswordIsRequired: 'È richiesta una nuova password',
        showPassword: 'Mostra password',
        passwordLength: 'Le password devono contenere almeno 6 caratteri',
        passwordContainSpaces: 'La password non deve contenere spazi',
        passwordNotMatch: 'Le voci della password non corrispondono',
        newPassword: 'Nuova password',
      },
      confirmModal: {
        areYouSure: 'Sei sicuro?',
      },
      appliedFilters: {
        between: 'Tra {{firstValue}} e {{secondValue}}',
        bottom: 'Fondo {{primaryValue}}%',
        top: 'Cima {{primaryValue}}%',
        activeFilters: 'Filtri attivi',
        active: 'attivo',
        closed: 'chiuso',
      },
      editTable: {
        editTable: 'Modifica tabella',
        selectDeselect: 'Seleziona/Deseleziona valori della tabella',
        resetToDefault: 'Ripristina le impostazioni predefinite',
      },
      errorBoundary: {
        oops: 'Ops! È successo qualcosa di inaspettato.',
        tryAgain: 'Per favore riprova! Se il problema persiste, contatta il tuo amministratore.',
        refresh: 'Aggiorna',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Spiacenti, nessun risultato trovato. Per favore riprova.',
      },
      permissionGuard: {
        invalidPermission: "Struttura di autorizzazione non valida, previsti 3 elementi nell'array",
      },
      preview: {
        findInView: 'Trova nella vista',
        showDetails: 'Mostra dettagli',
        closeEsc: 'Chiudi (Esc)',
        prevLeftArrow: 'Precedente (Freccia sinistra)',
        nextRightArrow: 'Successivo (Freccia destra)',
        proceedWithoutSaving: 'Naviga senza salvare',
        continueEditing: 'Continua a modificare',
        unsavedChanges: {
          partOne: 'Attenzione: ',
          partTwo: 'Hai ',
          partThree: 'MODIFICHE NON SALVATE',
          partFour: '. Navigare senza salvare scarterà tutte le modifiche. Sei sicuro?',
        },
      },
      printRecord: {
        title: 'Registro evento di perdita',
        date: 'Data:',
        location: 'Posizione',
        leakDetails: 'Dettagli della perdita',
        leakParameters: 'Parametri di perdita',
        energyLoss: 'Perdita di energia',
        leakSourceEquipment: 'Attrezzatura della fonte di perdita',
        notes: 'Note',
        leakEventStatus: "Stato dell'evento di perdita",
        requiredAction: 'Azione richiesta',
        leakSourceImage: 'Immagine della fonte di perdita',
        smartLeakTag: 'Etichetta di perdita intelligente',
        lossPerYear: 'Perdita ({{currency}}/anno)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: "L'assegnazione è stata eliminata",
          deleteAssignmentError: "Impossibile eliminare l'assegnazione. Per favore riprova più tardi",
          currentlyAssigned: 'Azione attualmente assegnata',
          repairedBy: 'Riparato da',
          text: 'Stai per eliminare questa assegnazione. Questa azione non può essere annullata',
        },
        actionSaveDate: 'Ultimo salvataggio',
      },
      visibleColumnsPopper: {
        tableValues: 'Seleziona / Deseleziona valori della tabella',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Uguale a',
        LESS_THAN: 'Minore di',
        GREATER_THAN: 'Maggiore di',
        LESS_THAN_OR_EQUAL_TO: 'Minore o uguale a',
        GREATER_THAN_OR_EQUAL_TO: 'Maggiore o uguale a',
        BETWEEN_A_AND_Y: 'Tra',
        TOP_PERCENT: 'Cima "x"%',
        BOTTOM_PERCENT: 'Fondo "x"%',
      },
      recordFields: {
        CREATED_AT: 'Creato il',
        RECORD_ID: 'Numero ID record',
        DOCUMENT_ID: 'Numero ID record',
        DOLLARS_PER_YEAR: 'Perdita',
        GAS_COMPONENT: 'Gas/Componente',
        LEAK_TYPE: 'Profilo tipo di perdita',
        DATE_TIME: 'Data/Ora',
        DB_PEAK: 'dB di picco',
        COMPANY: 'Azienda',
        NOTES: 'Note',
        USER: 'Utente',
        EQUIPMENT_TAG: 'Etichetta attrezzatura/componente',
        FLOW: 'Flusso (m³/h)',
        EQUIPMENT: 'Unità/Attrezzatura',
        ROUTE: 'Percorso',
        ROUTE_LOCATION: 'Posizione del Percorso',
        PROJECT_SURVEY: 'Ref. record di audit/indagine',
        OUTAGE_STATUS: 'Interruzione/Shutdown richiesto?',
        FACILITY: 'Impianto/Sito',
        ACTION_STATUS: "Stato dell'azione",
        SERVICE_TYPE: 'Servizio/Tipo',
        DB_ACTIVE: 'dB attivo',
        REFERENCE_ID: 'progetto #/ID indagine',
        AMBIENT_TEMPERATURE: 'Temperatura ambiente (°C)',
        LEAK_PRESSURE: 'Pressione (kPag)',
        LEAK_TEMPERATURE: 'Temperatura del gas (°C)',
        LEAK_CATEGORY: 'Categoria di perdita',
        DISTANCE: 'Distanza (m)',
        GAS_COST: 'Costo del gas ($/m³)',
        COMPRESSOR_FLOW: 'm³/h per BHP',
        DEVICE_ID: 'ID del dispositivo',
        COMPRESSOR_BHP: 'BHP del compressore',
        COMPRESSOR_TYPE: 'Tipo di compressore',
        MOTOR_EFFICIENCY: 'Efficienza del motore (%)',
        ENERGY_COST: 'Costo energetico (per kWh)',
        HOURS: "Ore di funzionamento all'anno",
        LOCATION: 'Posizione della perdita',
        LAST_MODIFIED: 'Ultima data salvata',
        POWER_LOAD: '% Potenza a pieno carico',
        CLIENT: 'Cliente',
        SERVICE: 'Servizio',
        CAPACITY: '% Capacità',
        EQUIPMENT_TYPE: 'Tipo di attrezzatura/componente',
        LEAK_TAG: 'Tag della perdita #/Ref. di lavoro #',
        REPAIRED_BY: 'Riparato da',
        ALTITUDE: 'Altitudine (m)',
        REQUIRED_ACTION: 'Azione richiesta',
        FREQUENCY_LOW_BOUND: 'Frequenza (bassa)',
        FREQUENCY_HIGH_BOUND: 'Frequenza (alta)',
        SENSITIVITY: 'Sensibilità',
        KWH_PER_YEAR: 'kWh/anno',
        ASSIGNED_BY: 'Assegnato da',
        ASSIGNED_TO: 'Assegnato a',
        ASSIGNED_DATE: 'Data di assegnazione',
        LOSS_PER_YEAR: "Perdita (all'anno)",
        ASSIGNED: 'Assegnato',
        CREATOR_USER_ID: 'ID utente',
        CURRENCY: 'Valuta',
        COMPLETED_ON: 'Completato il',
        IMAGES: 'Immagini della fonte della perdita',
        ACTION_STATUS_MODIFIED: "Stato dell'azione modificato",
      },
      IMPERIAL_LABELS: {
        FLOW: 'Flusso (cfm)',
        AMBIENT_TEMPERATURE: 'Temperatura ambiente (°F)',
        LEAK_PRESSURE: 'Pressione (psi)',
        LEAK_TEMPERATURE: 'Temperatura del gas (°F)',
        DISTANCE: 'Distanza (ft)',
        GAS_COST: 'Costo del gas ($/ft³)',
        COMPRESSOR_FLOW: 'cfm per BHP',
        ALTITUDE: 'Altitudine (ft)',
      },
      userFields: {
        EMAIL: 'Email',
        ROLE: 'Ruolo',
        DEVICE_ID: 'ID del dispositivo',
        DATE_TIME: 'Creato',
        ACTIVE: 'Stato',
        SOFTWARE_VERSION: 'Versione software',
        FIRMWARE_VERSION: 'Versione firmware',
        FIRST_NAME: 'Nome',
        LAST_NAME: 'Cognome',
        COMPANY_SLUG: 'Azienda',
        COMPANY_NAME: 'Azienda',
      },
      auditFields: {
        documentId: 'Numero ID record',
        companyName: "Nome dell'azienda",
        auditorName: 'Nome del revisore',
        auditorEmail: 'Email del revisore',
        createdAt: 'Creato il',
        updatedAt: 'Aggiornato il',
        comment: 'Commenti',
        isLocked: 'Blocco di audit',
      },
      deviceFields: {
        DEVICE_ID: 'ID del dispositivo',
        ACTIVE: 'Stato',
        REGISTERED_ON: 'Attivato il',
        EVENT_COUNT: 'Record',
        UNIQUE_COMPANIES: 'Aziende',
        LAST_USED_AT: 'Ultimo utilizzo il',
        LAST_USED_BY_NAME: 'Ultimo nome utilizzato',
        LAST_USED_BY_EMAIL: 'Ultima email utilizzata',
        LOCATION: 'Ultima posizione utilizzata',
        FIRMWARE_VERSION: 'Versione firmware',
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '# Perdite trovate',
          NO_OF_LEAKS_REPAIRED: '# Perdite riparate',
          PERCENT_LEAKS_REPAIRED: '% Perdite riparate',
          VOL_FLOW_FOUND: 'Flusso volumetrico trovato (m³/h)',
          VOL_FLOW_REPAIRED: 'Flusso volumetrico riparato (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Flusso riparato',
          VALUE_FOUND: 'Valore trovato',
          VALUE_REPAIRED: 'Valore riparato',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Opportunità di risparmio energetico (trovata) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Opportunità di risparmio energetico % (trovato vs calcolato o misurato)',
          ENERGY_SAVING_REALIZED_FIXED: 'Risparmio energetico realizzato (riparato) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '% Risparmio energetico realizzato (riparato vs calcolato o misurato)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Pressione di controllo del sistema ridotta (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Flusso volumetrico trovato (cfm)',
          VOL_FLOW_REPAIRED: 'Flusso volumetrico riparato (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Opportunità di risparmio energetico (trovata) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Risparmio energetico realizzato (riparato) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Pressione di controllo del sistema ridotta (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Indicatore di performance',
          ENPI_VALUE: 'Valore EnPI',
          ENB_VALUE: 'Valore EnB',
          SUCCESS_THRESHOLD: 'Soglia di successo (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Assegnato a',
        assignedBy: 'Assegnato da',
        assignedDate: 'Data di assegnazione',
        requestReassign: 'Richiesta di riassegnazione',
        actionStatus: "Stato dell'azione",
        partsNeeded: 'Parte richiesta',
        productionOutage: 'Arresto richiesto',
        timeToRepair: 'Tempo di riparazione',
        repairCost: 'Costo di riparazione',
        closeOutRef: 'Ref. di chiusura',
        'assignmentRecord.documentId': 'ID di registro',
        assignmentId: 'ID di assegnazione',
        owner: 'Proprietario',
        companyName: 'Azienda',
        assignmentActive: "Stato dell'assegnazione",
        'assignmentRecord.leakStatus.building': 'Unità/Attrezzatura',
        'assignmentRecord.leakStatus.facility': 'Struttura',
        'assignmentRecord.leakStatus.routeName': 'Percorso',
        'assignmentRecord.leakStatus.leakTag': 'Tag della perdita #/Ref. di lavoro #',
        'assignmentRecord.currency': 'Valuta',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Azione intrapresa',
        CLOSE_OUT_REF: "Riferimento di chiusura dell'azione n.",
        PARTS_NEEDED: 'Nuova parte/componente richiesta',
        PRODUCTION_OUTAGE: 'Produzione interrotta',
        REPAIR_COST: 'Costo ($)',
        REQUEST_REASSIGN: 'Richiedi riassegnazione',
        ACTION_STATUS: "Stato dell'azione",
        TIME_TO_REPAIR: 'Tempo impiegato per la riparazione (minuti)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Flusso per categoria di perdita',
          LOSS_BY_LEAK: 'Perdita per categoria di perdita',
          TAGS_BY_LEAK: 'Etichette per categoria di perdita',
          AVG_FLOW_BY_LEAK: 'Flusso medio per categoria di perdita',
          SAVING_OPT_BY_REPAIR: 'Opportunità e risparmi per tempo di riparazione',
          FLOW_REPAIR_TIME: 'Flusso per tempo di riparazione',
          LOSS_REPAIR_TIME: 'Perdita per tempo di riparazione',
          FLOW_REPAIR_PROG: 'Flusso per avanzamento delle riparazioni',
          TOTAL_REPAIR_PROG: 'Perdita per avanzamento delle riparazioni',
          FLOW_BY_EQUIP_COMP: 'Flusso per attrezzatura/componente',
          LOSS_BY_EQUIP_COMP: 'Perdita per attrezzatura/componente',
          TAGS_BY_EQUIP_COMP: 'Etichette per attrezzatura/componente',
        },
        leakCategoryLabels: {
          LOW: 'Basso',
          MEDIUM: 'Medio',
          HIGH: 'Alto',
        },
        actionStatus: {
          OPEN: 'Apri',
          COMPLETE: 'Completo',
        },
        equipmentTypes: {
          CONNECTOR: 'Connettore',
          CONTROL_VALVE: 'Valvola di controllo',
          HOSE: 'Tubo flessibile',
          TUBE_FITTING: 'Raccordo per tubi',
          METER: 'Misuratore',
          OPEN_ENDED_LINE: 'Linea aperta',
          PRESSURE_RELIEF_VALVE: 'Valvola di sicurezza',
          PUMP_SEAL: 'Tenuta della pompa',
          REGULATOR: 'Regolatore',
          VALVE: 'Valvola',
          LEVEL_CONTROLLER: 'Controllore di livello',
          POSITIONER: 'Posizionatore',
          PRESSURE_CONTROLLER: 'Regolatore di pressione',
          TRANSDUCER: 'Trasduttore',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Strumento pneumatico generico',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compressore alternativo generico',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compressore centrifugo generico',
        },
        mainGasValues: {
          AIR: 'Aria',
          METHANE: 'Metano (CH4)',
          CARBON_DIOXIDE: 'Anidride carbonica (CO2)',
          HYDROGEN: 'Idrogeno (H2)',
          HELIUM: 'Elio (He)',
          NITROGEN: 'Azoto (N2)',
          REFRIGERANT: 'Refrigerante (HFC)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Ossigeno (O)',
          WATER: 'Acqua (Vapore)',
        },
        leakTypeValues: {
          DEFAULT: 'Nessuno selezionato',
          HOLE: 'Foro',
          CRACK: 'Crepa',
          FITTING: 'Raccordo',
          FLANGE: 'Flangia',
          'GLAND/SEAL': 'Pressacavo/Tenuta',
          THREAD: 'Filetto',
          'TUBE/HOSE': 'Tubo/Tubo flessibile',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Aria compressa',
          VACUUM: 'Vuoto',
          STEAM: 'Vapore',
          PROCESS_GAS: 'Gas di processo',
          LIQUID: 'Liquido',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Vite rotativa',
          RECIPROCATING: 'Alternativo',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifugo',
        },
        labels: {
          LOW: 'Basso',
          MEDIUM: 'Medio',
          HIGH: 'Alto',
          EQUIPMENT: 'Attrezzatura',
          SAVINGS: 'Risparmi',
          OPPORTUNITY: 'Opportunità',
          LOSS: 'Perdita',
          REPAIRED: 'Riparato',
          UNREPAIRED: 'Non riparato',
          LESS_15_DAYS: 'Meno di 15 giorni',
          DAYS_16_TO_30: '15 a 30 giorni',
          DAYS_31_TO_60: '31 a 60 giorni',
          DAYS_61_TO_90: 'Più di 60 giorni',
          LESS_THAN_15_DAYS: 'Riparato in meno di 15 giorni',
          '16_TO_30_DAYS': 'Riparato in 16-30 giorni',
          '31_TO_60_DAYS': 'Riparato in 31-60 giorni',
          MORE_THAN_60_DAYS: 'Riparato in 61-90 giorni',
          AIR: 'Aria',
          METHANE: 'Metano (CH4)',
          CARBON_DIOXIDE: 'Anidride carbonica (CO2)',
          HYDROGEN: 'Idrogeno (H2)',
          HELIUM: 'Elio (He)',
          NITROGEN: 'Azoto (N2)',
          REFRIGERANT: 'Refrigerante (HFC)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Ossigeno (O)',
          WATER: 'Acqua (Vapore)',
          DEFAULT: 'Nessuno selezionato',
          HOLE: 'Foro',
          CRACK: 'Crepa',
          FITTING: 'Raccordo',
          FLANGE: 'Flangia',
          'GLAND/SEAL': 'Pressacavo/Tenuta',
          THREAD: 'Filetto',
          'TUBE/HOSE': 'Tubo/Tubo flessibile',
          COMPRESSED_AIR: 'Aria compressa',
          VACUUM: 'Vuoto',
          STEAM: 'Vapore',
          PROCESS_GAS: 'Gas di processo',
          LIQUID: 'Liquido',
          ROTARY_SCREW: 'Vite rotativa',
          RECIPROCATING: 'Alternativo',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifugo',
          CONNECTOR: 'Connettore',
          CONTROL_VALVE: 'Valvola di controllo',
          HOSE: 'Tubo flessibile',
          TUBE_FITTING: 'Raccordo per tubi',
          METER: 'Misuratore',
          OPEN_ENDED_LINE: 'Linea aperta',
          PRESSURE_RELIEF_VALVE: 'Valvola di sicurezza',
          PUMP_SEAL: 'Tenuta della pompa',
          REGULATOR: 'Regolatore',
          VALVE: 'Valvola',
          LEVEL_CONTROLLER: 'Controllore di livello',
          POSITIONER: 'Posizionatore',
          PRESSURE_CONTROLLER: 'Regolatore di pressione',
          TRANSDUCER: 'Trasduttore',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Strumento pneumatico generico',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compressore alternativo generico',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compressore centrifugo generico',
        },
        leakRecordPDF: 'Registro evento di perdita • Esportato {{date}}',
        leakTag: 'Etichette di perdita intelligenti • Esportato {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Tag del compressore',
          COMPRESSOR_TYPE: 'Tipo di compressore',
          COMPRESSOR_MAKE: 'Marca del compressore',
          COMPRESSOR_MODEL: 'Modello del compressore',
          NAMEPLATE_HP: 'HP di targa',
          NAMEPLATE_CAPACITY: 'Capacità di targa (m³/h)',
          MOTOR_EFFICIENCY: 'Efficienza del motore (%)',
          MOTOR_VOLTAGE: 'Tensione del motore',
          FULL_LOAD_CURRENT: 'Corrente a pieno carico (Ampere)',
          FULL_LOAD_INPUT_POWER: 'Potenza in ingresso a pieno carico (kW)',
          RATED_PRESSURE: 'Pressione nominale (kPag)',
          PRESSURE_SETPOINT: 'Punto di impostazione della pressione (kPag)',
          CONTROL: 'Controllo',
          TOTAL_AIR_STORAGE_SIZE: "Dimensione totale dello stoccaggio dell'aria (l)",
          IS_ACTIVE: 'Compressore Attivo / Spento',
          OPERATION_TYPE: 'Trim / Carico base',
          PERCENT_LOAD: '% Carico',
          COMPRESSOR_SETPOINT_SEU: 'Punto di impostazione del compressore (kPag)',
          COMPRESSOR_FLOW_SEU: 'Flusso del compressore (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'Punto di impostazione della pressione del sistema (kPag)',
          SYSTEM_MEASURED_FLOW: 'Flusso misurato del sistema (m³/h)',
          DATE_TIME_RUNNING_STATE: 'Data e ora dello stato di funzionamento',
          NOTES: 'Note',
          NAMEPLATE: 'Targa del compressore',
          DATASHEET: 'Scheda tecnica del compressore',
          BHP: 'BHP',
          OPERATING_HOURS: 'Ore di funzionamento',
          LOADED_PERCENT_TIME_RUNNING: '% Tempo di funzionamento',
          LOADED_PERCENT_FULL_LOAD_BHP: '% BHP a carico pieno (Capacità)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Tempo di funzionamento',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% BHP a carico pieno (Capacità)',
          ENERGY_COST: 'Tariffa elettrica aggregata ($)',
          ANNUAL_ENERGY_COST: 'Costo energetico annuale ($)',
          ANNUAL_ENERGY_KWH: 'Energia annuale in kWh',
          COST_FULLY_LOADED: 'Costo quando completamente carico ($)',
          COST_UNLOADED: 'Costo quando scarico ($)',
          KWH_PER_YEAR_LOADED: "kWh all'anno quando carico",
          KWH_PER_YEAR_UNLOADED: "kWh all'anno quando scarico",
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Capacità di targa (cfm)',
          FULL_LOAD_INPUT_POWER: 'Potenza in ingresso a pieno carico (BTU/h)',
          RATED_PRESSURE: 'Pressione nominale (psi)',
          PRESSURE_SETPOINT: 'Punto di impostazione della pressione (psi)',
          TOTAL_AIR_STORAGE_SIZE: "Dimensione totale dello stoccaggio dell'aria (gal)",
          COMPRESSOR_SETPOINT_SEU: 'Punto di impostazione del compressore (psi)',
          COMPRESSOR_FLOW_SEU: 'Flusso del compressore (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'Punto di impostazione della pressione del sistema (psi)',
          SYSTEM_MEASURED_FLOW: 'Flusso misurato del sistema (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: 'Fornitore di servizi',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Unità',
          ENERGY_SOURCES: 'Fonti di energia',
          FE_HOURS_OPERATION: 'Ore di funzionamento',
          FE_ENERGY_COST: "Costo dell'energia ($/kWh)",
          FE_EFFICIENCY: 'Efficienza (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Efficienza (kW/100cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Errore di validazione della richiesta. Si prega di controllare i campi del modulo',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Registrare un dispositivo',
          help: 'Aiuto',
          metric: 'Metrico',
          imperial: 'Imperiale',
          downloadApk: 'Scarica APK',
          downloadOta: 'Scarica file',
        },
        otaDialog: {
          title: 'Scarica file',
          downloadButton: 'Scarica',
          serialNumberLabel: 'Numero di serie del dispositivo',
          serialNumberRequired: 'Numero di serie richiesto',
          fileDownloaded: 'File {{fileName}} scaricato con successo',
          errorFetchingFile: 'Errore durante il recupero del file. Per favore riprova più tardi',
          selectSerialNumber: 'Seleziona un numero di serie del dispositivo',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Sei sul piano Essenziale. ',
            dialogTwo: 'Clicca ',
            dialogThree: 'qui',
            dialogFour: 'per acquistare una nuova licenza amministratore o contatta ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable:
            "Stiamo riscontrando difficoltà tecniche. Il nostro servizio potrebbe essere interessato. Ci scusiamo per l'inconveniente.",
          downForMaintenance: 'Il sistema è attualmente in manutenzione. Riprova più tardi.',
          tagsManagement: {
            dialogOne: 'Acquista di più',
            dialogTwo: 'Etichette di perdita intelligenti PROSARIS',
            dialogThree: 'qui！',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menu',
            editProfile: 'Modifica profilo',
            logout: 'Esci',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Cancella tutto',
            readAll: 'Segna tutto come letto',
            noNotifications: 'Nessuna notifica',
          },
        },
        index: {
          dashboard: 'Cruscotto',
          records: 'Record',
          actions: 'Azioni',
          company: 'Azienda',
          tags: 'Tag',
        },
        loggedInAs: 'Connesso come',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: "Impossibile determinare l'etichetta dal campo {{field}}",
        cannontDeterminByLabel: "Impossibile determinare il campo dall'etichetta {{label}}",
        active: 'Attivo',
        closed: 'Chiuso',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Cruscotto',
        records: 'Record',
        actions: 'Azioni',
        company: 'Azienda',
        tags: 'Tag',
        redirecting: 'Reindirizzamento in corso...',
      },
      account: {
        index: {
          updatePassword: 'Password aggiornata con successo.',
          accountDeletedSuccess: "L'account {{user}} è stato eliminato con successo",
          accountDeletedError: "Impossibile eliminare l'account al momento. Per favore riprova più tardi.",
          authenticationError: "Impossibile autenticare l'utente",
          personalDetails: 'Dettagli personali',
          currentPasswordIsRequired: 'È richiesta la password attuale',
          username: 'Nome utente',
          changePassword: 'Cambia password',
          currentPassword: 'Password attuale',
          profilePicture: 'Immagine del profilo',
          dangerZone: 'Zona di pericolo',
          deleteAccountTitle: 'Elimina account',
          deleteAccountMessage: "L'account non sarà più disponibile e tutte le informazioni personali verranno eliminate permanentemente.",
          alertTitle: "Si prega di confermare l'eliminazione dell'account",
          deleteDialog: {
            dialogOne: 'Attenzione:',
            dialogTwo: "L'eliminazione del tuo account rimuoverà permanentemente tutte le tue informazioni personali dal nostro database.",
            dialogThree: 'QUESTA AZIONE NON PUÒ ESSERE ANNULLATA.',
            dialogFour: 'Si prega di inserire la password per continuare.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Cache svuotata con successo!',
          editProfile: 'Modifica profilo',
          clearCache: 'Cancella cache',
          alertTitle: "Si prega di confermare la cancellazione della cache dell'app",
          deleteAll: 'Elimina tutto',
          accordionDialog: {
            dialogOne: 'Questo eliminerà tutte le impostazioni memorizzate per le seguenti pagine:',
            dialogTwo: 'Grafici, filtri dei grafici, intervallo di tempo',
            dialogThree: 'Filtri, Ordinamento, Colonne, Anteprima',
            dialogFour: 'Filtri, colonne',
            dialogFive: 'Azienda selezionata',
            dialogSix: 'Filtri, colonne',
          },
          deleteSelective: 'Elimina selettivamente',
          selectiveDialog: 'Si prega di selezionare le impostazioni memorizzate che si desidera eliminare:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Intervallo di tempo',
            CHART_FILTERS: 'Filtri dei grafici',
            CHARTS: 'Grafici',
          },
          Records: {
            HIGHLIGHTED: 'Anteprima',
            COLUMNS: 'Colonne',
            FILTERS: 'Filtri',
            SORT_COLUMN: 'Ordinamento',
          },
          Actions: {
            FILTERS: 'Filtri',
            COLUMNS: 'Colonne',
          },
          Company: {
            SELECTED: 'Azienda selezionata',
          },
          Tags: {
            FILTERS: 'Filtri',
            COLUMNS: 'Colonne',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Azienda',
          'leakStatus.building': 'Unità/Attrezzatura',
          'leakStatus.facility': 'Struttura',
          'leakStatus.leakTag': 'Tag della perdita #/Ref. di lavoro #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'Non ci sono assegnazioni da visualizzare',
        },
        filtersCommonWords: {
          recordsFilter: 'Filtro dei record',
          column: 'Colonna',
          selectAColumn: 'Seleziona una colonna...',
          addSearchTerm: 'Aggiungi un termine di ricerca...',
          filtersArentAvailable: 'I filtri non sono disponibili',
          value: 'Valore',
          exportCSV: 'Creain CSV',
          show: 'Mostra',
          loadMoreRecords: 'Carica più record',
          selectedAllRecords: 'Selezionati tutti i record ({{totalNum}})',
          selectedFromRecords: 'Selezionato {{selectedNum}} su {{totalNum}} record',
        },
        invalidColumn: 'Colonna non valida {{column}}',
        assignedActionRecords: 'Registri delle azioni assegnate',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Hai dimenticato la password?',
          successMessage: "Abbiamo inviato un'email di conferma a",
        },
        authSuccess: {
          thanks: 'Grazie!',
          backToLogin: 'Torna al login di Prosaris',
        },
        loginView: {
          forgotPass: 'Hai dimenticato la password?',
          createAccount: 'Crea un account',
          passwordIsRequired: 'È richiesta la password',
          resetPassword: 'Reimposta password',
          contactSupport: 'Si prega di contattare support@prosaris.ca',
          incorrectEmailOrPassword: 'Email o password errata',
          resetPassDialog: {
            dialogOne: 'È necessario reimpostare la password prima di continuare.',
            dialogTwo: 'È stata inviata una email di reimpostazione della password con istruzioni.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'La password è stata reimpostata con successo',
          enterNewPassword: 'Inserisci la tua nuova password qui sotto.',
          passwordChanged: 'La tua password è stata cambiata.',
        },
        createCompanyView: {
          createCompany: "Crea un'azienda",
          companyName: "Nome dell'azienda",
          successCreatingCompany: 'La tua azienda è stata creata.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Si è verificato un errore imprevisto durante il caricamento dei dati sulla perdita',
          calculatingError: 'Si è verificato un errore imprevisto durante il calcolo',
          dbMeasured: 'Db misurato',
          calculate: 'Calcolare',
          calculating: 'Calcolo in corso...',
          outflowCoefficient: 'Coefficiente di deflusso',
          kwhPerYear: 'Kwh/anno',
        },
      },
      companies: {
        commonUsedWords: {
          assigendAdminLicenses: 'Licenze amministratore assegnate',
          company: 'Azienda',
          totalAccounts: 'Totali account',
          email: 'Email',
          name: 'Nome',
          loadCompanyUsersError: "Impossibile caricare gli utenti dell'azienda. Per favore riprova più tardi",
          activated: 'Attivato',
          deactivated: 'Disattivato',
          activating: 'Attivazione in corso',
          deactivating: 'Disattivazione in corso',
          deactivate: 'Disattiva',
          activate: 'Attiva',
          set: 'Imposta',
          youAreGoingTo: 'Stai per',
          areYouSure: 'Sei sicuro?',
          companyNameIsRequired: "Il nome dell'azienda è richiesto",
          editCompany: 'Modifica azienda',
          createCompany: 'Crea azienda',
          viewHistory: 'Visualizza cronologia',
          onEssentialPlan: 'Sei su un piano essenziale. Si prega di contattare support@prosaris.ca',
          exportCSV: 'Creain CSV',
        },
        accounts: {
          accountDialog: {
            companyIsRequired: "L'azienda è obbligatoria",
            roleIsRequired: 'Il ruolo è obbligatorio',
            createAccountSuccessMessage: 'Account creato con successo',
            emailTakenErrorMessage: 'Un account con questa email esiste già nel sistema',
            errorWhileCreatingAccountMessage: "Errore durante la creazione dell'account. Per favore riprova più tardi.",
            changeAccountSuccessMessage: 'Account aggiornato con successo',
            errorWhileChangingAccountMessage: "Errore durante l'aggiornamento dell'account. Per favore riprova.",
            editAccount: 'Modifica account',
            createAccount: 'Crea account',
            role: 'Ruolo',
          },
          changeOwner: {
            changeOwnerMessage: 'Vuoi anche cambiare il proprietario dei record di perdita relativi a questo account?',
            no: 'No, mantieni il conto attuale',
          },
          contentCell: {
            loadAccountRolesError: "Caricamento delle informazioni sui ruoli dell'account non riuscito. Per favore riprova più tardi",
            changeRoleSuccessMessage: "L'account {{email}} ha un nuovo ruolo",
            changeRoleErrorMessage: "Errore durante l'aggiornamento del ruolo per l'account {{email}}. Per favore riprova più tardi",
            editUser: 'Modifica utente',
            adminSetPassword: 'Amministratore imposta la password',
            resetPassword: 'Reimposta la password',
            user: 'Utente',
            deleteUser: 'Elimina utente',
          },
          index: {
            noUsersToDisplay: 'Non ci sono utenti da visualizzare',
            changeStatusSuccessMessage: 'Utente {{isActive}} con successo',
            changeStatusErrorMessage: 'Errore durante {{isActive}} utente. Per favore riprova',
            changeOwnerSuccessMessage: 'I record di perdita sono stati trasferiti a un nuovo proprietario',
            changeOwnerErrorMessage: 'Impossibile trasferire i record a un nuovo proprietario. Per favore riprova più tardi',
            deleteAccountMutationSuccessMessage: "L'account {{email}} è stato eliminato con successo",
            deleteAccountMutationErrorMessage: "Impossibile eliminare l'account {{email}} al momento. Per favore riprova più tardi",
            resetAccountPassWordSuccessMessage: 'E-mail di reimpostazione della password inviata con successo',
            users: 'utenti',
            noneAvailable: 'Nessuno disponibile',
            of: 'di',
            contactSupport:
              "Si prega di contattare support@prosaris.ca per ulteriori informazioni sulle licenze amministrative dell'azienda",
            findAccount: 'Trova account',
            placeholder: 'Digita nome o email...',
            tooltip: 'È necessaria almeno una licenza amministrativa aziendale prima di poter invitare utenti',
            invite: 'Invita',
            alertDialog: {
              messageOne: "Si prega di confermare la richiesta di reimpostazione della password dell'utente",
              messageTwo: "Si prega di confermare il cambio di stato dell'account",
              messageThree: "Si prega di confermare l'eliminazione dell'account",
            },
            sendAPasswordResetEmail: "Stai per inviare un'e-mail di reimpostazione della password per il seguente account:",
            confirmMessage: 'Vuoi procedere?',
            followingAccount: 'seguente account:',
            delete: 'ELIMINA',
            userCreatedDialogTitle: "Link per il ripristino della password dell'utente inviato con successo",
            userCreatedDialogDescription:
              "All'indirizzo email dell'utente è stato inviato un link per reimpostare la password. Puoi anche copiare il link qui sotto.",
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: "Password dell'account aggiornata con successo",
            changedUserPasswordErrorMessage: "Errore durante l'aggiornamento dell'account. Per favore riprova.",
            setNewPassword: 'Imposta nuova password',
          },
          setPassword: {
            automatic: 'Genera automaticamente una password sicura',
            manual: 'Crea password manualmente',
            copyLinkInstruction: 'Potrai visualizzare e copiare la password nel passaggio successivo',
          },
          userCreatedDialog: {
            title: 'Utente creato con successo',
            description:
              "Il tuo nuovo utente può ora iniziare a utilizzare il dashboard Prosaris. L'utente riceverà anche un link via e-mail per reimpostare la password.",
            passwordCopied: 'Password copiata negli appunti',
            passwordResetLinkCopied: 'Link di reimpostazione della password copiato negli appunti',
            copyPassword: 'Copia password',
            copyPasswordResetLink: 'Copia link per reimpostare la password',
          },
          useRoleOptions: {
            notAvailable: '(non disponibile)',
            adminSeatTaken: '(il posto amministratore è occupato)',
            allSeatsAreTaken: '(tutti i {{numSeats}} posti sono occupati)',
            adminRolesTitle: 'Ruoli amministrativi',
            roles: {
              superAdmin: 'Amministratore Prosaris',
              companyAdmin: 'Azienda (Amministratore)',
              superCompanyAdmin: 'Super Azienda (Amministratore)',
              companyRead: 'Azienda (Solo lettura)',
              superAdminRead: 'Amministratore Prosaris (Solo lettura)',
              integrator: 'Integratore',
              auditor: 'Revisore',
              user: 'Utente',
              essential: 'Prosaris Essential',
            },
            rolesDescriptions: {
              superAdmin: 'Consentito avere il pieno controllo su più aziende.',
              companyAdmin: "Consentito visualizzare, modificare e assegnare i record dell'azienda.",
              superCompanyAdmin: "Consentito avere il pieno controllo sui record e sugli utenti dell'azienda.",
              companyRead: "Consentito visualizzare i record dell'azienda in modalità sola lettura.",
              superAdminRead: 'Consentito avere accesso in sola lettura a tutte le aziende.',
              integrator: 'Consente le integrazioni di terze parti per gestire aziende e account.',
              auditor: "Per consentire l'auditing dei record di perdita.",
              user: 'Consentito creare/aggiornare/eliminare i propri record.',
              essential: 'Un ruolo con funzionalità limitate.',
            },
            nonAdminRolesTitle: 'Ruoli non amministrativi',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Impossibile caricare le revisioni. Per favore riprova più tardi',
            audits: 'revisioni',
            noAuditsToDisplay: 'Nessuna revisione da visualizzare',
            findByAuditorEmail: "Trova per email dell'auditor",
            placeholder: "Digita email dell'auditor...",
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Il nome della struttura è richiesto',
              hoursYearlyIsRequired: 'Le ore totali/anno sono richieste',
              totalHoursYearly: 'Le ore totali/anno non possono superare 8760',
              invalidPhoneNumber: 'Numero di telefono non valido',
              createErrorMessage: 'Ore x Turni non possono superare le 24 ore',
              loadFacilityErrorMessage: 'Impossibile caricare la struttura. Per favore riprova più tardi',
              createFacilitySuccessMessage: 'Struttura creata con successo',
              createFacilityErrorMessage: 'Errore durante la creazione della struttura. Per favore riprova più tardi.',
              changeFacilitySuccessMessage: 'Struttura aggiornata con successo',
              changeFacilityErrorMessage: "Errore durante l'aggiornamento della struttura. Per favore riprova.",
              facilityName: 'Nome della struttura',
              abbreviation: 'Abbreviazione',
              address: 'Indirizzo',
              enterALocation: 'Inserisci una posizione',
              tooltip: "per attività di gestione dell'energia, anche approvatore del rapporto",
              energyMgtOwner: 'Proprietario della gestione energetica',
              title: 'Titolo',
              phone: 'Telefono',
              tooltipTwo: "per i rinnovi delle soluzioni e le questioni generali dell'account",
              accountOwner: "Proprietario dell'account",
              manufacturingArea: 'Area di produzione',
              size: 'Dimensione',
              unit: 'Unità',
              hoursOfOperation: 'Ore di funzionamento',
              auditFrequency: 'Frequenza di revisione',
            },
            operationHours: {
              daysPerWeek: 'Giorni/Settimana',
              shiftsPerDay: 'Turni/Giorno',
              hoursPerShift: 'Ore/Turno',
              weeksPerYear: '52 settimane/anno',
              totalHoursYearly: 'Totale = Ore/Anno',
            },
            constants: {
              constant: 'Costante',
              monthly: 'Mensile',
              threeMonth: '3 Mesi',
              fourMonth: '4 Mesi',
              sixMonth: '6 Mesi',
              nineMonth: '9 Mesi',
              annual: 'Annuale',
              sqft: 'Piedi quadrati [SQFT]',
              sqm: 'Metri quadrati [SQM]',
            },
          },
          index: {
            users: 'Utenti',
            devices: 'Dispositivi',
            facility: 'Struttura',
            energy: 'Energia',
            seu: 'SEUs - Comp.',
            enpi: 'EnPIs/EnBs',
            routes: 'Percorsi',
            audit: 'Revisioni',
            comingSoon: 'Prossimamente...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Caricamento degli EnPIs fallito. Si prega di riprovare più tardi',
            createEnpisSuccessMessage: 'EnPIs creati con successo',
            createEnpisErrorMessage: 'Errore durante la creazione degli EnPIs. Si prega di riprovare più tardi.',
            changeEnpisSuccessMessage: 'EnPIs aggiornati con successo',
            changeEnpisErrorMessage: "Errore durante l'aggiornamento degli EnPIs. Si prega di riprovare.",
            showHistory: 'Mostra cronologia',
          },
          changeHistory: {
            bulkOperation: "come un'operazione in blocco",
            details: 'dettagli',
            created: 'creato',
            updated: 'aggiornato',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Cronologia',
            noHistory: 'Nessuna cronologia',
          },
        },
        energy: {
          index: {
            utilProvider: 'Fornitore di servizi pubblici',
            energyUse: "Uso dell'energia e tipo di consumo",
            error: {
              loadFacility: 'Caricamento della struttura non riuscito. Riprova più tardi',
              loadEnergy: 'Caricamento dei dati energetici non riuscito. Riprova più tardi',
              loadUtilProviderLogo: 'Caricamento del logo del fornitore di servizi pubblici non riuscito. Riprova più tardi',
              createEnergy: 'Creazione dei dati energetici non riuscita. Riprova più tardi',
              createUtilProviderLogo: 'Caricamento del logo del fornitore di servizi pubblici non riuscito. Riprova più tardi',
              updateEnergy: 'Aggiornamento dei dati energetici non riuscito. Riprova più tardi',
              deleteUtilProviderLogo: 'Eliminazione del logo del fornitore di servizi pubblici non riuscita. Riprova più tardi',
            },
            success: {
              createEnergy: 'Dati energetici creati con successo',
              updateEnergy: 'Dati energetici aggiornati con successo',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: "Costo dell'energia",
              energySources: 'Fonti di energia',
              per: 'per',
              validations: {
                utilProviderIsRequired: 'Il fornitore di servizi pubblici è obbligatorio',
                costOfEnergyIsRequired: "Il costo dell'energia è obbligatorio",
                costOfEnergyMustBeNumber: "Il costo dell'energia deve essere un numero",
                costOfEnergyMustBeGreaterThanZero: "Il costo dell'energia deve essere maggiore o uguale a 0",
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'La dimensione del file deve essere inferiore a 1 MB',
                preview: 'Anteprima immagine non riuscita',
              },
              logo: 'Logo',
              addLogo: 'Aggiungi logo',
              removeLogo: 'Rimuovi logo',
            },
            EnergySources: {
              energySource: 'Fonte di energia',
              percentage: 'Percentuale',
              selectEnergySource: 'Seleziona fonte di energia',
              addEnergySource: 'Aggiungi fonte di energia',
              totalPercentage: 'Percentuale totale',
              mustBeEqualTo100: '(deve essere uguale al 100%)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Gas naturale',
                  FUEL_OIL: 'Olio combustibile',
                  DIESEL: 'Diesel',
                  GASOLINE: 'Benzina',
                  PROPANE: 'Propano',
                  COAL: 'Carbone',
                  WIND: 'Vento',
                  GEOTHERMAL: 'Geotermico',
                  BIOMASS: 'Biomassa',
                  STEAM: 'Vapore',
                  COMPRESSED_AIR: 'Aria compressa',
                  CHILLED_WATER: 'Acqua refrigerata',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Approccio di calcolo attuale',
              currentCalcApproachMessage:
                "Questo approccio di calcolo è attualmente applicato a tutti i campi 'Costo dell'energia' per impostazione predefinita, a meno che l'utente non lo abbia sovrascritto.",
              alertTitle: 'Reimposta approccio di calcolo',
              alertMessageOne:
                "Questa azione smetterà di utilizzare l'approccio di calcolo attuale per tutti i campi 'Costo dell'energia' d'ora in poi.",
              alertMessageTwo: 'Si prega di notare che questa modifica non influirà su alcun record esistente.',
              alertMessageThree: 'Sei sicuro di voler procedere con il ripristino?',
            },
            ReportType: {
              calcApproach: 'Approccio al calcolo',
              constants: {
                calculations: {
                  FIXED: 'Efficienza fissa',
                  USDOE: 'US DoE',
                  NREL: 'NREL capitolo 22',
                },
              },
              setAsCurrent: 'Imposta come attuale',
              setAsCurrentMessage: "Questo approccio di calcolo sarà utilizzato per tutti i campi 'Costo dell'energia' d'ora in poi.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: "L'efficienza deve essere maggiore o uguale a 0",
                  efficiencyMustBeNumber: "L'efficienza deve essere un numero",
                  operationHoursMustBeGreaterThanZero: 'Le ore di funzionamento devono essere maggiori o uguali a 0',
                  operationHoursMustBeLessThan8760: 'Le ore di funzionamento devono essere inferiori a 8760',
                  operationHoursMustBeNumber: 'Le ore di funzionamento devono essere un numero',
                },
              },
              UsDoe: {
                loaded: 'Caricato',
                unloaded: 'Scaricato',
                calcSummary: 'Riepilogo del calcolo',
                currentOperatingSystems: 'Sistemi operativi attuali',
                noCompsFoundAddCompOne: 'Nessun compressore trovato. Aggiungi un compressore dalla',
                noCompsFoundAddCompTwo: 'scheda sopra.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Errore durante il caricamento della targhetta. Per favore riprova più tardi.',
            dataSheetError: 'Errore durante il caricamento della scheda tecnica. Per favore riprova più tardi.',
            createSuccess: 'Compressore {{tag}} creato',
            createError: 'Creazione del compressore non riuscita. Per favore riprova più tardi.',
            createCompressor: 'Crea compressore',
          },
          compressorFile: {
            tip: 'Aggiungi file',
            removeFile: 'Rimuovi file',
            removeImage: 'Rimuovi immagine',
            title: "Si prega di confermare l'eliminazione del compressore {{prop}}",
            proceed: 'Vuoi procedere?',
          },
          compressorForm: {
            compTagIsReq: 'Il tag del compressore è richiesto',
            hpMustBeNum: 'La potenza nominale deve essere un numero',
            capMustBeNum: 'La capacità nominale deve essere un numero',
            motorEffMustBeNum: "L'efficienza del motore deve essere un numero",
            motorVoltMustBeNum: 'La tensione del motore deve essere un numero',
            loadMustBeNum: 'La corrente a pieno carico deve essere un numero',
            loadInputMustBeNum: 'La potenza in ingresso a pieno carico deve essere un numero',
            pressureMustBeNum: 'La pressione nominale deve essere un numero',
            setpointMustBeNum: 'Il punto di impostazione della pressione deve essere un numero',
            airMustBeNum: "La dimensione totale dello stoccaggio dell'aria deve essere un numero",
            addImage: 'Aggiungi immagine',
            addFile: 'Aggiungi file',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Vite rotativa',
              RECIPROCATING: 'Alternativo',
              CENTRIFUGAL: 'Centrifugo',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Carico/Scarico – Senza olio',
              loadUnload1Gal: 'Carico/Scarico (1 gal/CFM)',
              loadUnload3Gal: 'Carico/Scarico (3 gal/CFM)',
              loadUnload5Gal: 'Carico/Scarico (5 gal/CFM)',
              loadUnload10Gal: 'Carico/Scarico (10 gal/CFM)',
              ivmWithBlowdown: 'Modulazione della valvola di aspirazione (senza scarico)',
              ivmWithoutBlowdown: 'Modulazione della valvola di aspirazione (con scarico)',
              variableDisplacement: 'Spostamento variabile',
              vsdWithUnloading: 'Azionamento a velocità variabile con scarico',
              vsdWithStopping: 'Azionamento a velocità variabile con arresto',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'Controllo on/off',
              reciprocatingLoadUnload: 'Carico/Scarico',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV con scarico',
              centrifugalInletIbv: 'IGV con scarico',
            },
          },
          contentCell: {
            min: 'La percentuale di carico deve essere almeno 0',
            max: 'La percentuale di carico non può essere superiore a 100',
            typeError: 'Il carico deve essere una percentuale valida',
            setpointMustBeNum: 'Il setpoint del compressore deve essere un numero',
            flowMustBeNum: 'Il flusso del compressore deve essere un numero',
            pressureSetpointMustBeNum: 'Il setpoint di pressione del sistema deve essere un numero',
            measuredFlowMustBeNum: 'Il flusso misurato del sistema deve essere un numero',
            dateTime: 'Seleziona data e ora',
            active: 'ATTIVO',
            off: 'SPENTO',
            trim: 'Regolazione',
            baseLoad: 'Carico base',
          },
          index: {
            error: 'Impossibile caricare i compressori. Per favore riprova più tardi',
            nameplateError: 'Impossibile caricare la targhetta. Per favore riprova più tardi',
            dataSheetError: 'Impossibile caricare la scheda tecnica. Per favore riprova più tardi',
            changeCompSuccess: 'Compressore aggiornato con successo',
            changeCompError: "Errore durante l'aggiornamento del compressore. Per favore riprova.",
            deleteCompSuccess: 'Compressore eliminato con successo',
            deleteCompError: "Errore durante l'eliminazione del compressore. Per favore riprova.",
            createCompNameplateSuccess: 'Targhetta del compressore caricata con successo',
            createCompNameplateError: 'Errore durante il caricamento della targhetta. Per favore riprova più tardi.',
            deleteCompNameplateSuccess: 'Targhetta del compressore eliminata con successo',
            deleteCompNameplateError: "Errore durante l'eliminazione della targhetta del compressore. Per favore riprova.",
            createCompDataSheetSuccess: 'Scheda tecnica del compressore caricata con successo',
            createCompDataSheetError: 'Errore durante il caricamento della scheda tecnica. Per favore riprova più tardi.',
            deleteCompDataSheetSuccess: 'Scheda tecnica del compressore eliminata con successo',
            deleteCompDataSheetError: "Errore durante l'eliminazione della scheda tecnica del compressore. Per favore riprova.",
            changeCompanyCompSuccess: 'Dati di sistema aggiornati con successo',
            changeCompanyCompError: "Errore durante l'aggiornamento dei dati di sistema. Per favore riprova.",
            compDesignData: 'Dati di progettazione del compressore',
            comp: 'Compressori:',
            addComp: 'Aggiungi compressore',
            editCompPermission: 'È necessaria una licenza di amministratore per modificare il compressore. Si prega di contattare',
            selectOption: "Seleziona un'opzione",
            undoChanges: 'Annulla modifiche',
            selectComp: "Seleziona un compressore dall'alto.",
            noCompsFound: 'Nessun compressore trovato.',
            compRunningState: 'Stato di funzionamento del compressore',
            noCompsFoundAddComp: 'Nessun compressore trovato. Aggiungi un compressore dalla sezione sopra.',
            title: "Si prega di confermare l'eliminazione del compressore",
            areYouSure: 'Sei sicuro di voler',
            compressor: 'compressore',
            delete: 'eliminare',
          },
        },
        devices: {
          contentCell: {
            device: 'Dispositivo',
            usedByMultipleCompanies: 'è utilizzato da più aziende',
          },
          index: {
            noDevicesToDisplay: 'Non ci sono dispositivi da visualizzare',
            loadingDevicesErrorMessage: "Impossibile caricare i dispositivi dell'azienda. Per favore riprova più tardi",
            changeStatusSuccessMessage: 'Dispositivo {{isActive}} con successo',
            changeStatusErrorMessage: 'Errore durante {{isActive}} dispositivo. Per favore riprova',
            devices: 'dispositivi',
            findDevice: 'Trova dispositivo',
            placeholder: 'Digita ID del dispositivo...',
            activatedOn: 'Attivato il',
            title: 'Si prega di confermare il cambio di stato del dispositivo',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} è stato eliminato con successo',
          deleteCompanyErrorMessage: 'Eliminazione di {{companyName}} non riuscita. Per favore riprova più tardi.',
          needAdminLicense:
            'Hai bisogno di una licenza amministrativa per accedere a questa funzionalità. Si prega di contattare support@prosaris.ca',
          deleteChildCompanyFirst:
            "Impossibile eliminare l'azienda con filiali o account. Si prega di eliminare prima tutte le filiali e gli account.",
          editSettings: 'Modifica impostazioni',
          company: 'Azienda',
          createASubCompany: 'Crea una filiale per {{companyName}}',
          deleteCompany: 'Elimina azienda',
          cannotCreateChildCompanyDialog: {
            messageOne:
              'Hai raggiunto il numero massimo di aziende che possono essere create dai tuoi account Super Company Admin. Si prega di contattare ',
            messageTwo: 'per ulteriori dettagli.',
          },
          deleteCompanyDialog: {
            title: "Si prega di confermare l'eliminazione dell'azienda",
            delete: 'Elimina',
            attention: 'Attenzione: ',
            deleting: 'Eliminando',
            permanentlyDeleted: 'lo rimuoverà permanentemente dal nostro database.',
            cannotBeUndone: 'QUESTA AZIONE NON PUÒ ESSERE ANNULLATA.',
          },
        },
        companyDialog: {
          industryIsRequired: "È richiesta l'industria",
          createLogoErroerMessage: "Errore durante il caricamento dell'immagine. Per favore riprova più tardi.",
          deleteLogoErrorMessage: "Errore durante l'eliminazione dell'immagine. Per favore riprova più tardi.",
          createCompanySuccessMessage: 'Azienda {{name}} creata',
          createCompanyErrorMessage: "Impossibile creare l'azienda. Per favore riprova più tardi.",
          changeCompanySuccessMessage: 'Azienda {{name}} aggiornata',
          changeCompanyErrorMessage: "Impossibile cambiare il parametro dell'azienda. Per favore riprova più tardi.",
          language: 'Lingua',
          industry: 'Industria',
          country: 'Paese',
          currency: 'Valuta',
        },
        companyLogo: {
          setImageErrorMessage: 'La dimensione del file deve essere inferiore a 1 MB',
          logo: 'Logo',
          add: 'Aggiungi',
          removeLogo: 'Rimuovi logo',
          addLogo: 'Aggiungi logo',
        },
        index: {
          loadCompaniesErrorMessage: "Impossibile caricare l'elenco delle aziende. Per favore riprova più tardi",
          changeCompaniesSectionWidth: 'Cambia la larghezza della sezione delle aziende',
        },
        sidebar: {
          filterCompanies: 'Filtra aziende',
          placeholder: "Inserisci il nome dell'azienda",
          myCompany: 'La mia azienda',
          childCompanies: 'Aziende filiali',
        },
        routes: {
          index: {
            noFacility: 'Non hai definito nessuna struttura',
            createAFacility: 'Crea una struttura per iniziare a pianificare i percorsi',
            goToCreateAFacility: 'Vai a creare una struttura',
            createAFacilityTooltip: 'È necessario creare una struttura prima di poter iniziare a pianificare i percorsi.',
            save: 'Salva',
            reset: 'Reimposta',
            routePlans: 'Piani di percorso',
            loading: 'Caricamento...',
            noRoutesAdded: 'Nessun percorso aggiunto.',
            buildARouteTooltip: 'Costruisci un percorso utilizzando il Costruttore di Percorsi.',
            createFloorplanSuccess: 'Successo! Planimetria caricata!',
            createRouteSuccess: 'Successo! Percorso creato!',
          },
          routeBuilder: {
            title: 'Costruttore di Percorsi',
            missingFields: 'Campi mancanti nel file Excel',
            manuallyBuildARoute: 'Costruisci manualmente un nuovo piano di percorso',
            manualInstructions: 'Puoi costruire i percorsi manualmente o caricando un file Excel.',
            facility: 'Struttura',
            route: 'Percorso',
            enterRoute: 'Inserisci il percorso',
            addFloorplan: 'Aggiungi planimetria',
            floorplanInstructionsTooltip:
              "Aggiungi un'immagine di una planimetria per aiutarti a costruire i percorsi. Il percorso verrà sovrapposto alla planimetria quando salvato.",
            change: 'Cambia',
            location: 'Posizione',
            enterLocation: 'Inserisci la posizione',
            equipment: 'Attrezzatura',
            enterEquipment: "Inserisci l'attrezzatura",
            criticality: 'Criticità',
            enterCriticality: 'Inserisci la criticità',
            description: 'Descrizione',
            enterDescription: 'Inserisci la descrizione',
            addEquipment: 'Aggiungi attrezzatura',
            addRoute: 'Aggiungi percorso',
            downloadTemplate: 'Scarica modello',
          },
          excelReader: {
            loadFromFile: 'Carica da file',
            instructionsTooltip:
              'Puoi creare percorsi caricando un file Excel in cui ogni foglio rappresenta una posizione diversa in una struttura. Ogni posizione può avere più percorsi.',
          },
          graph: {
            controls: {
              addFloorplan: 'Aggiungi planimetria',
              toggleLabels: 'Attiva/Disattiva etichette',
              addWaypoint: 'Aggiungi punto di passaggio',
              addEquipment: 'Aggiungi attrezzatura',
              swapEquipment: 'Scambia attrezzatura',
              showLeakStats: 'Mostra statistiche delle perdite',
              save: 'Salva',
              delete: 'Elimina',
            },
            routeStats: {
              title: 'Statistiche del Percorso',
              totalDollarLossPerYear: 'Perdita totale in dollari per anno:',
              totalKwhLossPerYear: 'Perdita totale in kWh per anno:',
              totalLeakCount: 'Numero totale di perdite:',
              totalActiveOpen: 'Totale aperture attive:',
              totalActiveClosed: 'Totale chiusure attive:',
              noStatsAvailable: 'Nessuna statistica disponibile:',
              close: 'Chiudi',
            },
            nodeDetails: {
              edit: 'Modifica',
              name: 'Nome',
              description: 'Descrizione',
              criticality: 'Criticità',
              equipmentInformation: "Informazioni sull'attrezzatura",
              thisIsAWaypoint: 'Questo è un punto di passaggio',
              activeOpenLeakCount: 'Numero di perdite attive aperte:',
              noActiveLeaksDetected: 'Nessuna perdita attiva rilevata',
              close: 'Chiudi',
              nothingToSeeHere: 'Niente da vedere qui',
              seeLeakRecords: 'Visualizza i registri delle perdite',
            },
            swapEquipment: {
              title: 'Scambia attrezzatura',
              selectEquipment: "Seleziona l'attrezzatura da scambiare:",
              selectFirst: 'Seleziona la prima attrezzatura',
              selectSecond: 'Seleziona la seconda attrezzatura',
              swap: 'Scambia',
              cancel: 'Annulla',
            },
            addNode: {
              title: 'Aggiungi',
              waypoint: 'Punto di passaggio',
              equipment: 'Attrezzatura',
              afterWhich: 'Dopo quale attrezzatura?',
              selectPreceding: "Seleziona l'attrezzatura precedente:",
              selectEquipment: 'Seleziona attrezzatura',
              name: 'Nome',
              description: 'Descrizione',
              criticality: 'Criticità',
              add: 'Aggiungi',
              cancel: 'Annulla',
            },
            save: 'Salva',
            delete: 'Elimina',
            successRouteUpdated: 'Percorso aggiornato con successo',
            errorRouteUpdated: "Errore nell'aggiornamento del percorso. Per favore riprova più tardi.",
            successRouteDeleted: 'Percorso eliminato con successo',
            errorRouteDeleted: "Errore nell'eliminazione del percorso. Per favore riprova più tardi.",
            successFloorplanUpdated: 'Planimetria aggiornata con successo',
            errorFloorplanUpdated: "Errore nell'aggiornamento della planimetria. Per favore riprova più tardi.",
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Licenze amministratore assegnate',
            of: 'di',
            edit: 'Modifica',
          },
          essentialMode: {
            disable: 'disabilita',
            enable: 'abilita',
            essential: 'Modalità essenziale:',
            enabled: 'ABILITATO',
            disabled: 'DISABILITATO',
            moveCompany: "Sposta l'azienda {{company}} in modalità essenziale",
            affectedCompanies: 'Numero di aziende interessate',
            affectedAccounts: 'Numero di account interessati',
            archivedLeak: 'Numero di record di eventi di perdita archiviati',
            applyingChanges:
              'Applicando queste modifiche, gli account otterranno il ruolo "Essenziale". Gli utenti potranno vedere {{quota}} record di eventi di perdita più recenti su un dashboard e un\'app mobile. Altri record verranno archiviati.',
            essentialLabel: 'Cambia modalità essenziale per le sottoaziende',
            turnOffEssential: "Disattiva modalità essenziale per l'azienda {{company}}",
            byApplyingChanges:
              'Applicando queste modifiche, tutti gli account otterranno il ruolo "Utente". Gli utenti potranno vedere tutti i loro record. Tutti i record verranno desarchiviati.',
          },
          index: 'Impostazioni',
        },
        index: {
          loadCompanyError: "Impossibile caricare le informazioni sull'azienda. Per favore riprova più tardi",
          companySlug: 'Alias aziendale',
          notSelected: 'Non selezionato',
          country: 'Paese',
          accounts: 'Account',
          currency: 'Valuta',
          adminLicenses: 'Licenze amministratore',
          totalCustomer: "Numero totale di account clienti nell'albero",
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Registra il tuo dispositivo',
          toolTip: 'Si prega di localizzare il numero di serie sulla sua carta di registrazione o sulla scatola.',
          serialNumberIsRequired: 'È richiesto il numero di serie',
          oops: 'Ops!',
          deviceNotFound: 'Numero di serie del dispositivo non trovato',
          deviceFoundNdAssociatedCompany: "Il tuo dispositivo è stato trovato ed è associato a un'azienda.",
          deviceFound: 'Il tuo dispositivo è stato trovato.',
          goToLogin: 'Vai al login',
          contactSupport: 'Si prega di contattare il supporto',
        },
        deviceErrorCodes: {
          'B13:001': 'Numero di serie del dispositivo non trovato',
          'B13:002': 'Dispositivo già attivato',
          'B13:003': 'Email amministratore non inviata',
          'B13:004': 'Azienda non trovata',
          'B13:005': 'Azienda non valida',
          'B13:006': 'Tipo non valido',
        },
        registerAccountView: {
          registerAccount: 'Crea un account Prosaris',
          termsMustBeAccepted: 'È necessario accettare i termini e le condizioni.',
          saleType012Success: 'Puoi accedere per iniziare a utilizzare il tuo dispositivo.',
          saleType3Success: 'Sei stato aggiunto come utente base. Gli amministratori della tua azienda sono stati informati.',
          saleType4Success: {
            companyPlatformFound: 'Licenza della piattaforma aziendale trovata.',
            dialogOne: 'Questo dispositivo è associato a una nuova licenza della piattaforma aziendale.',
            dialogTwo: 'La tua azienda potrebbe già esistere nel nostro sistema.',
            dialogThree: 'In tal caso, contatta il tuo amministratore.',
          },
          termsAndConditions: {
            dialogOne: 'Accetto i',
            dialogTwo: 'Termini di Servizio',
            dialogThree:
              'di Prosaris e confermo di aver avuto abbastanza tempo per leggerli e capire che contengono termini importanti riguardo il mio uso del software Prosaris, come la limitazione della responsabilità di Prosaris e come saranno gestiti i miei dati.',
          },
          acceptTerms: 'Accetta i termini',
          registerAccountErrorCodes: {
            'B02:001': 'Codice di registrazione non valido',
            'B02:002': 'Azienda utente non valida',
            'B02:003': 'Ruolo utente non valido',
            'B02:004': "L'email è già in uso",
            'B02:005': "L'email è già in uso",
            'B02:006': 'Impossibile reimpostare la password per il tuo account',
            'B02:007': 'Azienda utente non valida',
            'B02:008': 'Foto profilo mancante',
            'B02:009': "È richiesto il reset dell'account. Il processo è stato avviato",
            'B02:010': "L'account è stato disattivato",
            'B02:011': 'Formato foto profilo non valido',
            'B02:012': "Dispositivo già registrato a un'azienda",
            'B02:013': 'Numero di serie non definito',
            'B02:014': 'Tipo non valido',
            'B02:015': 'Link per reimpostare la password scaduto',
            'B02:016': 'Non è possibile creare un account per questa azienda',
            'B02:017': 'Ruolo non valido',
            'B02:018': 'Ruolo non valido',
            'B02:019': 'Azienda utente non valida',
            'B02:020': 'Ruolo utente non valido',
            'B02:021': "L'utente non appartiene a questa azienda",
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Sono richiesti i numeri di serie',
          failedToCopy: 'Numeri di serie non riusciti copiati negli appunti',
          invalidFormat: 'Uno o più dispositivi hanno un formato non valido. Si prega di controllare e riprovare.',
          devices: 'Registra i tuoi dispositivi',
          deviceSerialNumber: 'Numero(i) di serie del dispositivo',
          note: 'Nota: Inserisci ogni numero di serie su una nuova riga o separato da una virgola.',
          title: 'Si prega di individuare il numero di serie sulla carta di registrazione o sulla scatola.',
          register: 'Registrare',
          deviceRegistrationStatus: 'Stato di registrazione del dispositivo',
          success: 'Successo',
          noDeviceFound: 'Nessun dispositivo trovato.',
          failed: 'Fallito',
          copyFailed: 'Copia i numeri di serie non riusciti negli appunti',
        },
      },
      errors: {
        notFoundView: {
          title: 'La pagina che stai cercando non è qui',
          description:
            'O hai provato una scorciatoia sospetta o sei arrivato qui per errore. Qualunque sia il caso, prova a usare la navigazione.',
        },
        accountNotFound: {
          title: 'Account non trovato',
          description:
            "Per favore riprova! Se il problema persiste, il tuo account potrebbe essere inattivo o non essere stato inizializzato dall'amministratore. Si prega di contattare l'amministratore.",
          logout: 'Disconnetti',
        },
      },
      integration: {
        index: {
          loggingInError: "Errore durante l'accesso a Prosaris.",
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'Nessuna immagine disponibile',
          activeActionRecord: {
            updateAssignmentSuccess: "L'azione assegnata è stata aggiornata.",
            updateAssignmentError: "Errore durante l'aggiornamento dell'azione assegnata.",
            deleteImageSuccess: "L'immagine assegnata è stata eliminata.",
            deleteImageError: "Errore durante l'eliminazione dell'immagine dell'azione assegnata.",
            confirmMarkAction: 'Vuoi davvero segnare questa azione come completata?',
            confirmDeleteImage: 'Vuoi davvero eliminare questa immagine?',
          },
          index: 'Errore durante il caricamento delle azioni assegnate per un record.',
        },
        audio: {
          noAudioAvailable: 'Nessun dato audio disponibile',
          playPause: 'Riproduci / Pausa',
          generateRegion: 'Genera Regione',
          removeLastRegion: "Rimuovi l'Ultima Regione",
          generateMarker: 'Genera Marcatore',
          removeLastMarker: "Rimuovi l'Ultimo Marcatore",
          toggleTimeline: 'Attiva / Disattiva la Timeline',
          download: 'Scarica',
        },
        audit: {
          updateAuditSuccess: 'Audit aggiornato con successo',
          updateAuditError: 'Errore durante il salvataggio del commento. Per favore riprova più tardi.',
          createAuditSuccess: 'Audit creato con successo',
          createAuditError: "Errore durante la creazione dell'audit. Per favore riprova più tardi.",
          deleteAuditSuccess: "L'audit è stato eliminato con successo",
          deleteAuditError: "Impossibile eliminare l'audit. Per favore riprova più tardi.",
          noComment: 'Nessun commento',
          reviewComments: 'Commenti di revisione',
          reviewedBy: 'Esaminato da:',
          auditDate: "Data dell'audit:",
          deleteAudit: 'Elimina audit',
          reviewedNoComments: 'Esaminato nessun commento',
          auditComments: "Commenti dell'audit",
          saveComment: 'Salva commento',
          discardChanges: 'Annulla modifiche',
        },
        changeHistory: {
          created: 'creato',
          updated: 'aggiornato',
          deleted: 'eliminato',
          transferred: 'trasferito',
          leak: 'Perdita',
          images: 'Immagine',
          audit: 'Audit',
          audio: 'Audio',
          history: 'Cronologia',
          noHistory: 'Nessuna cronologia',
          bulk: 'come operazione in blocco',
          details: 'dettagli',
          unset: 'non impostato',
        },
        fft: {
          fft: 'FFT (Trasformata Rapida di Fourier)',
          fftAvg: 'Media FFT',
          notApplicable: 'Non applicabile',
          chartLayers: 'Strati del grafico',
          avg: 'Media',
          all: 'Tutto',
          playing: 'Riproduzione in corso...',
          showTimeSeries: 'Mostra serie temporale',
        },
        form: {
          updateLeakSuccess: 'Aggiornato con successo.',
          updateLeakError: "Errore durante l'aggiornamento della perdita. Per favore riprova più tardi.",
          alertTitle: {
            partOne: 'Il registro della perdita è in',
            partTwo: 'modalità sola lettura',
            partThree: '.',
          },
          alertDialog: {
            dialogOne: 'Tuttavia, è possibile modificare le seguenti proprietà:',
            dialogTwo: "Stato dell'azione",
          },
          GPSLocation: 'Posizione GPS',
          completedOn: 'Completato il:',
          lastSaved: 'Ultimo salvataggio:',
        },
        images: {
          removeSuccess: 'Immagine eliminata con successo.',
          removeError: "Impossibile eliminare l'immagine del record di perdita. Per favore riprova più tardi.",
          text: 'Sei sicuro di voler eliminare questa immagine?',
        },
        index: {
          title: 'Registro evento di perdita',
          loadingError: 'Errore durante il caricamento del record di perdita. Per favore riprova.',
          unsavedMessage: 'Premendo "Ok" confermi che tutte le modifiche non salvate verranno eliminate',
          notAnAuditor: 'Non sei un revisore',
          anEssential: 'Sei su un piano essenziale',
          showHistory: 'Mostra cronologia',
        },
        massFlow: {
          recalculating: 'Ricalcolo di flusso/perdita secondo le impostazioni del parametro di perdita...',
          warning:
            'I valori di input sono cambiati o il calcolo è obsoleto, premere "Ricalcola" per aggiornare i valori di flusso e perdita',
          calculatingError: 'Errore durante il calcolo del flusso di massa. Per favore riprova.',
          noValue: 'Nessun valore',
          calculatingSuccess: 'Flusso/Perdita ricalcolato',
          recalculate: 'Ricalcola',
          calculatorVersion: 'Versione del calcolo:',
          noteDialog: {
            note: 'Nota:',
            dialogOne: 'Cambiare le impostazioni del parametro di perdita aggiornerà il calcolo di flusso/perdita. Controlla',
            dialogTwo: 'Approfondimenti sui calcoli',
            dialogThree: 'pagina per ulteriori informazioni',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Il record della perdita è stato modificato',
          close: 'Chiudi',
          unsavedChanges:
            'Questo record ha modifiche non salvate. Si prega di considerare di salvare queste modifiche o di rimuoverle completamente.',
          discardChanges: 'Annulla modifiche',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: "È richiesto l'ID del dispositivo",
          dbPeakIsRequired: 'È richiesto il DB di picco misurato',
          dbMin: 'Il DB di picco misurato deve essere maggiore o uguale a 0',
          dbMax: 'Il DB di picco misurato deve essere minore o uguale a 95',
          dbError: 'Il DB di picco misurato deve essere un numero',
          imageUploadError: "Errore durante il caricamento dell'immagine. Per favore riprova più tardi.",
          createLeakSuccess: 'Registrazione della perdita aggiunta con successo',
          createLeakError: "Errore durante la creazione dell'account. Per favore riprova più tardi.",
          basicInfo: 'Informazioni di base',
          flowInput: 'Ingresso di flusso',
          mainGas: 'Gas/Componente principale di interesse',
          selectComponent: 'Seleziona componente',
          selectLeakType: 'Seleziona tipo di perdita',
          mightBeSomethingHere: 'non ancora sicuro',
          energyLoss: 'Perdita di energia',
          selectCompressorType: 'Seleziona tipo di compressore',
          noRoutes: 'Nessun percorso trovato',
          noEquipment: 'Nessuna attrezzatura trovata',
          noLocations: 'Nessuna posizione trovata',
          energyCost: 'Costo energetico',
          audioRecording: 'Registrazione Audio',
          leakSourceEquipment: 'Attrezzatura della fonte di perdita',
          selectComponentType: 'Seleziona tipo di componente',
          selectService: 'Seleziona servizio',
          locationAndUser: 'Posizione e utente',
          leakEventStatus: "Stato dell'evento di perdita",
          low: 'Basso',
          medium: 'Medio',
          high: 'Alto',
          open: 'Apri',
          complete: 'Completo',
          reset: 'Reimposta',
        },
        images: {
          leakSourceImageCapture: 'Acquisizione immagine fonte di perdita',
          add: 'Aggiungi',
        },
        index: {
          title: 'Crea registro di perdita',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'Da',
          to: 'A',
          qualifier: 'Qualificatore',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Assegna record di perdita',
          selectAUserToAssign: 'Seleziona un utente da assegnare ai record selezionati',
          filterByName: 'Filtra per nome',
          filterByEmployeeName: 'Filtra per nome dei dipendenti',
          unexpectedError: 'Si è verificato un errore imprevisto',
          noUserFound: 'Nessun utente trovato',
          assigningActionForSelectedRecords: 'Assegnazione di azioni per i record selezionati...',
        },
        tableHead: {
          youAreSelecting: 'Stai selezionando:',
          visibleRecords: 'record visibili',
          andAllUnloadedRecords: 'e tutti i record non caricati.',
          allRecordsWillBeLoaded: 'Tutti i record saranno caricati e selezionati.',
          thisCanTakeAWhile: 'Questo potrebbe richiedere del tempo se hai molti record.',
        },
        tableBody: {
          noRecordsToDisplay: 'Non ci sono record da visualizzare.',
        },
        toolBar: {
          assign: 'Assegna',
          onEssentialPlan: 'Sei su un piano essenziale. Si prega di contattare support@prosaris.ca',
          exportPDF: 'Esporta in PDF',
          needAdminLicense:
            'Hai bisogno di una licenza amministrativa per accedere a questa funzionalità. Si prega di contattare support@prosaris.ca',
          create: 'Crea',
          deleteRecordMessage: 'Sei sicuro di voler eliminare il record selezionato?',
          deleteRecordMessagePlural: 'Sei sicuro di voler eliminare i record selezionati?',
          permissionDenied: 'Permesso negato!',
          notAllowedToDelete: 'Hai selezionato un record di qualcun altro che non ti è permesso eliminare. Rimuovilo dalla selezione.',
          generatingPDF: 'Generazione del PDF in corso...',
          cannotGeneratePDF:
            'Purtroppo, non possiamo esportare {{numberSelected}} record in un documento PDF in questo momento. Seleziona fino a {{limitRecords}} record.',
          cannotExportMessage: 'Impossibile esportare {{numberSelected}} record',
        },
        index: {
          cannotLoadLeakRecord: 'Ci dispiace, non possiamo caricare il record di perdita in questo momento',
          successfullyAssignedAllRecords: 'Tutti i record assegnati con successo',
          actionRecordAssigned: 'Record di azione assegnato!',
          actionRecordAssignedPlural: 'Record di azione assegnati!',
          errorWhileAssigningLeak: "Errore durante l'assegnazione della perdita. Per favore riprova più tardi.",
          successfullyDeletedAllRecords: 'Tutti i record eliminati con successo',
          successfullyDeletedRecord: '{{length}} record di perdita eliminato con successo',
          successfullyDeletedRecordPlural: '{{length}} record di perdita eliminati con successo',
          errorOnRecordsDelete: "Errore durante l'eliminazione dei record. Per favore riprova più tardi",
          leakPreview: 'Anteprima della perdita',
          leakRecord: 'Registro di perdita: ',
        },
      },
      tagList: {
        resetTag: 'Reimposta etichetta',
        dataExport: {
          smartLeakTagsFormat: 'Formato etichette di perdita intelligenti',
          smartLeakTagsDescription: 'Contiene i campi predefiniti presenti nella pagina delle etichette di perdita intelligenti',
          standardLeakRecordFormat: 'Formato registro perdite standard',
          standardLeakRecordDescription: 'Contiene tutti i campi relativi ai registri delle perdite',
        },
        filters: { smartLeakTagFilters: 'Filtri per etichette di perdita intelligenti' },
        index: {
          cannotLoadTags: "Mi dispiace, non possiamo caricare l'etichetta di perdita intelligente in questo momento",
          updateLeakSuccess: 'Reimpostazione etichetta di perdita intelligente riuscita.',
          updateLeakError: "Errore durante la reimpostazione dell'etichetta di perdita intelligente. Per favore riprova più tardi.",
          bulkEditSuccess: 'Reimpostazione riuscita di {{len}} etichetta di perdita intelligente',
          bulkEditSuccessPlural: 'Reimpostazione riuscita di {{len}} etichette di perdita intelligenti',
          bulkEditError: 'Errore durante la reimpostazione delle etichette di perdita intelligenti. Per favore riprova più tardi.',
          tagsFileName: 'Etichette di perdita intelligenti',
          leakFileName: 'Registri delle perdite',
        },
        tableBody: {
          title: "Si prega di confermare la reimpostazione dell'etichetta",
          alertDialog: {
            dialogOne: 'Stai cercando di',
            dialogTwo: "reimpostare l'etichetta",
            dialogThree: 'da un registro di perdita con',
            dialogFour: "Stato dell'azione: APERTO",
            dialogFive: 'Vuoi procedere?',
          },
        },
        toolBar: {
          resetTags: 'Reimposta etichette',
          title: 'Si prega di confermare la reimpostazione in blocco delle etichette',
          alertDialog: {
            dialogOne: 'La tua selezione contiene',
            dialogTwo: 'record con',
            dialogTwoPlural: 'record con',
            dialogThree: "Stato dell'azione: APERTO",
            dialogFour: 'Vuoi procedere?',
          },
          exportFormatCSV: 'Seleziona un formato di esportazione per CSV',
          exportFormatPDF: 'Seleziona un formato di esportazione per PDF',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Errore durante il caricamento delle azioni assegnate. Per favore riprova.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Grafici disponibili',
            selectAChart: 'Seleziona un grafico...',
            chartFilters: 'Filtri dei grafici',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Errore durante il caricamento dei record di perdita. Per favore riprova.',
              errorMessageTwo: 'Si prega di selezionare una data antecedente alla data di fine.',
              errorMessageThree: 'Si prega di selezionare un periodo inferiore a 6 mesi.',
              errorMessageFour: 'Si prega di selezionare una data successiva alla data di inizio.',
            },
            charts: 'Grafici',
            chartAlreadyExists: 'Questo grafico esiste già!',
          },
          filters: {
            select: 'Seleziona...',
            startDate: 'Data di inizio',
            endDate: 'Data di fine',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Flusso per categoria di perdita (${unit})`,
              LOSS_BY_LEAK: (currency) => `Perdita per categoria di perdita (${currency})`,
              TAGS_BY_LEAK: 'Etichette per categoria di perdita',
              AVG_FLOW_BY_LEAK: (unit) => `Flusso medio per categoria di perdita (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Opportunità di perdita e risparmio per tempo di riparazione (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Flusso per tempo di riparazione (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Perdita per tempo di riparazione (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Progresso del flusso – Riparato vs Non riparato (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Progresso delle perdite – Riparato vs Non riparato (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Flusso per attrezzatura/componente (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => ` Perdita per attrezzatura/componente (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Etichette per attrezzatura/componente',
            },
            datasetLabel: {
              totalLeakFlow: 'Flusso totale di perdita trovato in',
              totalLoss: 'Totale delle perdite in',
              totalRecords: 'Totale dei record trovati in',
              avgLeakFlow: 'Flusso medio di perdita trovato in',
              totalLossIn: 'Perdita totale in',
              flowByRepairTime: 'Flusso per tempo di riparazione in',
              lossByRepairTime: 'Perdita per tempo di riparazione in',
              flowByRepairProgress: 'Flusso trovato per avanzamento delle riparazioni in',
              recordsByCompletion: 'Record per completamento in',
              flowByEquipment: 'Flusso trovato per attrezzatura in',
              totalLossByEquipment: 'Perdita totale per attrezzatura in',
              totalRecordsByEquipment: 'Record totali per attrezzatura in',
            },
            labels: {
              low: 'Basso',
              medium: 'Medio',
              high: 'Alto',
              equipment: 'Attrezzatura',
              savings: 'Risparmi',
              opportunity: 'Opportunità',
              loss: 'Perdita',
              repaired: 'Riparato',
              unrepaired: 'Non riparato',
              LESS_15_DAYS: 'Meno di 15 giorni',
              DAYS_16_TO_30: '15 a 30 giorni',
              DAYS_31_TO_60: '31 a 60 giorni',
              DAYS_61_TO_90: 'Più di 60 giorni',
            },
          },
        },
        index: {
          assignedActions: 'Azioni assegnate a te',
        },
      },
      export: {
        imageUrlOne: 'URL immagine perdita 1',
        imageUrlTwo: 'URL immagine perdita 2',
        leakTag: 'Etichetta di perdita intelligente/Riferimento di lavoro',
        per: 'per ',
      },
    },
    languages: {
      English: 'Inglese',
      Chinese: 'Cinese',
      Spanish: 'Spagnolo',
      French: 'Francese',
      German: 'Tedesco',
      Norwegian: 'Norvegese',
      Italian: 'Italiano',
      Turkish: 'Turco',
    },
  },
};
