import api from '../helpers';

const mapper = (model) => ({
  // eslint-disable-next-line no-underscore-dangle
  id: model.id || model._id,
  ...model,
});

export default {
  create: {
    key: 'createLeak',
    query: (currency, deviceId, deviceCreatedAt, documentId, creatorUserId, leakParameters, liveDataReading, leakSource, leakStatus) => {
      return api.post('/api/leaks', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          currency,
          deviceId,
          deviceCreatedAt,
          documentId,
          creatorUserId,
          leakParameters,
          liveDataReading,
          leakSource,
          leakStatus,
        },
      });
    },
  },
  getLeak: {
    key: 'fetchLeak',
    query: (id, field) => {
      return api.get(`/api/leaks/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { by: field },
      });
    },
    mapper: ({ data }) => mapper(data),
  },
  setLeak: {
    query: (id, field, model) => {
      return api.put(`/api/leaks/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: model,
        params: { by: field },
      });
    },
  },
  list: {
    key: 'fetchLeaks',
    query: (offset, limit, sortBy, sortDir, filters = {}) => {
      return api.get('/api/leaks', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          format: 'withRelations',
          offset,
          limit,
          sort: [sortBy, sortDir],
          filters,
        },
      });
    },
    mapper: ({ data }) => data?.map(mapper) || [],
  },
  listNonEmptyTags: {
    key: 'fetchLeaksNonEmptyTags',
    query: (offset, limit, sortBy, sortDir, filters = {}) => {
      return api.get('/api/leaks?filters[leakStatus.leakTag]=$notEmpty', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          format: 'withRelations',
          offset,
          limit,
          sort: [sortBy, sortDir],
          filters,
        },
      });
    },
    mapper: ({ data }) => data?.map(mapper) || [],
  },
  listIds: {
    key: 'fetchLeakIds',
    query: (filters) => {
      return api.get('/api/leaks', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          format: 'onlyId',
          offset: 0,
          limit: -1,
          filters,
        },
      });
    },
    mapper: ({ data }) => data,
  },
  transfer: {
    key: 'transferLeaks',
    query: (oldUserId, newUserId) => {
      return api.post('/api/leaks/transfer', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          fromUserId: oldUserId,
          toUserId: newUserId,
        },
      });
    },
  },
  bulkDeleteById: {
    key: 'bulkDelete',
    query: (ids) => {
      return api.post('/api/leaks/bulk-delete', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { ids },
        params: { by: 'id' },
      });
    },
  },
  bulkDeleteByFilters: {
    key: 'bulkDelete',
    query: (filters) => {
      return api.post('/api/leaks/bulk-delete', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { filters },
      });
    },
  },
  bulkEditById: {
    key: 'bulkEdit',
    query: (ids, model) => {
      return api.post('/api/leaks/bulk-edit', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { ids, data: model },
        params: { by: 'id' },
      });
    },
  },
  bulkEditByDocumentId: {
    key: 'bulkEdit',
    query: (documentId, model) => {
      return api.post('/api/leaks/bulk-edit', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { documentId, data: model },
      });
    },
  },
  calculate: {
    key: 'calculateLeak',
    query: (parameters) => {
      return api.post('/api/calculations', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: parameters,
      });
    },
  },
  history: {
    key: 'historyLeak',
    query: (resourceId) => {
      return api.get(`/api/history/leaks/${resourceId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
