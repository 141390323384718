import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        [theme.breakpoints.up('md')]: {
          height: '100%',
        },
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingBottom: theme.spacing(6),
      },
    },
  })
);

function GlobalStyles() {
  useStyles();

  return null;
}

export default GlobalStyles;
