import queries from '../queries';

const isTranslationFeatureActive = async () => {
  const { data, error } = await queries.isTranslationFeatureActive.query();
  if (error) {
    throw error;
  }

  return data;
};

const getFeatureViewable = async (id) => {
  const { data, error } = await queries.getFeatureViewable.query(id);
  if (error) {
    throw error;
  }

  return data;
};

export default {
  getFeatureViewable,
  isTranslationFeatureActive,
};
