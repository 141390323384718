import { useMutation } from 'react-query';
import queries from '../queries';

const useFloorplanCreate = (callbacks = {}) => {
  return useMutation(
    ({ routeId, filename, file }) => {
      return queries.floorplans.create.query({ routeId, filename, file });
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useFloorplanDelete = (callbacks = {}) => {
  return useMutation(
    ({ id }) => {
      return queries.floorplans.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useFloorplanCreate,
  useFloorplanDelete,
};
