import api from '../helpers';

export default {
  get: {
    key: 'fetchUtilProviderLogo',
    query: (energyId) => {
      return api.get(`/api/utilproviderlogo/${energyId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: false,
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  create: {
    key: 'createUtilProviderLogo',
    query: ({ energyId, filename, file }) => {
      return api.post('/api/utilproviderlogo', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          energyId,
          filename,
          image: file,
        },
        _throwError: true,
      });
    },
  },
  delete: {
    key: 'deleteUtilProviderLogo',
    query: (id) => {
      return api.delete(`/api/utilproviderlogo/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        _throwError: true,
      });
    },
  },
};
